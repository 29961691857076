/**
 * @ngdoc Component
 * @name tallyfy.process.component.templateEdit
 * @module tallyfy.process
 *
 * @description
 * A component to edit the details of a template
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .component('templateEdit', {
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/modules/process/components/edit/info/template-edit.html',
      controller:
        /*@ngInject*/
        function (_, blockUI, Growl, $rootScope, $scope, $confirm, $filter, $state, ProcessService, RunDetailService, Helper, TasksService, OrganizationsService,
                  TagsService, DOM, DOMService, $q, CONST, DESCRIPTIONSIZE, DateUtils, TFY_EVENTS, PLANS, RunsService, clipboard, CompactStepService, AuthPlan,
                  $sce, BLUEPRINT_TYPE, $analytics, StepService, $stateParams, UtilsService, $timeout, Upload, ENV_CONFIG, FilesService, FolderService, $window,
                  RunRepository, $uibModal, $log, DATEFORMAT, moment, COMMON) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('templateEdit'),
            tempProcess,
            highestStepDeadlineEventHandler,
            drawerStepUpdateEventHandler,
            unregisterCloseBPEDetailListener,
            processChangesHandler,
            processUpdatedHandler,
            updateFroalaViaCapturesEventHandler,
            onKOFieldAddedHandler,
            folderSelectedWatcher,
            toolbarButtons = CompactStepService.getBlueprintFroalaToolbar(),
            templateSaved = false,
            currentUser = _.get($rootScope.identity, 'id'),
            foldersList,
            modalInstance,
            windowElem = angular.element($window);

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.saveProcess = saveProcess;
          $ctrl.archiveProcess = archiveProcess;
          $ctrl.toggleProcessTab = toggleProcessTab;
          $ctrl.toggleTemplateOwner = toggleTemplateOwner;
          $ctrl.discardChanges = discardChanges;
          $ctrl.closeModal = closeModal;
          $ctrl.onRemovingUser = onRemovingUser;
          $ctrl.onUserSelect = onUserSelect;
          $ctrl.getTagClass = getTagClass;
          $ctrl.loadUsers = loadUsers;
          $ctrl.templateOwnersAsStr = templateOwnersAsStr;
          $ctrl.isTemplateTitle = isTemplateTitle;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.copyToClipboard = copyToClipboard;
          $ctrl.hasAtLeastOneField = hasAtLeastOneField;
          $ctrl.duplicateTemplate = ProcessService.showDuplicateTemplateModal;
          $ctrl.getSaveText = getSaveText;
          $ctrl.unArchiveProcess = unArchiveProcess;
          $ctrl.setTitleEditable = setTitleEditable;
          $ctrl.checkAuthority = checkAuthority;
          $ctrl.copyZeroLaunchLink = copyZeroLaunchLink;
          $ctrl.setTagTextColor = TagsService.isTagTextColor;
          $ctrl.changeTab = changeTab;
          $ctrl.isActiveContent = isActiveContent;
          $ctrl.insertVariablesForm = [];
          $ctrl.setBlueprintStatus = setBlueprintStatus;
          $ctrl.statusOptions = StepService.getProgressStatus();
          $ctrl.openBuilder = openBuilder;
          $ctrl.isImage = Helper.isImage;
          $ctrl.uploadFiles = uploadFiles;
          $ctrl.getFileLocation = Helper.getFileLocation;
          $ctrl.downloadAsset = Helper.downloadAsset;
          $ctrl.deleteAsset = deleteAsset;
          $ctrl.getFileClassName = Helper.getFileClassName;
          $ctrl.onNewFolderForBlueprint = onNewFolderForBlueprint;
          $ctrl.onMoveToFolder = onMoveToFolder;
          $ctrl.onRemoveFromFolder = onRemoveFromFolder;
          $ctrl.onFolderTreeToggle = onFolderTreeToggle;
          $ctrl.onFolderSeletionToggle = onFolderSeletionToggle;
          $ctrl.onCreateFolder = onCreateFolder;
          $ctrl.setDefaultSelect = setDefaultSelect;
          $ctrl.onSelectedOption = onSelectedOption;
          $ctrl.handleTemplateStatusUpdate = handleTemplateStatusUpdate;
          $ctrl.createPermissionTask = createPermissionTask;
          $ctrl.checkAutoNameValidation = checkAutoNameValidation;

          /**
           * public properties
           */
          $ctrl.isWebhook = false;
          $ctrl.formInvalid = false;
          $ctrl.isTemplateOwner = false;
          $ctrl.processCoworkers = [];
          $ctrl.captureForm = {};
          $ctrl.processPermissionForm = {};
          $ctrl.userLists = [];
          $ctrl.stepFields = [];
          $ctrl.koFields = [];
          $ctrl.selectFolder = {};
          $ctrl.isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
          $ctrl.dateFormat = OrganizationsService.getDateFormat();
          $ctrl.showPermissions = false;
          $ctrl.ownerChangeBP = false;
          $ctrl.maxAutoNameTitleLength = COMMON.MAX_AUTOMATION_NAME;
          $ctrl.checkMaxTitleValidation = false;

          //Attach event listener
          windowElem.on('resize', onWindowResize);

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            onWindowResize();
            $ctrl.foldersList = [{ name: 'empty' }];
            watchRunData();
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            $ctrl.process = $ctrl.resolve.process;
            if (_.get($ctrl.process, 'is_public')) {
              $ctrl.isPublic = true;
            } else {
              $ctrl.isShowPermission = true;
            }
            var owner = _.get($ctrl.process, 'users') && $ctrl.process.users.length < 0 ? $ctrl.process.owner_id : $ctrl.process.users,
            havePermissionTab = true;
            $ctrl.isDuplicatePermission = UtilsService.hasSpecificPermissions($ctrl.isAdminMember, $ctrl.process.created_by, currentUser, _.get($ctrl.process, 'can_edit_perms.checklist_duplicate', []), _.get($ctrl.process, 'can_edit_perms.checklist_duplicate_group', []), $ctrl.resolve.orgGroups);
            $ctrl.tabsOptions = ProcessService.getTabOptions(havePermissionTab, true, true, $ctrl.resolve.havePermissionToEdit);
            $ctrl.maxTitleLength = DESCRIPTIONSIZE.maxLength;
            $ctrl.processDeadline = $ctrl.resolve.highestDeadline;
            $ctrl.URL_REGEX = CONST.URL_REGEX;
            tempProcess = angular.copy($ctrl.process);
            currentUser = $rootScope.identity;
            $ctrl.processStarter = _.find($ctrl.resolve.orgAllUsers, {
              id: $ctrl.process.created_by
            });
            getLastUpdatedDate();
            setUpFroalaEditors();
            if ($ctrl.isDocsPlan) {
              $ctrl.process.tags.data = addTagPillDesign($ctrl.process.tags.data);
              $ctrl.resolve.tags = addTagPillDesign($ctrl.resolve.tags);
            }
            $ctrl.process.tags.data = addTagPillDesign($ctrl.process.tags.data);
            $ctrl.resolve.tags = addTagPillDesign($ctrl.resolve.tags);
            $ctrl.orgId = _.get($rootScope.identity, 'default_organization.id');
            $ctrl.drawerTab = $ctrl.resolve.activeSettingsTab ?
              _.find($ctrl.tabsOptions, { value: $ctrl.resolve.activeSettingsTab.value }) :
              _.first(_.filter($ctrl.tabsOptions, { isEnable: true }));
            if(!_.get($ctrl.drawerTab, 'class')) {
              _.set($ctrl.drawerTab, 'class', 'active');
            }
            $ctrl.htmlPopover = $sce.trustAsHtml($filter('translate')('template.share.helpText'));
            identifyTagsType();
            $ctrl.isRestricted = AuthPlan.isRestricted(PLANS.RESTRICATED_PLANS);
            angular.extend(toolbarButtons, {
              moreMisc: {
                buttons: [
                  ($ctrl.isRestricted) ? 'insertVariableDropdownStarter' : 'insertVariableDropdown'
                ],
                align: 'right',
                buttonsVisible: 2
              }
            });
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              allowEdit: $ctrl.haveAuthority,
              autofocus: false,
              placeholder: $filter('translate')('steps.settings.placeholders.processToLaunchNameFormat'),
              currentUser: $rootScope.identity,
              pluginsEnabled: ['insertVariable'],
              toolbar: toolbarButtons,
              enableFullscreen: true,
              type: 'blueprint'
            }, true, toolbarButtons);
            $ctrl.froalaOptions.variableValue = insertVariables($ctrl.process.prerun);
            $ctrl.froalaOptions.upgradeModal = upgradeModal;
            $ctrl.froalaOptions.resizeable = true;
            $ctrl.ownerChangeBP = $ctrl.isAdminMember || _.includes(owner,_.get($rootScope.identity, 'id'));
            $ctrl.selectedStatus = _.find($ctrl.statusOptions, { value: $ctrl.process.status });
            $ctrl.exampleBP = _.get($stateParams, 'status') === 'example';
            $ctrl.havePermissionToEdit = $ctrl.resolve.havePermissionToEdit;
            $ctrl.havePermissionToRead = $ctrl.resolve.havePermissionToRead;
            $ctrl.havePermissionToLaunch = $ctrl.resolve.havePermissionToLaunch;
            $ctrl.havePermissionToDuplicate = $ctrl.resolve.havePermissionToDuplicate;
            $ctrl.toggleProcessTab = $ctrl.resolve.toggleProcessTab;
            $ctrl.openPowerBuilder = $ctrl.resolve.openPowerBuilder;
            $ctrl.processFiles = _.get($ctrl.process, 'assets.data');
            $ctrl.allowedFileTypes = '.txt,.jpg,.jpeg,.gif,.png,.tif,.bmp,.pdf,.doc,.docx,.xls,.xlsx,.xslt';
            $ctrl.org = _.get($rootScope.identity, 'organizations');
            //Check if user has full permissions or creator or one of the owners
            $ctrl.hasFullPermission = ($ctrl.isAdminMember || _.isEqual(_.get($ctrl.process, 'created_by'), $rootScope.identity.id) || _.includes(owner, $rootScope.identity.id) || $ctrl.havePermissionToEdit);
            $ctrl.isLightMember = _.isEqual(_.get($rootScope, 'identity.role'), "light");
            getFolders();
            $ctrl.userLists = setProcessOwnersList();
            getRunsByChecklist($ctrl.process.id);
          }

          function setProcessOwnersList() {
            var processOwner = [];
            if ($ctrl.resolve.process.users.length === 0) {
               $ctrl.resolve.process.users.push($ctrl.resolve.process.owner_id);
            }
            if ($ctrl.resolve.process.users && $ctrl.resolve.process.users.length > 0) {
              _.forEach($ctrl.resolve.process.users, function(user) {
                processOwner.push(_.find($ctrl.resolve.users, function(u) {
                  return u.id === user;
                }));
              });
            }
            if ($ctrl.resolve.process.groups && $ctrl.resolve.process.groups.length > 0) {
              _.forEach($ctrl.resolve.process.groups, function(user) {
                processOwner.push(_.find($ctrl.resolve.orgGroups, function(u) {
                  return u.id === user;
                }));
              });
            }
            if (processOwner.length < 1) {
              processOwner.push(_.find($ctrl.resolve.process.users, function(u) {
                return u.id === _.get($rootScope.identity, 'id');
              }));
            }
            return processOwner;
          }

          function insertVariables(preruns) {
            var variables = [{
              id: 'DATE',
              label: $filter('translate')('steps.captures.form.label.fields.date'),
              full_label: $filter('translate')('steps.captures.form.label.fields.date')
            }, {
              id: 'TEMPLATE_NAME',
              label: $filter('translate')('global.bluePrintModal.info.typeUniqueNameBP'),
              full_label: $filter('translate')('global.bluePrintModal.info.typeUniqueNameBP')
            }];
            if (!_.isEmpty(preruns)) {
              var truncateFields = _.map(_.filter(preruns, function(f) { return f.required && !_.includes(["textarea", "file", "table"], f.field_type); }) || [], function (field) {
                return angular.extend(field, {
                  full_label: field.label,
                  label: truncateName(field.label, 25),
                  type_field: $filter('translate')('steps.describes.insertVariable.' + field.field_type) + " " + field.alias.split('-').pop()
                });
               }) || [];
              variables.push({
                name: $filter('translate')('steps.logic.label.pre_run_requied'),
                type: 'Prerun',
                capture: truncateFields
              });
            }
            return variables;
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            highestStepDeadlineEventHandler();
            drawerStepUpdateEventHandler();
            processChangesHandler();
            unregisterCloseBPEDetailListener();
            processUpdatedHandler();
            updateFroalaViaCapturesEventHandler();
            onKOFieldAddedHandler();
            folderSelectedWatcher();
            windowElem.off('resize', onWindowResize);
          }

          /**
           * @ngdoc method
           * @name onSelectedOption
           * @description set option selected
           * @param {*} folder
           * @returns void
           */
          function onSelectedOption(folder) {
            $ctrl.selectFolder = folder || {};
          }

          /**
           * @ngdoc method
           * @name setUpFroalaEditors
           * @description
           * Initialize the froala editors options when the drawer open
           * @returns Object
           */
          function setUpFroalaEditors() {
            prepareInsertVariables();
            angular.extend(toolbarButtons, {
              moreMisc: {
                buttons: [
                  ($ctrl.isRestricted) ? 'insertVariableDropdownStarter' : 'insertVariableDropdown'
                ],
                align: 'right',
                buttonsVisible: 2
              }
            });
            $ctrl.froalaForGuidance = Helper.getFroalaOptions({
              allowEdit: $ctrl.haveAuthority,
              placeholder: $filter('translate')('template.placeholder.processInst'),
              currentUser: currentUser,
              toolbar: toolbarButtons,
              enableFullscreen: true,
              type: 'blueprint'
            }, true, toolbarButtons);
            $ctrl.froalaForGuidance.variableValue = $ctrl.insertVariablesForm;
            $ctrl.froalaForGuidance.isFreePlan = AuthPlan.getCurrentPlanCode() === PLANS.FREE;
            $ctrl.froalaForGuidance.upgradeModal = upgradeModal;
            $ctrl.froalaForGuidance.enter = FroalaEditor.ENTER_BR;
            $ctrl.froalaForGuidance.transitionOnShow = true;
            $ctrl.froalaForGuidance.skipBlueprintId = $ctrl.process.id;
            $ctrl.froalaForGuidance.autofocus = false;
            $ctrl.froalaForGuidance.resizeable = true;
            $ctrl.froalaForSummary = angular.copy($ctrl.froalaForGuidance);
            $ctrl.froalaForSummary.placeholderText = $filter('translate')('template.placeholder.templateSummary');
          }

          function upgradeModal() {
            AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'insertVariable');
          }

          /**
           * @ngdoc method
           * @name watchRunData
           * @description
           * watch for changes made in resolved data when the update in the template details
           * @returns void
           */
          function watchRunData() {
            if ($ctrl.resolve.process) {
              processChangesHandler = $scope.$watch('$ctrl.resolve.process', function () {
                prepareProcess();
              }, true);
            }
          }

          /**
           * @ngdoc method
           * @name prepareProcess
           * @description
           * Prepare default process details before loading the drawers
           * @returns void
           */
          function prepareProcess() {
            $ctrl.isWebhook = isWebhook();
            $ctrl.users = _.sortBy($ctrl.resolve.users, 'text');
            $ctrl.steps = _.get($ctrl.resolve.process, 'steps.data');
            $ctrl.createdAtFormatted = DateUtils.toTimezone($ctrl.process.created_at).value();
            $ctrl.userLists = setProcessOwnersList();
            var assignList = [], guestList = [], groupList = [];
            _.forEach($ctrl.resolve.process.steps.data, function(step) {
               if (_.get(step, 'assignees').length > 0) {
                 assignList = _.union(assignList, step.assignees);
               }
               if (_.get(step, 'guests').length > 0) {
                 guestList = _.union(guestList, step.guests);
               }
               if (_.get(step, 'groups').length > 0) {
                 groupList = _.union(groupList, step.groups);
               }
            });
            $ctrl.processCoworkers = setProcessCoworkersList(assignList, groupList);
            $ctrl.processGuests = setProcessGuestsList(guestList);
            $ctrl.selectedStatus = _.find($ctrl.statusOptions, { value: $ctrl.resolve.process.status });
          }

          function setProcessCoworkersList(users, groups) {
            var processOwner = [];
            if (users && users.length > 0) {
              _.forEach(users, function(user) {
                processOwner.push(_.find($ctrl.resolve.users, function(u) {
                  return u.id === user;
                }));
              });
            }
            if (groups && groups.length > 0) {
              _.forEach(groups, function(user) {
                processOwner.push(_.find($ctrl.resolve.orgGroups, function(u) {
                  return u.id === user;
                }));
              });
            }
            return processOwner;
          }

          function setProcessGuestsList(guests) {
            var processOwner = [];
            if (guests && guests.length > 0) {
              _.forEach(guests, function(guest) {
                var search = _.find($ctrl.resolve.guests, { email: guest });
                processOwner.push(search ? search : { email: guest, text: guest, type: "guest" });
              });
            }
            return processOwner;
          }

          /**
           * @ngdoc method
           * @name isTemplateTitle
           * @description Assign value based on process title
           * @returns {Boolean} true/false
           */
          function isTemplateTitle() {
            $ctrl.editTitle = (!$ctrl.process.title);
          }

          /**
           * @ngdoc method
           * @name showSuccessContextAlert
           * @param {String} message
           * @description Showing success global growl message
           * @returns void
           */
          function showSuccessContextAlert(message) {
            growl.success(message, {
              referenceId: 'blueprintContextAlert',
              disableIcons: true,
              disableCloseButton: true
            });
          }

          /**
           * @ngdoc method
           * @name saveProcess
           * @description Save the template
           * @param isClose {Boolean}
           * @returns void
           */
          function saveProcess(isClose) {
            if (isInvalidTemplateForm()) {
              return;
            }
            $ctrl.formInvalid = false;
            beforeStepMetaUpdate();
            angular.extend($ctrl.process.steps.data, removeDeadlineRules($ctrl.steps));
            $ctrl.process.guidance_html = $ctrl.process.guidance;
            $ctrl.process.major_update = true;
            $ctrl.process.user_id = _.head($ctrl.userLists).id;
            $ctrl.process.title = ProcessService.capitalizeFirstLetterOfEachWord($ctrl.process.title);
            if ($ctrl.selectFolder) {
              $ctrl.process.default_folder = _.toString($ctrl.selectFolder.folder_id);
            }
            var users = [], groups = [];
            _.forEach($ctrl.userLists, function(user) {
              if (_.get(user, 'name')) {
                groups.push(user.id);
              } else {
                users.push(user.id);
              }
            });
            if (!$ctrl.isWebhook) $ctrl.process.webhook = '';
            if (!$ctrl.process.auto_naming) $ctrl.process = _.omit($ctrl.process, ['allow_launcher_change_name']);
            blockUI.start();
            ProcessService.update({
              id: $ctrl.process.id,
              skipNotFound: true
            }, angular.extend($ctrl.process, {
               users: users,
               groups: groups
            })).then(function (response) {
              $ctrl.isTemplateOwner = false;
              setBlueprintStatus();
              templateSaved = true;
              angular.extend($ctrl.process, response.data);
              $ctrl.resolve.process = angular.copy($ctrl.process);
              identifyTagsType();
              $ctrl.lastUpdatedFormatted = $ctrl.resolve.process.last_updated;
              blockUI.stop();
              showSuccessContextAlert($filter('translate')('process.messages.processUpdate'));
              resetForm(true);
              $rootScope.$emit('DRAWER:PROCESS:UPDATED', $ctrl.process);
              $rootScope.$emit("NEW_TAG:ADDED", $ctrl.process);
              if (isClose) {
                closeDrawer();
              }
              $rootScope.$emit('ACTIVITY:UPDATES', { auditableId: $ctrl.process.id, auditableType: 'checklist' });
              var owner = _.get($ctrl.process, 'users') && $ctrl.process.users.length < 0 ? $ctrl.process.owner_id : $ctrl.process.users;
              $ctrl.ownerChangeBP = $ctrl.isAdminMember || _.includes(owner, _.get($rootScope.identity, 'id'));
            }, function (error) {
              blockUI.stop();
              if (error.status === 404) {
                growl.error($filter('translate')('steps.messages.bluePrintNotFound'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                $state.go('process.templates', {
                  org_id: _.get($rootScope.identity, 'default_organization.id')
                });
              }
            });
          }

          /**
           * @ngdoc method
           * @name resetForm
           * @param {*} onSaved
           *
           * @description
           * reset run detail form
           */
          function resetForm(onSaved) {
            if (!onSaved) {
              var tags = $ctrl.process.tags.data;
              resetTagsValue(tags);
              var process = transformProcessSteps(tempProcess);
              angular.extend($ctrl.resolve.process, process);
            } else {
              $ctrl.process = $ctrl.resolve.process;
            }
            tempProcess = angular.copy($ctrl.resolve.process);
            $ctrl.form.processDetail.$setPristine();
            $ctrl.formInvalid = false;
          }

          /**
           * @ngdoc method
           * @returns void
           *
           * @description
           * show unsaved confirm modal
           */
          function showConfirmForm() {
            if (isInvalidTemplateForm()) {
              return;
            }

            $confirm({
              'body': 'global.unsaved.body',
              'header': 'global.unsaved.header',
              'buttons': {
                'accept': 'global.unsaved.buttons.accept',
                'cancel': 'global.unsaved.buttons.cancel'
              },
              modalType: 'modal-blueprint'
            }).then(function () {
              saveProcess(true);
            }, function () {
              discardChanges(true);
            });
          }

          /**
           * @ngdoc method
           * @name beforeStepMetaUpdate
           * @description Set allow_guest_owners values zero
           * @returns void
           */
          function beforeStepMetaUpdate() {
            if ($ctrl.process.allow_guests_to_track === 'no') {
              _.forEach($ctrl.process.steps.data, function (step, stepKey) {
                $ctrl.process.steps.data[stepKey].allow_guest_owners = false;
              });
            }
          }

          /**
           * @ngdoc method
           * @name removeDeadlineRules
           * @param {Object} steps
           * @description Delete deadline_rules key if found in the step
           * @returns {Object} stepCopy
           */
          function removeDeadlineRules(steps) {
            var stepCopy = angular.copy(steps);
            _.forEach(stepCopy, function (step) {
              if (_.has(step, 'deadline_rules')) {
                delete step.deadline_rules;
              }
            });
            return stepCopy;
          }

          /**
           * @ngdoc method
           * @name archiveProcess
           * @description archive a process
           */
          function archiveProcess() {
            if (!Helper.checkAccessAuthority())
              return;
            closeDrawer();
            $timeout(function () {
                $ctrl.onSaving = true;
                ProcessService.archiveProcess({
                  id: $ctrl.process.id
                }).then(function () {
                  $ctrl.onSaving = false;
                  growl.success($filter('translate')('process.messages.archive'), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true,
                    variables: {
                      method: unArchiveProcess,
                      param: $ctrl.process,
                      linkText: $filter('translate')('runs.dashboard.undo')
                    }
                  });
                  $state.go('process.templates');
                }, function () {
                  $ctrl.onSaving = false;
                });
            }, 100);
          }

          /**
           * @ngdoc method
           * @name unArchiveProcess
           * @description unarchive a process
           * @param {*} process
           */
          function unArchiveProcess(process) {
            if (!Helper.checkAccessAuthority() || !process)
              return;
            blockUI.start();
            Growl.clearAllMessages('global');
            ProcessService.unArchiveProcess({
              id: process.id
            }).then(function (data) {
              $state.go('process.edit', {
                slug: data.data.id
              });
              growl.success($filter('translate')('process.messages.unArchive'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              blockUI.stop();
            }, function (error) {
              growl.error(error.message);
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name discardChanges
           * @public
           * @param {Boolean} close
           * @description discard the changes made
           * @returns void
           */
          function discardChanges(close) {
            templateSaved = false;
            prepareProcess();
            resetForm();
            setProcessFormPristine();
            if (close) closeDrawer();
          }

          /**
           * @name resetTagsValue
           * @param {*} tags
           * @returns void
           *
           * @description
           * revert tags value from API
           */
          function resetTagsValue(tags) {
            var originTags = tempProcess.tags.data;
            if (_.isEqual(tags, originTags)) return;

            var tagPromises = [],
              attachTags = getFiltersTag(originTags, tags),
              checklistParam = {
                type: 'Checklist',
                id: $ctrl.process.id
              };

            if (attachTags && attachTags.length > 0) {
              _.forEach(attachTags, function (tag) {
                var attachTag = {
                  id: tag.tag_id,
                  title: tag.title
                };
                tagPromises.push(TagsService.attachTag(attachTag, checklistParam));
              });
            }

            var detachTags = getFiltersTag(tags, originTags);

            if (detachTags && detachTags.length > 0) {
              _.forEach(detachTags, function (tag) {
                var tagId = _.isNumber(tag.id) ? tag.id : tag.tag_id;
                tagPromises.push(TagsService.detachTag(tagId, checklistParam));
              });
            }

            $q.all([tagPromises])
              .then(function () {
                angular.noop();
              }, function () {
                angular.noop();
              });
          }

          /**
           * @ngdoc method
           * @name getFiltersTag
           * @param {Object} origin
           * @param {String} desc
           * @description Find and return tag
           * @returns {Object} tag
           */
          function getFiltersTag(origin, desc) {
            _.filter(origin, function (tag) {
              return !_.find(desc, {
                id: tag.id
              });
            });
          }

          /**
           * @ngdoc method
           * @name closeModal
           * @public
           * @description
           * Close more detail madal window
           * Fire validation using manually submit the form when the user clicks on "close" button
           * @param {event} $event
           * @returns void
           */
          function closeModal($event) {
            Growl.clearAllMessages('blueprintContextAlert');
            if (_.get($ctrl.form, 'processPermissionForm.$dirty')) {
              if ((_.get($ctrl.form.processPermissionForm, 'editAssignee') && $ctrl.form.processPermissionForm.editAssignee.$dirty) ||
                (_.get($ctrl.form.processPermissionForm, 'launchAssignee') && $ctrl.form.processPermissionForm.launchAssignee.$dirty) ||
                (_.get($ctrl.form.processPermissionForm, 'processAssignee') && $ctrl.form.processPermissionForm.processAssignee.$dirty) ||
                (_.get($ctrl.form.processPermissionForm, 'readAssignee') && $ctrl.form.processPermissionForm.readAssignee.$dirty)) {
                if ($event) {
                  $event.stopPropagation();
                }
                growl.error($filter('translate')('permissions.errorMessage.unsaveChanges'), {
                  referenceId: 'blueprintPermissionAlert',
                  disableIcons: true,
                  disableCloseButton: true
                });
                return;
              } else {
                discardChanges(true);
              }
            }
            _.get($ctrl.form, 'processDetail.$pristine') ? closeDrawer() : showConfirmForm();
          }

          /**
           * @ngdoc method
           * @returns {void}
           *
           * @description
           * close the drawer
           */
          function closeDrawer() {
            $ctrl.dismiss();
            $rootScope.$broadcast('STEP:CHANGED_SELECTED_FORM_TAB');
            RunDetailService.closeDrawer();
          }

          /**
           * @ngdoc method
           * @name toggleProcessTab
           * @description Return true/false value basen on old value
           */
          function toggleProcessTab() {
            $ctrl.showPermissions = false;
            if (!$ctrl.formInvalid) {
              $ctrl.showProcessAudit = !$ctrl.showProcessAudit;
            }
          }

          /**
           * @ngdoc method
           * @name toggleTemplateOwner
           * @description Return true/false value basen on old value
           */
          function toggleTemplateOwner() {
            if (!Helper.checkAccessAuthority())
              return;
            $ctrl.isTemplateOwner = !$ctrl.isTemplateOwner;
          }

          /**
           * @function
           * @name isUserExist
           * @param user
           * @description Check a user already exist
           */
          function isUserExist(user) {
            return _.some(_.concat($ctrl.resolve.users, $ctrl.resolve.orgGroups), function (owner) {
              return (owner.email === user.text || user.email) || owner.text === user.text;
            });
          }

          /**
           * @function
           * @name onUserSelect
           * @param user
           * @description On select user in the tags input
           */
          function onUserSelect(user) {
            return checkUserStatus(user);
          }

          /**
           * @function
           * @name checkUserStatus
           * @param user
           * @description Return true/false based on the conditions
           */
          function checkUserStatus(user) {
            return isUserExist(user);
          }

          /**
           * @function
           * @name onRemovingUser
           * @param {Object} $owner
           * @description check before removing user. if only one coworker then it won't delete the user
           */
          function onRemovingUser($owner) {
            return checkUserStatus($owner) && $ctrl.userLists.length > 1;
          }

          /**
           * @function
           * @name getTagClass
           * @param {Object} user
           * @description Callback function tagClass in ngTagsInput
           */
          function getTagClass(user) {
            if (Helper.isValidEmail(user.text)) {
              return 'tag-email';
            }
          }

          /**
           * loadUsers
           * filters tags based on search text
           * @param  {string} $query A search string
           * @return {array} An array of filtered tags
           */
          function loadUsers($query) {
            return _.filter(_.concat($ctrl.resolve.users, $ctrl.resolve.orgGroups), function (tag) {
              return tag.text.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
          }

          /**
           * @ngdoc method
           * @name templateOwnersAsStr
           * @returns {String} owners
           */
          function templateOwnersAsStr() {
            return _.join(_.map($ctrl.userLists, 'text'), ', ');
          }

          /**
           * @ngdoc method
           * @name defaultAvatar
           * @description set default avatar
           * @param {string} avatar
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText
           * @public
           * @description set default avatar Text
           * @param {string} firstname
           * @param {string} lastname
           * @return {string} first character of name
           */
          function defaultAvatarText(firstname, lastname) {
            return TasksService.setDefaultAvatarText(firstname, lastname);
          }

          /**
           * @ngdoc method
           * @name isValidTemplateForm
           * @description Return true/false value based on form valid/invalid
           * @return {Boolean}
           */
          function isInvalidTemplateForm() {
            var isInvalid = $ctrl.form.processDetail.$invalid;

            if (isInvalid) {
              $ctrl.formInvalid = true;
              $rootScope.$broadcast('STEP:CHANGED_SELECTED_FORM_TAB');
              goToErrorInput();
            }
            if ($ctrl.process.auto_naming && $ctrl.process.default_process_name_format.length > $ctrl.maxAutoNameTitleLength) {
              isInvalid = true;
            } else {
              isInvalid = false;
            }

            return isInvalid;
          }

          /**
           * @ngdoc method
           * @name isWebhook
           * @return {Boolean}
           *
           * @description
           * to check webhook is exist or not on resolve.process
           */
          function isWebhook() {
            return !!$ctrl.resolve.process.webhook;
          }

          /**
           * @ngdoc method
           * @name hasAtLeastOneField
           * @description return the boolean about child fields
           */

          function hasAtLeastOneField() {
            var filtered_steps = $ctrl.steps.filter(function (step) {
              return step.captures.length > 0;
            });
            return filtered_steps.length;
          }

          /**
           * @ngdoc method
           * @returns void
           *
           * @description
           * scrolling to error input
           */
          function goToErrorInput() {
            DOMService.centerObjectToView(DOM.ERROR_VIEW.ELEMENT);
          }

          /**
           * @ngdoc method
           * @name setProcessFormPristine
           * @returns void
           *
           * @description
           * check and set process detail's form pristine
           */
          function setProcessFormPristine() {
            var owner = _.get($ctrl.process, 'users') && $ctrl.process.users.length < 0 ? $ctrl.process.owner_id : $ctrl.process.users;
            if (_.includes(owner, $ctrl.userLists[0].id)) {
              $ctrl.form.processDetail.$setPristine();
            }
          }

          /* event handler for getting highest step deadline in steps
           * @type {*|(function())}
           */
          highestStepDeadlineEventHandler = $rootScope.$on('PROCESS:STEP_HIGHEST_DEADLINE', function (event, highestDeadline) {
            $ctrl.processDeadline = highestDeadline;
          });

          /**
           * event handler when run details sidebar needs to close
           * @type {*|(function())}
           */
          unregisterCloseBPEDetailListener = $rootScope.$on('BPE_DETAIL:CLOSE', function (event) {
            closeModal(event);
          });

          /**
           * @name transformProcessSteps
           * @description remove helper props on process.steps.data
           * @param {Object} process
           * @returns process object
           */
          function transformProcessSteps(process) {
            process.steps.data = angular.copy($ctrl.resolve.process.steps.data);
            if (_.get(process, 'steps.data')) {
              process.steps.data = _.map(process.steps.data, function (step) {
                return _.omit(step, ['isFFSave']);
              });
            }
            return process;
          }

          /**
           * @name getSaveText
           * @description return text based on condition
           * @returns button text
           */
          function getSaveText() {
            if ($ctrl.form.processDetail.$dirty || !templateSaved) {
              return $filter('translate')('steps.buttons.save_step');
            }
            return $filter('translate')('steps.buttons.saved');
          }

          /**
           * @function
           * @name changeTab
           * @description Execute when change tab in Mobile view to check if it has something need to be saved.
           * @param {integer} selectedIndex
           * @param {object} tab
           * @param {event} $event
           */
          function changeTab(selectedIndex, tab, $event) {
            if (selectedIndex !== 4 && _.get($ctrl.form, 'processPermissionForm.$dirty')) {
              $event.stopPropagation();
              growl.error($filter('translate')('permissions.errorMessage.unsaveChanges'), {
                referenceId: 'blueprintPermissionAlert',
                disableIcons: true,
                disableCloseButton: true
              });
              return;
            }

            if (isInvalidTemplateForm()) {
              $event.stopPropagation();
              return;
            }
            if (_.get($ctrl.form, 'processDetail.$dirty') || _.get($ctrl.captureForm, '$dirty')) {
              showConfirmForm();
            }
            if ($ctrl.drawerTab.value === $ctrl.tabsOptions[selectedIndex].value) {
              return;
            }

            tab.class = '';
            $ctrl.drawerTab = $ctrl.tabsOptions[selectedIndex];
            $ctrl.drawerTab.class = 'active';
            eventTabTracker($ctrl.tabsOptions[selectedIndex]);
          }

          /**
           * @function
           * @name isActiveContent
           * @description Set active content as selected tab.
           * @param {String} tabValue
           * @returns {Boolean}
           */
          function isActiveContent(tabValue) {
            return (tabValue === $ctrl.drawerTab.value);
          }

          /**
           * @ngdoc method
           * @name getLastUpdatedDate
           * @description get latest updated date
           */
          function getLastUpdatedDate() {
            if ($ctrl.resolve.process.steps.data.length) {
              var maxDate = _.orderBy($ctrl.resolve.process.steps.data, 'last_updated', 'desc');
              $ctrl.lastUpdatedFormatted = DateUtils.toTimezone(_.max([maxDate[0].last_updated, $ctrl.resolve.process.last_updated])).value();
            } else {
              $ctrl.lastUpdatedFormatted = DateUtils.toTimezone($ctrl.resolve.process.last_updated).value();
            }
          }

          function setTitleEditable() {
            if (!Helper.checkAccessAuthority() || !$ctrl.havePermissionToEdit)
              return;
            modalInstance = $uibModal.open({
              component: 'processRenameModal',
              windowClass: 'process-edit-modal',
              backdrop: 'static',
              resolve: {
                titleName: function () {
                  return $ctrl.process.title;
                }
              }
            });
            modalInstance.result.then(function (res) {
              $ctrl.process.title = res;
              saveProcess('major_update', true);
            }, function () {
              $log.info('modal is cancelled');
            });
          }

          function checkAuthority() {
            if (!Helper.checkAccessAuthority()) {
              $ctrl.fieldModel = null;
              return;
            }
          }

          /**
           * @name copyZeroLaunchLink
           * @description
           * generate and copy zero click launch link
           */
          function copyZeroLaunchLink() {
            var runName,
              koFields,
              URIParams,
              launchLink;

            runName = $filter('defaultTitle')($ctrl.process.title, {
              limit: DESCRIPTIONSIZE.maxProcessTitleLength
            });
            koFields = RunsService.getPrerunValues($ctrl.process.prerun);
            URIParams = 'default_run_name=' + runName + '&ko_fields=' + JSON.stringify(koFields) + '&launchprocess=true';
            launchLink = location.origin + '/' + $state.params.org_id + '/process/' + $ctrl.process.id + '/create?' + URIParams;
            clipboard.copyText(encodeURI(launchLink));
            growl.success($filter('translate')('global.growlMessages.copied.copyZeroClick'), {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            });
          }

          /**
           * @function
           * @name isActiveContent
           * @description Set active content as selected tab.
           * @param {String} tabValue
           * @returns {Boolean}
           */
          function isActiveContent(tabValue) {
            if (tabValue === $ctrl.drawerTab.value) {
              return true;
            }
            return false;
          }

          /**
           * @function
           * @name copyToClipboard
           * @description copy handler
           * @param {String} text
           * @param {*} target
           * @param {event} $event
           */
          function copyToClipboard(text, target, $event) {
            $ctrl.highlight = target;
            if ($event) {
              $event.stopPropagation();
            }
            clipboard.copyText(text);
            var message = $filter('translate')('global.growlMessages.copied.message', { type: $filter('translate')('template.share.label.' + target) });
            growl.success(message, { referenceId: 'shareBluePrint', disableIcons: true, disableCloseButton: true });
          }

          /**
           * @name addTagPillDesign
           * @description add tag pill text color
           * @param {*} tags
           */
          function addTagPillDesign(tags) {
            _.forEach(tags, function (tag) {
              if (tag.color) {
                tag.textColor = $ctrl.setTagTextColor(tag.color);
              }
            });
            return tags;
          }

          /**
           * @name identifyTagsType
           * @description
           * identify tags type
           */
          function identifyTagsType() {
            $ctrl.isProcessHavingDocumentType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.DOCUMENT;
            $ctrl.isProcessHavingFormType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.FORM;
          }

          function prepareInsertVariables() {
            var preruns = $ctrl.process.prerun || [];
            if (!_.isEmpty(preruns)) {
              $ctrl.prerun = truncateFields(preruns);
              $ctrl.insertVariablesForm.push({
                name: $filter('translate')('steps.logic.label.pre_run'),
                type: 'Prerun',
                capture: $ctrl.prerun
              });
            }
            var steps = $ctrl.process.steps.data;
            _.map(steps, function (step, key) {
              if (step.captures.length > 0) {
                $ctrl.captures = truncateFields(step.captures);
                $ctrl.insertVariablesForm.push({
                  id: step.id,
                  name: $filter('translate')('steps.describes.insertVariable.step', { stepNumber: key + 1, stepName: truncateName(step.title, 30) }),
                  type: 'Step',
                  capture: $ctrl.captures
                });
              }
            });
          }

          function truncateFields(fields) {
            if (!_.isArray(fields) || _.isEmpty(fields)) {
              return [];
            }
            var copyFields = angular.copy(fields);
            copyFields = _.filter(copyFields, function (cpField) {
              return !cpField.isNew;
            });
            return _.map(copyFields, function (field) {
              return angular.extend(field, {
                full_label: field.label,
                label: truncateName(field.label, 25),
                type_field: prepareFieldsType(field.field_type) + " " + field.alias.split('-').pop()
              });
            });
          }

          function truncateName(name, length) {
            if (_.size(name) > length) {
              return name.substring(0, length) + '...';
            }
            return name;
          }

          function prepareFieldsType(fieldType) {
            return $filter('translate')('steps.describes.insertVariable.' + fieldType);
          }

          function reInitFroalaEditorVariables() {
            $ctrl.insertVariablesForm = [];
            prepareInsertVariables();
            if ($ctrl.froalaForGuidance) {
              $ctrl.froalaForGuidance.variableValue = $ctrl.insertVariablesForm;
              var editor = _.get($ctrl.froalaForGuidance, 'froalaEditor', {});
              if (editor.insertVariable) {
                editor.insertVariable.updateVariableValues(editor, $ctrl.insertVariablesForm);
                $ctrl.froalaForGuidance.updateVariableValues($ctrl.insertVariablesForm);
              }
            }
          }

          function eventTabTracker(tab) {
            if (!tab.eventText) {
              return;
            }
            $analytics.eventTrack('BPE - Settings Sidebar' + ' > ' + tab.eventText + ' Tab', { category: 'BPE - Settings Sidebar' });
          }

          /**
           * @ngdoc method
           * @name setBlueprintStatus
           * @description Set or Update Blueprint status
           */
          function setBlueprintStatus() {
            StepService.updateBlueprintStatus({
              checklist_id: _.get($ctrl.process, 'id'),
              status: _.get($ctrl.selectedStatus, 'value')
            }).then(function () {
              angular.extend($ctrl.resolve.process, { status: _.get($ctrl.selectedStatus, 'value') });
            });
          }

          function openBuilder() {
            $ctrl.openPowerBuilder();
          }

          /**
           * @function
           * @name uploadFiles
           * @param {Object} files
           * @description Upload files
           * @returns void
           */
          function uploadFiles(files) {
            blockUI.start();
            if (files && _.isArray(files) && files.length === 0) {
              $ctrl.files = null;
              blockUI.stop();
              Growl.clearAllMessages('global');
              growl.error($filter('translate')('template.title.errorMessage.filesExtension'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              return;
            }

            $q.all(files.forEach(function (file) {
              saveFile(file);
            })).then(function () {
              $ctrl.files = null;
              blockUI.stop();
            }, function () {
              $ctrl.files = null;
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name saveFile
           * @param {Object} file
           * @description Save file
           * @returns void
           */
          function saveFile(file) {
            blockUI.start();
            var data = {
              name: file,
              uploaded_from: ($ctrl.process.type === 'procedure') ? 'procedure_bp_config' : ($ctrl.process.type === 'document') ? 'document_bp_config' : 'form_bp_config',
              subject_type: 'Checklist',
              subject_id: $ctrl.process.id,
              source: 'local'
            };
            Upload.upload({
              url: ENV_CONFIG.API_HOST + '/organizations/' + $ctrl.orgId + '/file',
              data: data
            }).then(function (resp) {
              $ctrl.files = null;
              $ctrl.processFiles.push(resp.data.data);
              blockUI.stop();
            });
          }

          /**
          * @function
          * @name deleteAsset
          * @param {Object} file
          * @param {Integer} i
          * @description Delete asset
          * @returns void
          */
          function deleteAsset(file, i) {
            $ctrl.files = null;
            $confirm({
              'header': $filter('translate')('global.confirmAction.header', {
                actionName: 'Delete',
                componentName: 'File'
              }),
              'body': $filter('translate')('global.confirmAction.body', {
                actionName: 'delete',
                componentName: file.filename
              }),
              'buttons': {
                'accept': $filter('translate')('global.confirmAction.buttons.accept', { actionName: 'Delete File' }),
                'cancel': 'global.confirmAction.buttons.close'
              },
              modalType: 'modal-danger'
            }).then(function () {
              blockUI.start();
              var params = {
                id: _.get(file, 'id'),
                org: $ctrl.org.id
              };
              var resource = FilesService.delete(params);
              resource.then(function () {
                _.remove($ctrl.processFiles, { id: file.id });
                blockUI.stop();
              }, function () { });
            });
          }

          /**
           * @ngdoc method
           * @name onNewFolderForBlueprint
           * @param {*} process
           *
           * @description
           * on new folder for blueprint added / directly added blueprint into a folder after created
           */
          function onNewFolderForBlueprint(process) {
            if (!$ctrl.haveAuthority) {
              return;
            }
            var createModal = FolderService.openFolderModal(null, null, 'checklist');
            createModal.result.then(function (response) {
              var newFolder = response.data;
              process.folder_id = newFolder.id;
              ProcessService.update({
                id: process.id
              }, process).then(function (res) {
                newFolder.total = 0;
                var folderExist = _.find($ctrl.folderItemsToDisplay, { type: 'folder' });
                if ((folderExist || $ctrl.pager.current_page === 1) && !$ctrl.folderID) {
                  $ctrl.folderItemsToDisplay.splice(0, 0, {
                    id: newFolder.id,
                    type: 'folder',
                    data: newFolder
                  });
                }
                var folderIndex = _.findIndex($ctrl.folders, { id: newFolder.id });
                if (folderIndex >= 0) {
                  $ctrl.folderID ? $ctrl.folders[folderIndex].total-- : $ctrl.folders[folderIndex].total++;
                }
                growl.success($filter('translate')('runs.messages.folder.addedToFolder', {
                  bpName: res.data.title
                }), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              });
            });
          }

          /**
           * @ngdoc method
           * @name onMoveToFolder
           * @param {*} process
           * @param {*} folder
           *
           * @description
           * move blueprint to a folder
           */
          function onMoveToFolder(process, folder) {
            if (!$ctrl.haveAuthority)
              return;
            if (_.get(folder, 'id') !== _.get(process, 'folder.data.id')) {
              blockUI.start();
              process.folder_id = _.get(folder, 'id');
              ProcessService.update({
                id: process.id,
                skipNotFound: true
              }, process).then(function(res) {
                blockUI.stop();
                Growl.clearAllMessages('global');
                growl.success($filter('translate')('runs.messages.folder.movedToFolder', {
                    bpName: res.data.title
                }), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                });
              }, function(error) {
                blockUI.stop();
                if (error.status === 404) {
                  Growl.clearAllMessages('global');
                  growl.error($filter('translate')('steps.messages.bluePrintNotFound'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                }
              });
            } else {
              Growl.clearAllMessages('global');
              $timeout(function () {
                growl.warning($filter('translate')('runs.messages.folder.alreadyOnFolder', {
                  bpName: _.get(process, 'title'),
                  folderName: _.get(folder, 'name')
                }), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, 0);
            }
          }

          /**
           * @ngdoc method
           * @name onRemoveFromFolder
           * @param {*} process
           *
           * @description
           * remove blueprint to a folder
           */
          function onRemoveFromFolder(process) {
            if (!$ctrl.haveAuthority)
              return;
            blockUI.start();
            process.folder_id = null;
            ProcessService.update({
              id: process.id
            }, process).then(function(res) {
              ProcessService.setPinned({
                id: _.get(res, "data.id"),
                is_pinned: false
              }).then(function () { }, function () {});
              blockUI.stop();
              growl.success($filter('translate')('runs.messages.folder.removedFromFolder', {
                bpName: res.data.title
              }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            });
          }

          function getFolders() {
            var params = {
              pagination: false,
              sort: 'title',
              with: 'children',
              folder_type: 'run'
            };
            FolderService.getTaskOrRunFolders(params)
              .then(function (res) {
                foldersList = _.sortBy(_.get(res, 'data'), 'name');
                $ctrl.foldersList = _.concat($ctrl.foldersList, foldersList);
                setDefaultSelect(foldersList);
              });
          }

          function setDefaultSelect(list) {
            if (list) {
                var findSelected = _.find(list, { id: $ctrl.process.default_folder });
                if (findSelected) {
                   $ctrl.selectFolder = $ctrl.selectedFolder = findSelected;
                   return;
                } else {
                   _.forEach(list, function (folder) {
                      if (_.get(folder, 'children.data').length > 0) {
                        setDefaultSelect(folder.children.data);
                      }
                   });
                }
            }
          }

          /**
           * @function
           * @name onFolderSeletionToggle
           * @param {*} folder
           * @description
           * Toggle folder selection
           */
          function onFolderSeletionToggle($select, folder) {
            if (folder) {
              $ctrl.selectFolder = folder;
              if ($ctrl.selectFolder) {
                _.set($ctrl.process, 'default_folder' , _.get($ctrl.selectFolder, 'folder_id'));
                $ctrl.form.processDetail.$pristine = false;
              }
            }
          }

          /**
           * @function
           * @name onFolderTreeToggle
           * @param {*} folder
           * @description
           * Toggle folder tree
           */
          function onFolderTreeToggle(folder) {
            folder.isExpanded = !folder.isExpanded;
            if (folder.children.data.length > 0) {
              FolderService.collapseAllChildFolders(folder.children.data);
            }
          }

          /**
           * @function
           * @name onCreateFolder
           * @param {*} folder
           * @description
           * Create new folder
           */
          function onCreateFolder(folder) {
            var createModal = FolderService.openFolderModal(null, _.get(folder, 'id', 'root'), 'run');
            createModal.result.then(function (response) {
              response.data.folder_name = response.data.name;
              response.data.folder_id = response.data.id;
              if (folder) {
                folder.children.data.push(response.data);
                folder.children.data = _.sortBy(folder.children.data, 'name');
              } else {
                foldersList.push(response.data);
                foldersList = _.sortBy(foldersList, 'name');
                $ctrl.foldersList = angular.copy(foldersList);
              }
            });
          }

          //Window resize handler
          function onWindowResize () {
            if ($window.innerWidth > 1200) {
              $ctrl.titleLimit = 50;
            } else if ($window.innerWidth > 875 || ($window.innerWidth < 767 && $window.innerWidth > 480)) {
              $ctrl.titleLimit = 35;
            } else {
              $ctrl.titleLimit = 25;
            }
          }

          //Get runs by checklist
          function getRunsByChecklist (processId) {
            RunRepository.getRunsData({
              status: "active",
              checklist_id: processId,
              per_page: 1
            }).then(function (res) {
              $ctrl.activeProcesses = res.meta.pagination.total;
            }, function () {
              $ctrl.activeProcesses = null;
            })
          }

          // Handle update template status
          function handleTemplateStatusUpdate () {
            $ctrl.form.processDetail.$setPristine();
            setBlueprintStatus();
          }

          //Create a task to request permission from template owners
          function createPermissionTask () {
            var taskObj = prepareTaskValues();
            blockUI.start();
            TasksService.createStandaloneTask(taskObj, false).then(function (res) {
              TasksService.createStandaloneTaskLinkByBlueprint({
                id: _.get(res.data, 'id'),
                checklist_id: taskObj.checklist_id
              }).then(function (response) {
                blockUI.stop();
                growl.success($filter('translate')('tasks.permissionTaskSuccess'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, function (err) {
                blockUI.stop();
              });
            }, function (err) {
              blockUI.stop();
            });
          }

          //Prepare task object for creating permission task
          function prepareTaskValues () {
            var startedAt = DateUtils.toTimezone().value().add(2, 'h').format(),
            user = $rootScope.identity, displayName = (user.full_name + ' (' + user.email + ')'),
            taskSummary = $ctrl.isLightMember ?
              $filter('translate')('tasks.permissionTaskSummaryLight', { templateName: $ctrl.process.title, user: displayName, userId: user.id }) :
              $filter('translate')('tasks.permissionTaskSummary', { templateName: $ctrl.process.title, user: displayName });
            return {
              started_at: DateUtils.toUTC(moment(startedAt).format()).format(DATEFORMAT.DEFAULT),
              deadline: DateUtils.toUTC(moment(startedAt).add(1, 'd').format()).format(DATEFORMAT.DEFAULT),
              owners: {
                users: $ctrl.process.users,
                guests: [],
                groups: []
              },
              task_type: 'expiring',
              title: $filter('translate')('tasks.permissionTaskTitle', { templateName: $ctrl.process.title, user: displayName }),
              summary: taskSummary,
              checklist_id: $ctrl.process.id
            };
          }

          function checkAutoNameValidation () {
            if ($ctrl.process.auto_naming && $ctrl.process.default_process_name_format.length > $ctrl.maxAutoNameTitleLength) {
              $ctrl.checkMaxTitleValidation = true;
              $ctrl.form.processDetail.$pristine = true;
            } else {
              $ctrl.form.processDetail.$pristine = false;
              $ctrl.checkMaxTitleValidation = false;
            }
          }

          folderSelectedWatcher = $rootScope.$on('FOLDER:CLICK', function (e, data) {
            $ctrl.onMoveToFolder(data.blueprint, data.folder);
          });

          updateFroalaViaCapturesEventHandler = $rootScope.$on('STEP:UPDATED', function (e, data) {
            reInitFroalaEditorVariables();
          });

          onKOFieldAddedHandler = $rootScope.$on('KO_FIELD:UPDATED', function (e, data) {
            reInitFroalaEditorVariables();
          });

          /**
           * @name drawerStepUpdateEventHandler
           *
           * @description
           * update drawer process object when step update
           */
          drawerStepUpdateEventHandler = $rootScope.$on(TFY_EVENTS.STEP.UPDATE_DRAWER_STEP, function (event, updatedData) {
            if (_.get(updatedData, 'step')) {
              tempProcess.steps.data = angular.copy(updatedData.step);
            }
            if (_.get(updatedData, 'last_updated')) {
              $ctrl.lastUpdatedFormatted = updatedData.last_updated;
            }
          });

          processUpdatedHandler = $rootScope.$on('PROCESS:UPDATED', function () {
            if ($ctrl.form.processDetail) {
              $ctrl.form.processDetail.$setPristine();
            }
          });
        }
    });
})();
