/**
 * @ngdoc Component
 * @name tallyfy.process.component.readBluePrint
 * @module tallyfy.process
 *
 * @description
 * A component to display process in read only mode
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.process')
    .component('readBlueprint', {
      templateUrl: 'app/modules/process/components/readBluePrint/readBluePrint.html',
      bindings: {
        activeProcess: '<',
        process: '<',
        run: '<',
        prerunValues: '=',
        availableUsers: '<',
        launchProcess: '&?',
        showButton: '<?',
        orgGroups: '<?',
        usersInOrg: '<',
        tasks: '=',
        froalaViewBusy: '=?',
        onArchiveProcess: '&?',
        hidePrintSelector: '<',
        isPublicProcess: '<',
        metadata: '=?',
        editableField: '<',
        readMode: '<',
        orgRoles: '<?',
        embedded: '<?',
        stages: '<?',
        stageSteps: '<?',
        runTasks: "<?",
        showHiddenTasks: '<?',
        guestDocTasks: '<?'
      },
      controller:
        /*@ngInject*/
        function (ProcessService, $filter, $localStorage, ProcessRepository, $element, TasksService, StepService, _, CONST, CompactStepService, BLUEPRINT_TYPE, $uibModal, $location, Helper, RunsService, PublicOrganizationService,
          $rootScope, $state, blockUI, AuthPlan, $stateParams, KendoUIService, ReadBlueprintTableService, USER_STATE, $analytics, Growl, $timeout, FolderService, UtilsService, CompactTaskService, FieldService) {
          var $ctrl = this,
            updatedSummary,
            allFormFields,
            growl = new Growl(),
            body = angular.element('body'),
            folderSelectedWatcher,
            koFieldClickHandler,
            fieldEditorSelectedWatcher,
            blueprintToggleViewWatcher,
            startIndexChangedWatcher,
            usersInOrg = [],
            stepLogic;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.stepsRuleTerms = {};
          $ctrl.launchButton = false;
          $ctrl.shareButton = false;
          $ctrl.insertVariablesForm = [];
          $ctrl.isHover = {};
          $ctrl.onSaving = {};
          $ctrl.completePercent = {};
          $ctrl.completeProgressCaption = {};
          $ctrl.approveProgressCaption = {};
          $ctrl.rejecteProgressCaption = {};
          $ctrl.guestCompleters = {};
          $ctrl.onRejectSaving = {};
          $ctrl.minified = {};
          $ctrl.affectingAutomations = {};
          $ctrl.asAutomationTriggers = {};

          /**
           * public methods
           */
          $ctrl.stepOwnersAsStr = stepOwnersAsStr;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.isHiddenStep = isHiddenStep;
          $ctrl.getStepSummary = getStepSummary;
          $ctrl.startRun = startRun;
          $ctrl.shareBPLink = shareBPLink;
          $ctrl.checkKOFormField = checkKOFormField;
          $ctrl.onShareBlueprintClick = onShareBlueprintClick;
          $ctrl.customizeBlueprint = customizeBlueprint;
          $ctrl.getMemberCompleteProgress = getMemberCompleteProgress;
          $ctrl.getCompleteProgress = getGuestCompleteProgress;
          $ctrl.setBlueprintStatus = setBlueprintStatus;
          $ctrl.isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
          $ctrl.statusOptions = StepService.getProgressStatus();
          $ctrl.initTableConfig = initTableConfig;
          $ctrl.onFavoriteClick = onFavoriteClick;
          $ctrl.onPlayClick = onPlayClick;
          $ctrl.stateParams = $stateParams;
          $ctrl.onRemoveFromFolder = onRemoveFromFolder;
          $ctrl.duplicateTemplate = ProcessService.showDuplicateTemplateModal;
          $ctrl.onNewFolderForBlueprint = onNewFolderForBlueprint;
          $ctrl.onNewFolderClick = onNewFolderClick;
          $ctrl.onNewFolderAdded = onNewFolderAdded;
          $ctrl.haveAuthority = Helper.checkAccessAuthority;
          $ctrl.onMoveToFolder = onMoveToFolder;
          $ctrl.resetSelectedBP = resetSelectedBP;
          $ctrl.toggleCommentsModal = toggleCommentsModal;
          $ctrl.goToStep = goToStep;
          $ctrl.onCompleteKoTaskClick = onCompleteKoTaskClick;
          $ctrl.onCompleteTaskClick = onCompleteTaskClick;
          $ctrl.onCaptureUpdated = onCaptureUpdated;
          $ctrl.isTaskCompletable = isTaskCompletable;
          $ctrl.iHaveCompleted = iHaveCompleted;
          $ctrl.getButtonClass = getButtonClass;
          $ctrl.getButtonText = getButtonText;
          $ctrl.getButtonIconClass = getButtonIconClass;
          $ctrl.getApprovalButtonIconClass = getApprovalButtonIconClass;
          $ctrl.getApprovalButtonText = getApprovalButtonText;
          $ctrl.getExpiringButtonText = getExpiringButtonText;
          $ctrl.getApprovalButtonClass = getApprovalButtonClass;
          $ctrl.isHasIssue = CompactTaskService.isHasIssue;
          $ctrl.getStepPosition = getStepPosition;
          $ctrl.onViewBluePrint = onViewBluePrint;
          $ctrl.getStepTypeRuleActions = getStepTypeRuleActions;
          $ctrl.shouldShowInstructions = shouldShowInstructions;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.showMeta = false;
            $ctrl.stepTypes = StepService.getStepTypes();
            $ctrl.isGuest = $rootScope.userState === USER_STATE.GUEST;
            usersInOrg = ($ctrl.isGuest) ? _.get($rootScope.identity, 'guest.organization.users', [])
              : angular.copy($ctrl.availableUsers);
            $ctrl.usersInOrg = angular.copy(usersInOrg);
            $ctrl.selectedStatus = _.find($ctrl.statusOptions, { value: $ctrl.process.status });
            $ctrl.exampleBP = _.get($stateParams, 'status', '') === 'example';
            var currentUserId = _.get($rootScope, 'identity.id');
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.isLightMember = _.isEqual(_.get($rootScope, 'identity.role'), "light");
            var steps = [];
            if ($ctrl.guestDocTasks && ($ctrl.isGuest || $ctrl.isPublicProcess)) {
              steps = $ctrl.guestDocTasks;
            } else {
              steps = angular.copy($ctrl.run ? ($ctrl.showHiddenTasks ? getRunTasks($ctrl.runTasks) : _.get($ctrl.run, 'tasks.data')) : _.get($ctrl.process, 'steps.data'));
            }
            setStepsForBlueprint(steps);
            $ctrl.stageStepsInOrder = mapStageStepOrder(steps);
            angular.extend($ctrl.process, {
              havePermissionToDuplicate: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, $ctrl.process.created_by, currentUserId, _.get($ctrl.process, 'permissions.data.checklist_duplicate', []), _.get($ctrl.process, 'permissions.data.checklist_duplicate_group', []), $ctrl.orgGroups),
              havePermissionToEdit: !$ctrl.isLightMember && UtilsService.hasSpecificPermissions($ctrl.isAdminMember, $ctrl.process.created_by, currentUserId, _.get($ctrl.process, 'permissions.data.checklist_edit', []), _.get($ctrl.process, 'permissions.data.checklist_edit_group', []), $ctrl.orgGroups),
              havePermissionToRead: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, $ctrl.process.created_by, currentUserId, _.get($ctrl.process, 'permissions.data.checklist_read', []), _.get($ctrl.process, 'permissions.data.checklist_read_group', []), $ctrl.orgGroups),
              havePermissionToLaunch: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, $ctrl.process.created_by, currentUserId, _.get($ctrl.process, 'permissions.data.process_launch', []), _.get($ctrl.process, 'permissions.data.process_launch_group', []), $ctrl.orgGroups)
            });
            if ($ctrl.run && $ctrl.tasks && $ctrl.tasks.length && $ctrl.run.status !== 'archived') {
              prepareInsertVariables($ctrl.process.prerun);
            }
            $ctrl.isProcessHavingDocumentType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.DOCUMENT;
            $ctrl.isProcessHavingFormType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.FORM;
            $ctrl.isProcessHavingProcedureType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.PROCEDURE;
            $rootScope.$emit('ON_LOADING_PAGE:HIDE_BUTTON');
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(changes) {
            if (changes.process) {
              if ($ctrl.showButton) {
                $ctrl.showButton === 'launch' ? $ctrl.launchButton = true : $ctrl.shareButton = true;
              }
              $ctrl.viewerId = _.get($rootScope.identity, 'id');
              $ctrl.process.processStepMeta = ProcessService.processStepMeta();
              setTimingsAsStr();
              $ctrl.froalaTextShortenConfig = ProcessService.froalaTextShortenConfig(200);
              mergeCaptures();
              $ctrl.isProcessHavingDocumentType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.DOCUMENT;
              $ctrl.isProcessHavingFormType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.FORM;
              $ctrl.isProcessHavingProcedureType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.PROCEDURE;
              setStepsRuleTerms();
            }
            if ((changes.stages && !changes.stages.isFirstChange()) || (changes.stageSteps && !changes.stageSteps.isFirstChange()) || (changes.guestDocTasks && changes.guestDocTasks.currentValue)) {
              var steps = [];
              if ($ctrl.guestDocTasks && ($ctrl.isGuest || $ctrl.isPublicProcess)) {
                steps = $ctrl.guestDocTasks;
              } else {
                steps = angular.copy($ctrl.run ? ($ctrl.showHiddenTasks ? getRunTasks($ctrl.runTasks) : $ctrl.run.tasks.data) : $ctrl.process.steps.data);
              }
              $ctrl.stageStepsInOrder = mapStageStepOrder(steps);
              setStepsForBlueprint(steps);
              setTimingsAsStr();
            }
          }

          function mapStageStepOrder(steps) {
            var stages = _.orderBy($ctrl.stages || [], 'position', 'asc'), stepData = steps || [], result = [];
            for (var i = 0; i < stages.length; i++) {
              var processInStage = _.filter(stepData, function (step) {
                return step.stage_id == stages[i].id;
              });
              result = _.concat(result, _.orderBy(processInStage, 'position'));
            }
            var unMappedSteps = _.filter(stepData, function (step) {
              return !step.stage_id;
            })
            return _.concat(result, _.orderBy(unMappedSteps, 'position'));
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            folderSelectedWatcher();
            koFieldClickHandler();
            fieldEditorSelectedWatcher();
            blueprintToggleViewWatcher();
            startIndexChangedWatcher();
          }

          function setStepsForBlueprint(steps) {
            prepareStepsForBlueprint(steps);
          }

          function prepareStepsForBlueprint(steps) {
            _.forEach(steps, function (step) {
              var metadata = getStepMetadata(step);
              var stepType = _.find($ctrl.stepTypes, { value: step.step_type || step.task_type });
              if (stepType) {
                metadata.stepType = stepType;
              }
              angular.extend(step, { metadata: metadata });
              step.automation = {
                affecting: getAffectingAutomations(step)
              };
              if (step.automation.affecting.length) {
                var stepTerms = _.filter(step.automation.affectings, function (automation) {
                  return _.find(automation.then_actions, function (action) {
                    return !action.actionable_type;
                  });
                }),
                  captureTerms = _.filter(step.automation.affecting, function (automation) {
                    return _.find(automation.then_actions, function (action) {
                      return action.actionable_type === 'Capture';
                    });
                  }),
                  prerunTerms = _.filter(step.automation.affecting, function (automation) {
                    return _.find(automation.then_actions, function (action) {
                      return action.actionable_type === 'Prerun';
                    });
                  });
                if (captureTerms.length) {
                  _.forEach(captureTerms, function (term) {
                    var captureStep = _.find($ctrl.process.steps.data, function (step) {
                      if (step.captures.length && _.some(step.captures, function (capture) { return capture.id === term.subject_id; })) {
                        return step;
                      }
                    });
                    if (captureStep) {
                      term.stepId = captureStep.id;
                      term.captureObj = _.find(captureStep.captures, function (capture) { return capture.id === term.subject_id; });
                    }
                  })
                }
                if (prerunTerms.length) {
                  _.forEach(prerunTerms, function (term) {
                    var prerunObj = _.find($ctrl.process.prerun, { id: term.subject_id });
                    if (prerunObj) {
                      angular.extend(term, { prerunObj: prerunObj });
                    }
                  });
                }
                $ctrl.minified[step.id] = _.get($stateParams, 'print') ? false : true;
                prepareOtherLogic(step);
                var embeddedBP = isEmbeddedBlueprint(step);
                angular.extend(step, { embeddedBP: embeddedBP });
                var combinedTerms = _.concat(prerunTerms, captureTerms, stepTerms);
                angular.extend(step.automation.affecting, { stepTerms: stepTerms, captureTerms: captureTerms, prerunTerms: prerunTerms, combinedTerms: combinedTerms });
              }
            });
            $ctrl.showMeta = true;
            _.set($ctrl.process, 'steps.data', angular.copy(steps));
          }

          function getAffectingAutomations(step) {
            return _.map(CompactStepService.getAffectingAutomations($ctrl.process, step), function (affectingAutomation) {
              return {
                id: affectingAutomation.id,
                automated_alias: affectingAutomation.automated_alias,
                conditions: affectingAutomation.conditions,
                then_actions: _.filter(affectingAutomation.then_actions, function (action) {
                  return action.target_step_id === step.id;
                })
              };
            }) || [];
          }

          //Set step signal
          function getStepMetadata(step) {
            var affectingAutomations = getAffectingAutomations(step);
            if (!affectingAutomations.length) {
              return {
                signal: 'green',
                icon: 'fas fa-check text-success',
                description: $filter('translate')('process.readBlueprint.signalMessages.go')
              };
            }
            if (affectingAutomations.length) {
              var visibilityActions = _.find(affectingAutomations, function (automation) {
                return _.find(automation.then_actions, { action_type: 'visibility' });
              });
              return !visibilityActions
                ? {
                  signal: 'red',
                  icon: 'fas fa-exclamation-triangle text-danger',
                  description: $filter('translate')('process.readBlueprint.signalMessages.check')
                } :
                {
                  signal: 'orange',
                  icon: 'fas fa-hourglass text-orange',
                  description: $filter('translate')('process.readBlueprint.signalMessages.wait')
                };
            }
          }

          function prepareInsertVariables(preruns) {
            if (Helper.isObjectEmpty($ctrl.run)) {
              return;
            }
            var request = !$ctrl.isGuest
              ? ($ctrl.isPublicProcess ? CompactTaskService.getPublicProcessFormFields({ runId: $ctrl.run.id }) : RunsService.getRunFormFields({ id: $ctrl.run.id }))
              : PublicOrganizationService.getGuestRunFormFields($ctrl.run.id);
            request.then(function (res) {
              if (preruns.length) {
                $ctrl.insertVariablesForm.push({
                  name: $filter('translate')('steps.logic.label.pre_run'),
                  type: 'Prerun',
                  capture: truncateFields(preruns)
                });
              }
              var allAvailableStepCaptures = res.data.form_fields || [];
              if (allAvailableStepCaptures.length) {
                var mappedFormFields = {};
                for (var i = 0; i < allAvailableStepCaptures.length; i++) {
                  if (!mappedFormFields[allAvailableStepCaptures[i].step_id]) {
                    mappedFormFields[allAvailableStepCaptures[i].step_id] = {
                      id: allAvailableStepCaptures[i].step_id,
                      title: allAvailableStepCaptures[i].task_title
                    };
                  }
                  if (Helper.isObjectEmpty(mappedFormFields[allAvailableStepCaptures[i].step_id].captures)) {
                    mappedFormFields[allAvailableStepCaptures[i].step_id].captures = [];
                  }
                  mappedFormFields[allAvailableStepCaptures[i].step_id].captures.push(allAvailableStepCaptures[i]);
                }
                var index = 0;
                _.map(mappedFormFields, function (mappedFormField) {
                  if (mappedFormField.captures.length > 0) {
                    $ctrl.insertVariablesForm.push({
                      id: mappedFormField.id,
                      name: $filter('translate')('steps.describes.insertVariable.step', { stepNumber: ++index, stepName: truncateName(mappedFormField.title, 30) }),
                      type: 'Step',
                      capture: truncateFields(mappedFormField.captures)
                    });
                  }
                });
              }
            });
          }

          function truncateName(name, length) {
            if (_.size(name) > length) {
              return name.substring(0, length) + '...';
            }
            return name;
          }

          function truncateFields(fields) {
            if (!_.isArray(fields) || _.isEmpty(fields)) {
              return [];
            }
            var copyFields = angular.copy(fields);
            copyFields = _.filter(copyFields, function (cpField) {
              return !cpField.isNew;
            });
            return _.map(copyFields, function (field) {
              return angular.extend(field, {
                label: _.size(field.label) > 25 ? field.label.substring(0, 25) + '...' : field.label,
                type_field: $filter('translate')('steps.describes.insertVariable.' + field.field_type) + " " + field.alias.split('-').pop()
              });
            });
          }

          /**
           * @ngdoc method
           * @name mergeCaptures
           * @description Get capture from the step and prerun object and append to new array
           */
          function mergeCaptures() {
            allFormFields = [];
            _.forEach(_.get($ctrl.process, 'steps.data', []), function (step) {
              step = prepareStepOwner(step);
              if (!$ctrl.activeProcess && step.captures.length) {
                _.forEach(step.captures, function (capture) {
                  allFormFields.push(capture);
                });
              }
              step.isPrintSelected = true;
            });
            _.forEach($ctrl.process.prerun, function (prerun) {
              allFormFields.push(prerun);
            });
          }

          /**
           * @ngdoc method
           * @name stepOwnersAsStr
           * @param {Object} step
           * @returns {String} owners
           */
          function stepOwnersAsStr(step) {
            $ctrl.stepsOwners = StepService.stepOwnersAsStr(step);
            return _.join(_.map($ctrl.stepsOwners, 'text'), ', ');
          }

          /**
           * @ngdoc method
           * @name defaultAvatar
           * @public
           * @description set default avatar
           * @param {string} avatar
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText
           * @public
           * @description set default avatar Text
           * @param {string} firstname
           * @param {string} lastname
           * @return {string} first character of name
           */
          function defaultAvatarText(firstname, lastname) {
            return TasksService.setDefaultAvatarText(firstname, lastname);
          }

          /**
           * @function
           * @name setTimingsAsStr
           * @description Set the absolute timings for steps
           */
          function setTimingsAsStr() {
            var stepList = angular.copy(_.get($ctrl.process, 'steps.data', [])), stepsData = [];
            if ($ctrl.isGuest || $ctrl.isPublicProcess) {
              stepsData = $ctrl.guestDocTasks;
            } else {
              stepsData = $ctrl.activeProcess ? $ctrl.tasks : stepList;
            }
            _.forEach(stepsData, function (step_item, i) {
              if ($ctrl.activeProcess) {
                var step = _.find(stepList, { id: step_item.step_id });
                _.set(step_item, 'is_soft_start_date', _.get(step, 'is_soft_start_date'));
                step_item.startedAsString = $filter('tallyfyTimeAgo')($filter('convertToAbsoluteLocalTZ')(step_item.started_at));
                step_item.deadlineAsString = $filter('tallyfyTimeAgo')($filter('convertToAbsoluteLocalTZ')(step_item.deadline));
              } else {
                step_item.startedAsString = StepService.getStepStartAsStr(stepsData, step_item);
                step_item.deadlineAsString = $filter('translate')('runs.process.processStatus.due') + StepService.getStepDeadlineAsStr(stepsData, step_item);
              }
              if (i === (stepsData.length - 1)) {
                _.set($ctrl.process, 'steps.data', stepsData || []);
              }
            });
          }

          /**
           * @ngdoc method
           * @name isHiddenStep
           * @param {object} step
           * @returns {Boolean}
           */
          function isHiddenStep(step) {
            var flag = _.get(step, 'condition.flag');
            return flag && _.toLower(flag) === 'show';
          }

          /**
           * @ngdoc method
           * @name getStepSummary
           * @param {object} stepSummary
           * @returns {String} summary
           */
          function getStepSummary(stepSummary) {
            updatedSummary = angular.copy(stepSummary);
            var capturesAlias = updatedSummary.match(CONST.INSERT_VARIABLE_REGEX),
              doubleBracesAlias = [],
              singleBracesAlias = [];
            _.forEach(angular.copy(capturesAlias), function (value, key) {
              if (value.match(CONST.DOUBLE_CURLY_REGEX)) {
                doubleBracesAlias.push(value);
                capturesAlias.splice(key, 1);
              } else {
                singleBracesAlias.push(value);
              }
            });
            updateAliasValue(allFormFields, _.concat(doubleBracesAlias, singleBracesAlias));
            return updatedSummary;
          }

          /**
           * @ngdoc method
           * @name updateAliasValue
           * @param {*} steps
           * @param {*} insertedVariable
           */
          function updateAliasValue(steps, insertedVariable) {
            _.forEach(angular.copy(steps), function (capture) {
              _.forEach(insertedVariable, function (alias) {
                if (capture.alias === alias.replace(CONST.ALIAS_REGEX, '')) {
                  updatedSummary = _.replace(updatedSummary, new RegExp(alias, 'g'), '<b>[' + capture.label + ']</b>');
                }
              });
            });
          }

          /**
          * @ngdoc method
          * @name prepareStepOwner
          * @description Restructure the step owner obejct
          * @param {Object} step
          * @returns {Object} step
          */
          function prepareStepOwner(step) {
            return StepService.prepareStepAssignees(step, $ctrl.availableUsers, $ctrl.orgGroups);
          }

          function setStepsRuleTerms() {
            var data = $ctrl.activeProcess ?
              _.get($ctrl.run, 'checklist.data.steps.data', [])
              : _.get($ctrl.process, 'steps.data', []);
            var stepsRuleTerms = _.reduce(
              data,
              function (acc, step) {
                acc[step.id] = CompactStepService.getAffectingAutomations($ctrl.activeProcess ? $ctrl.run.checklist.data : $ctrl.process, step);
                return acc;
              }, {});
            $ctrl.stepsRuleTerms = stepsRuleTerms;
          }

          /**
           * @name shareBPLink
           *
           * @description
           * Share BP link
           */
          function shareBPLink() {
            $uibModal.open({
              component: 'shareLink',
              windowClass: 'modal-fade fade share-link-modal',
              resolve: {
                link: function () {
                  return $location.absUrl();
                },
                titleTranslation: function () {
                  return 'process.label.share_link_title';
                }
              }
            });
          }
          function startRun() {
            $ctrl.launchProcess();
          }

          function onShareBlueprintClick($event) {
            Helper.copyToClipboard(window.location.href, 'publicLibraryLinkCopied', $event);
          }

          /**
           * @ngdoc method
           * @name checkKOFormsField
           * @description Check Kick off form field available
           * @returns {Boolean}
           */
          function checkKOFormField() {
            if (_.get($ctrl.process, 'prerun', []).length > 0) {
              return !_.filter(_.get($ctrl.process, 'prerun', []), function (item) {
                return !_.has(item, 'isNew');
              }).length;
            } else {
              return !_.get($ctrl.process, 'prerun', []).length;
            }
          }

          /**
           * @ngdoc method
           * @name customizeBlueprint
           * @description Public Blueprint launch in current organization
           */
          function customizeBlueprint() {
            var organizationId = _.get($rootScope.identity, 'default_organization.id');
            blockUI.start();
            $ctrl.onCustomizeBlueprintSaving = true;
            ProcessRepository.customizeTemplate({
              org: organizationId,
              title: "",
              id: _.get($ctrl.process, 'id'),
              tenant: _.get($ctrl.process, 'organization_id')
            }).then(function (res) {
              if (res) {
                window.open($state.href('process.edit', {
                  org_id: organizationId,
                  slug: res.data.id,
                  view: 'edit',
                  importPublicBlueprint: true
                }, { absolute: true }), '_self');
              }
            }, function () {
              $ctrl.onCustomizeBlueprintSaving = false;
              blockUI.stop();
            });
          }

          function getGuestCompleteProgress(task) {
            if (task.everyone_must_complete) {
              var owners = _.get(task, 'owners', {});
              iHaveCompleted(task);
              return _.difference(owners.guests, waitingCompleter.guests);
            }
          }

          function getMemberCompleteProgress(task) {
            if (task.everyone_must_complete) {
              var owners = _.get(task, 'owners', {});
              var waitingCompleter = _.get(task, 'completion_progress.waiting', {});
              iHaveCompleted(task);
              var mappedIds = owners.users;
              var completerIds = _.difference(mappedIds, waitingCompleter.users);
              return _.filter(owners.users, function (user) {
                return _.indexOf(completerIds, user.id || user) !== -1;
              });
            }
          }

          /**
           * @ngdoc method
           * @name setBlueprintStatus
           * @description Set or Update Blueprint status
           */
          function setBlueprintStatus() {
            StepService.updateBlueprintStatus({
              checklist_id: _.get($ctrl.process, 'id'),
              status: _.get($ctrl.selectedStatus, 'value')
            });
          }

          function initTableConfig(field) {
            if (!$ctrl.tableOptions) {
              $ctrl.tableOptions = {};
            }
            $ctrl.tableOptions[field.id] = getTableConfig(field);
          }

          function getTableConfig(field) {
            var columnDefs = [];
            for (var i = 0; i < field.columns.length; i++) {
              var guid = Helper.getRandomString(8);
              field.columns[i]._guid = guid;
              columnDefs.push({
                field: guid,
                exportKeyMap: {
                  key: guid,
                  label: field.columns[i].label
                },
                headerTemplate: kendo.template(KendoUIService.getTooltippedHeaderLabel(field.columns[i])),
                title: field.columns[i].label
              });
            }
            return {
              beautyScrollStyle: true,
              gridConfig: ReadBlueprintTableService.getOptions(field.id, columnDefs),
              infiniteScrollDataLoad: {},
              tableState: {
                sort: {},
                columns: {}
              },
              templateScope: {
                callbacks: {},
                variables: {}
              }
            }
          }

          /**
           * @ngdoc method
           * @name onFavoriteClick
           * @param {*} blueprint
           *
           * @description
           * set Favorite in blueprint
           */
          function onFavoriteClick(blueprint) {
            blueprint.starred = !blueprint.starred;
            Growl.clearAllMessages('global');
            $rootScope.$emit('BLUEPRINT:UPDATED_FAVORITE_BLUEPRINT', { blueprint_id: blueprint.id });
          }

          /**
          * @ngdoc method
          * @name onPlayClick
          * @description Play Blueprint Explanation Video
          * @returns {object} video
          */
          function onPlayClick(data) {
            $uibModal.open({
              component: 'explanationVideoModal',
              windowClass: 'explanation-video-modal',
              resolve: {
                data: function () {
                  return data;
                }
              }
            });
          }

          function resetSelectedBP() {
            $state.transitionTo($state.current, angular.extend($stateParams, {
              blueprint_id: null
            }), {
              notify: false
            });
            body.removeClass('library-view-modal');
            $ctrl.selectedBlueprint = null;
          }

          function onRemoveFromFolder(process) {
            if (!$ctrl.haveAuthority())
              return;
            blockUI.start();
            process.folder_id = null;
            if (_.get($ctrl.selectedBlueprint, 'id') === process.id) {
              $ctrl.resetSelectedBP();
            }
            ProcessService.update({
              id: process.id
            }, process).then(function (res) {
              ProcessService.setPinned({
                id: _.get(res, "data.id"),
                is_pinned: false
              }).then(function () { }, function () { });
              blockUI.stop();
              postRemoveFromFolder(res);
            });
          }

          function postRemoveFromFolder(res) {
            $rootScope.$emit('BLUEPRINT:REMOVE_BLUEPRINT', { blueprint_id: res.data.id });
            resetSelectedBP();
            growl.success($filter('translate')('runs.messages.folder.removedFromFolder', {
              bpName: res.data.title
            }), {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            });
          }

          /**
           * @ngdoc method
           * @name onNewFolderForBlueprint
           * @param {*} process
           *
           * @description
           * on new folder for blueprint added / directly added blueprint into a folder after created
           */
          function onNewFolderForBlueprint(process) {
            if (!$ctrl.haveAuthority()) {
              return;
            }
            var createModal = FolderService.openFolderModal(null, null, 'checklist');
            createModal.result.then(function (response) {
              var newFolder = response.data;
              process.folder_id = newFolder.id;
              ProcessService.update({
                id: process.id
              }, process).then(function (res) {
                newFolder.total = 0;
                if (!$ctrl.folderID) {
                  $ctrl.folders.splice(0, 0, newFolder);
                  $ctrl.foldersAsMenu.splice(0, 0, newFolder);
                }
                var folderExist = _.find($ctrl.folderItemsToDisplay, { type: 'folder' });
                if ((folderExist || $ctrl.pager.current_page === 1) && !$ctrl.folderID) {
                  $ctrl.folderItemsToDisplay.splice(0, 0, {
                    id: newFolder.id,
                    type: 'folder',
                    data: newFolder
                  });
                }
                var folderIndex = _.findIndex($ctrl.folders, { id: newFolder.id });
                if (folderIndex >= 0) {
                  $ctrl.folderID ? $ctrl.folders[folderIndex].total-- : $ctrl.folders[folderIndex].total++;
                }
                if ($ctrl.folderID) {
                  growl.success($filter('translate')('runs.messages.folder.movedToFolder', {
                    bpName: res.data.title
                  }), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                } else {
                  growl.success($filter('translate')('runs.messages.folder.addedToFolder', {
                    bpName: res.data.title
                  }), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                }
              });
            });
          }

          /**
           * @ngdoc method
           * @name onNewFolderClick
           * @param {integer} folderId
           * @description
           * click handler on new folder click
           */
          function onNewFolderClick(folderId) {
            if (!$ctrl.haveAuthority())
              return;
            var createModal = FolderService.openFolderModal(null, folderId || 'root', 'checklist');
            createModal.result.then(onNewFolderAdded);
          }

          /**
           * @ngdoc method
           * @name onNewFolderAdded
           * @param {*} response
           *
           * @description
           * callback handler after folder created on modal popup
           */
          function onNewFolderAdded(response) {
            getFolderList();
            $rootScope.$emit('FOLDER:ADD_NEW', { folder: response.data });
          }

          /**
           * @ngdoc method
           * @name onMoveToFolder
           * @param {*} process
           * @param {*} folder
           *
           * @description
           * move blueprint to a folder
           */
          function onMoveToFolder(process, folder) {
            if (!$ctrl.haveAuthority())
              return;
            if (_.get(folder, 'id') !== _.get(process, 'folder.data.id')) {
              if (_.get($ctrl.selectedBlueprint, 'id') === process.id) {
                $ctrl.resetSelectedBP();
              }
              blockUI.start();
              process.folder_id = folder.id;
              ProcessService.update({
                id: process.id,
                skipNotFound: true
              }, process).then(function (res) {
                blockUI.stop();
                postMoveToFolder(res);
                $rootScope.$emit('BLUEPRINT:REMOVE_BLUEPRINT', { blueprint_id: process.id });
                resetSelectedBP();
              }, function (error) {
                blockUI.stop();
                if (error.status === 404) {
                  growl.error($filter('translate')('steps.messages.bluePrintNotFound'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                  $state.go('process.templates', {
                    org_id: _.get($rootScope.identity, 'default_organization.id')
                  });
                }
              });
            } else {
              Growl.clearAllMessages('global');
              $timeout(function () {
                growl.warning($filter('translate')('runs.messages.folder.alreadyOnFolder', {
                  bpName: _.get(process, 'title'),
                  folderName: _.get(folder, 'name')
                }), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, 0);
            }
          }

          /**
           * @ngdoc method
           * @name postMoveToFolder
           * @param {*} res
           *
           * @description
           * callback after blueprint moved to folder
           */
          function postMoveToFolder(res) {
            var folderIndex = _.findIndex($ctrl.folders, { id: res.data.folder_id });
            folderIndex >= 0 ? $ctrl.folders[folderIndex].total++
              : angular.noop();
            if ($ctrl.folderID) {
              growl.success($filter('translate')('runs.messages.folder.movedToFolder', {
                bpName: res.data.title
              }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            } else {
              growl.success($filter('translate')('runs.messages.folder.addedToFolder', {
                bpName: res.data.title
              }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            }
          }

          /**
           * @function
           * @name prepareOtherLogic
           * @description Prepare other step logic
           */
          function prepareOtherLogic(currentStep) {
            var steps = _.get($ctrl.process, 'steps.data', []);
            currentStep.otherLogic = [];
            _.forEach(steps, function (step) {
              var rules = _.get(step, 'condition.terms') || [];
              stepLogic = [];
              if (rules.length) {
                _.forEach(angular.copy(rules), function (rule) {
                  if (rule.subject_type === 'Step') {
                    updateStepLogic(rule, currentStep);
                  } else if (rule.subject_type === 'Capture') {
                    var captures = _.get(currentStep, 'captures') || [];
                    _.forEach(captures, function (item) {
                      updateStepLogic(rule, item);
                    });
                  }
                });
                if (stepLogic.length) {
                  currentStep.otherLogic.push({
                    otherStepPosition: step.position,
                    OtherStepTitle: step.title,
                    currentStepPosition: currentStep.position,
                    currentStepTitle: currentStep.title,
                    stepLogic: stepLogic,
                    flag: step.condition.flag
                  });
                }
              }
            });
          }

          /**
           * @function
           * @name updateStepLogic
           * @description update step logic
           */
          function updateStepLogic(rule, step) {
            if (rule.subject_id === step.id) {
              if (step.field_type === 'table' && rule.operation === 'contains') {
                rule.operation = rule.column_contains_name ? 'contains' : 'table_contains';
              }
              stepLogic.push({
                subjectType: rule.subject_type,
                subjectId: rule.subject_type === 'Capture' ? step.id : null,
                logic: rule.subject_type === 'Step' ? $filter('translate')('steps.logic.otherStepsLogic.step.' + rule.statement, {
                  operation: rule.operation,
                  column: rule.column_contains_name ? _.find(step.columns, { id: parseInt(rule.column_contains_name) }).label : void 0
                }) : $filter('translate')('steps.logic.otherStepsLogic.stepFormFields.' + rule.operation, {
                  fieldName: step.label,
                  statement: rule.statement,
                  column: rule.column_contains_name ? _.find(step.columns, { id: parseInt(rule.column_contains_name) }).label : void 0
                })
              });
            }
          }

          //Toggle comments modal
          function toggleCommentsModal(step) {
            $uibModal.open({
              component: 'editComment',
              windowClass: 'modal-fade fade edit-comment-modal',
              resolve: {
                step: function () {
                  return step;
                },
                tasks: function () {
                  return $ctrl.tasks;
                },
                usersInOrg: function () {
                  return $ctrl.usersInOrg;
                },
                viewerId: function () {
                  return $ctrl.viewerId;
                },
                orgGroups: function () {
                  return $ctrl.orgGroups;
                },
                isGuest: function () {
                  return $ctrl.isGuest;
                }
              }
            })
          }

          //Go to step
          function goToStep(step, isPosition, isPrerun) {
            if (isPrerun) {
              var element = document.getElementById("prerun-" + step);
              element.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
              return;
            }
            if (isPosition) {
              step = _.find($ctrl.process.steps.data, { position: step }).id;
            }
            var element = document.getElementById("read-bp-step-" + step);
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          }

          function onCaptureUpdated(field, isKoField, task) {
            if (isKoField) {
              $ctrl.run.prerun[field.id] = FieldService.getFieldValue(field);
              var isHasEmptyRequiredFields = CompactTaskService.validateCaptures([field]), isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded([field]);
              if (isHasExceededTextChars || isHasEmptyRequiredFields) {
                $rootScope.$emit('COMPACT:FORM_VALIDATION_ERROR');
              } else {
                RunsService.update({ id: $ctrl.run.id }, { prerun: mapProcessPrerunValue() })
                  .then(function (res) {
                    angular.extend($ctrl.run, { prerun: res.data.prerun });
                    $rootScope.$broadcast('CAPTURE:UPDATE', { updatedTask: 'ko-form', updatedCapture: field });
                  });
              }
            } else {
              var isArchivedStatus = _.get(task, 'run.data.status') === 'archived';
              if (isArchivedStatus) {
                return;
              }
              task.taskdata[field.id] = FieldService.getFieldValue(field);
              var isHasEmptyRequiredFields = CompactTaskService.validateCaptures([field]), isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded([field]);
              if ((isHasExceededTextChars || isHasEmptyRequiredFields) && !(task.status === 'completed' || iHaveCompleted(task))) {
                $rootScope.$emit('COMPACT:FORM_VALIDATION_ERROR');
                return;
              }
              if (CompactTaskService.isCaptureUpdateInProgress) {
                CompactTaskService.isCaptureUpdateInQueue = true;
                $timeout(function () {
                  onCaptureUpdated(field, isKoField, task);
                  CompactTaskService.isCaptureUpdateInQueue = false;
                }, 100);
              } else {
                CompactTaskService.isCaptureUpdateInProgress = true;
                var OOTTaskPayload;
                if (task.is_oneoff_task && !task.run_id) {
                  OOTTaskPayload = angular.extend(_.omit(task, ['started_at']), {
                    name: task.name,
                    deadline: DateUtils.toUTC(moment(task.deadline_unformatted)).format(DATEFORMAT.DEFAULT),
                    taskdata: task.taskdata,
                    owners: task.owners
                  });
                }
                var taskPayload = { taskdata: task.taskdata },
                  resource = $ctrl.isGuest || ($ctrl.isPublicProcess && isGuestAssigned(task))
                    ? CompactTaskService.updateGuestTask(task, taskPayload)
                    : task.is_oneoff_task && !task.run_id
                      ? CompactTaskService.updateStandaloneTask(task, OOTTaskPayload)
                      : CompactTaskService.updateTask(task, taskPayload);
                resource.then(function (response) {
                  taskUpdateSuccess(response, field, task);
                }, taskUpdateError);
              }
            }
          }

          function taskUpdateError(err) {
            if (err.status === 422) {
              $ctrl.errorFields = _.get(err, 'data.data.errors');
            }
            CompactTaskService.isCaptureUpdateInProgress = false;
          }

          function isGuestAssigned(task) {
            var guestEmail = _.get($localStorage.guest, 'email', '');
            return _.indexOf(task.owners.guests, guestEmail) >= 0;
          }

          function taskUpdateSuccess(response, field, task) {
            if (!$ctrl.isGuest && !isGuestAssigned(task)) {
              angular.extend(task, response.data);
            }
            if ($ctrl.run) {
              if ($ctrl.isGuest && isKoTaskAssignee(task)) {
                var tempEl = angular.element('<div>').html(task.summary),
                  koAssigneeElements = tempEl[0].getElementsByTagName('ko-field-assignee');
                if (koAssigneeElements && koAssigneeElements.length && field) {
                  var koField = _.find(_.get($ctrl.run, 'checklist.data.prerun', []), { alias: koAssigneeElements[0].dataset.koFieldAlias.replace(/'/g, '') });
                  angular.extend(koField, { value: field.value });
                  updatePrerunField(koField, task);
                }
              }
              $rootScope.$broadcast('CAPTURE:UPDATE', { updatedTask: task, updatedCapture: field, isKoTaskAssignee: isKoTaskAssignee(task), doNotExtend: true });
            } else {
              var tempEl = angular.element('<div>').html(task.summary), koAssigneeElements = tempEl[0].getElementsByTagName('ko-field-assignee');
              if (koAssigneeElements && koAssigneeElements.length && field) {
                var koField = _.find(
                  $ctrl.isGuest ? _.get(formFieldData, 'ko_form_fields', []) : _.get(formFieldData, 'ko_form_fields.data', []),
                  { alias: koAssigneeElements[0].dataset.koFieldAlias.replace(/'/g, '') }
                );
                angular.extend(koField, { value: field.value });
                updatePrerunField(koField, task);
              }
            }
            CompactTaskService.isCaptureUpdateInProgress = false;
            if (isKoTaskAssignee(task) && !Helper.isObjectEmpty(FieldService.getFieldValue(field)) && task.status !== 'completed') {
              $ctrl.onCompleteTaskClick(void 0, void 0, task);
            }
          }

          function completeTask(action, task) {
            action === 'reject' ? $ctrl.onRejectSaving[task.id] = true : $ctrl.onSaving[task.id] = true;
            var override_user;
            if (task.everyone_must_complete && isAdminMember && $ctrl.enquireStatus) {
              var waitingUsers = _.get(task, 'completion_progress.waiting.users', []),
                currentUser = _.get($rootScope, 'identity.id');
              override_user = _.includes(waitingUsers, currentUser) ? currentUser
                : _.first(waitingUsers);
            }
            CompactTaskService.taskComplete(task, action, $ctrl.isGuest, true, override_user)
              .then(function (res) {
                $ctrl.completeProgressCaption[task.id] ? getSingleTask(res.task) : angular.extend(task, res.task);
                setCompleteProgress(task);
                var stepCaptures = (_.get(task, 'form_fields.data', []) || []);
                trackEvent('Task completed', {
                  category: $ctrl.isGuest ? 'Task completed by guest' : 'Task completed by member',
                  'form-fields': stepCaptures.length ? 'yes' : 'no'
                }, task);
                action === 'reject' ? $ctrl.onRejectSaving[task.id] = false : $ctrl.onSaving[task.id] = false;
              }, function () {
                action === 'reject' ? $ctrl.onRejectSaving[task.id] = false : $ctrl.onSaving[task.id] = false;
              });
          }

          function trackEvent(eventName, properties, task) {
            properties.type = task.is_oneoff_task ? 'standalone' : 'process';
            properties.processId = $ctrl.run ? $ctrl.run.id : void 0;
            properties.taskId = task.id;
            $analytics.eventTrack(eventName, properties);
          }

          function getStepCaptures(task) {
            var taskCaptures = (_.get(task, 'form_fields.data', []) || []);
            if (taskCaptures.length) {
              _.forEach(taskCaptures, function (capture) {
                capture.value = task.taskdata[capture.id];
              });
            }
            return taskCaptures;
          }

          function onCompleteKoTaskClick() {
            $ctrl.formSubmitted = true;
            var preruns = CompactTaskService.processPreruns($ctrl.run),
              isHasEmptyRequiredFields = CompactTaskService.validateCaptures(preruns),
              isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded(preruns);
            if (isHasExceededTextChars || isHasEmptyRequiredFields) {
              $rootScope.$emit('COMPACT:FORM_VALIDATION_ERROR');
            } else {
              $ctrl.run.prerun_status === 'complete' ? inCompleteKoTask() : completeKoTask();
            }
          }

          function inCompleteKoTask() {
            $ctrl.onKoFormSaving = true;
            CompactTaskService.koTaskReOpen($ctrl.run)
              .then(function (res) {
                angular.extend($ctrl.run, res.data);
                $rootScope.$emit('KICK_OFF_FORM:UPDATED_STATUS');
                trackKoEvent('KO task re-opened', {});
                $ctrl.onKoFormSaving = false;
              }, function () {
                $ctrl.onKoFormSaving = false;
              });
          }

          function trackKoEvent(eventName, properties) {
            properties.type = 'process';
            properties.processId = $ctrl.run ? $ctrl.run.id : void 0;
            $analytics.eventTrack(eventName, properties);
          }

          function completeKoTask() {
            $ctrl.onKoFormSaving = true;
            CompactTaskService.koTaskComplete($ctrl.run)
              .then(function (res) {
                angular.extend($ctrl.run, res.data);
                $rootScope.$emit('KICK_OFF_FORM:UPDATED_STATUS');
                trackKoEvent('KO task completed', {});
                $ctrl.onKoFormSaving = false;
              }, function () {
                $ctrl.onKoFormSaving = false;
              });
          }

          function onCompleteTaskClick($event, action, task) {
            if (CompactTaskService.isCaptureUpdateInProgress || CompactTaskService.isCaptureUpdateInQueue) {
              action === 'reject' ? $ctrl.onRejectSaving = true : $ctrl.onSaving[task.id] = true;
              $timeout(function () {
                onCompleteTaskClick($event, action, task);
              }, 500);
            } else {
              $ctrl.formSubmitted = true;
              var stepCaptures = getStepCaptures(task),
                isHasEmptyRequiredFields = CompactTaskService.validateCaptures(stepCaptures),
                isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded(stepCaptures);
              if ((isHasExceededTextChars || isHasEmptyRequiredFields) && !(task.status === 'completed' || iHaveCompleted(task))) {
                $rootScope.$emit('COMPACT:FORM_VALIDATION_ERROR');
                action === 'reject' ? $ctrl.onRejectSaving = false : $ctrl.onSaving[task.id] = false;
              } else {
                task.status === 'completed' || iHaveCompleted(task) ? inCompleteTask(action, task) : completeTask(action, task);
              }
            }
          }

          function isKoTaskAssignee(task) {
            var tempEl = angular.element('<div>');
            tempEl.html(task.summary);
            var koAssigneeElements = tempEl[0].getElementsByTagName('ko-field-assignee');
            return koAssigneeElements.length;
          }

          function updatePrerunField(field, task) {
            if (!task.run.data.prerun) {
              task.run.data.prerun = {};
            }
            task.run.data.prerun[field.id] = FieldService.getFieldValue(field);
            var request = $ctrl.isGuest
              ? RunsService.updateGuestPrerun({
                runId: task.run.data.id,
                skipAuthToken: true
              }, {
                run_id: task.run.data.id,
                category: "prerun",
                form_id: field.id,
                alias: field.alias,
                field_type: field.field_type,
                form_value: field.value
              })
              : RunsService.update({ id: task.run.data.id }, { prerun: mapProcessPrerunValue() });
            request.then(function (res) {
              angular.extend(task.run.data, { prerun: res.data.prerun });
              $rootScope.$broadcast('CAPTURE:UPDATE', { updatedTask: 'ko-form', updatedCapture: field });
            });
          }

          function mapProcessPrerunValue() {
            var prerun = {};
            for (var key in $ctrl.run.prerun) {
              var data = _.find($ctrl.run.ko_form_fields.data, { id: key }) || {};
              if (data.field_type === 'table') {
                var value = $ctrl.run.prerun[key];
                if (value && value.length) {
                  if (Helper.isObjectEmpty(prerun[key])) {
                    prerun[key] = [];
                  }
                  for (var i = 0; i < value.length; i++) {
                    prerun[key].push(
                      !Helper.isObjectEmpty(value) && !Helper.isObjectEmpty(value[i]) && value[i].id
                        ? value[i]
                        : {
                          id: data.id,
                          label: data.label,
                          value: value[i]
                        }
                    );
                  }
                }
              } else {
                prerun[key] = $ctrl.run.prerun[key];
              }
            }
            return prerun;
          }

          function setCompleteProgress(task) {
            if (task.everyone_must_complete) {
              var owners = _.get(task, 'completion_progress.completed_by', {});
              var waitingCompleter = _.get(task, 'completion_progress.waiting', {});
              $ctrl.completePercent[task.id] = (100 * task.completion_progress.completed) / task.completion_progress.total;
              $ctrl.completeProgressCaption[task.id] = task.completion_progress.completed + '/' + task.completion_progress.total;
              $ctrl.approveProgressCaption[task.id] = task.everyone_must_complete ? task.completion_progress.approved_count + '/' + task.completion_progress.total : angular.noop();
              $ctrl.rejecteProgressCaption[task.id] = task.everyone_must_complete ? task.completion_progress.rejected_count + '/' + task.completion_progress.total : angular.noop();
              $ctrl.guestCompleters[task.id] = _.difference($ctrl.isGuest
                ? ($ctrl.iHaveCompleted(task)
                  ? [$rootScope.identity.guest.email]
                  : []
                ) : owners.guests, waitingCompleter.guests);
              var mappedIds = $ctrl.isGuest
                ? _.map(owners.users, function (user) {
                  return user.id;
                }) : owners.users;
              var completerIds = _.difference(mappedIds, waitingCompleter.users);
              $ctrl.userCompleters = _.filter(owners.users, function (user) {
                return _.indexOf(completerIds, user.id || user) !== -1;
              });
            }
          }

          function inCompleteTask(action, task) {
            action === 'reject' ? $ctrl.onRejectSaving = true : $ctrl.onSaving[task.id] = true;
            CompactTaskService.taskReopen(task).then(function (res) {
              angular.extend(task, res.task);
              setCompleteProgress(task);
              trackEvent('Task re-opened', {
                'form-fields': _.get(res.task, 'form_fields', []).length ? 'yes' : 'no'
              }, task);
              action === 'reject' ? $ctrl.onRejectSaving = false : $ctrl.onSaving[task.id] = false;
            }, function () {
              action === 'reject' ? $ctrl.onRejectSaving = false : $ctrl.onSaving[task.id] = false;
            });
          }

          function isTaskCompletable(task) {
            return CompactTaskService.isTaskCompletable(task, $ctrl.orgGroups || []);
          }

          function iHaveCompleted(task) {
            if (task.everyone_must_complete) {
              var owners = _.get(task, 'completion_progress.completed_by', {});
              var waitingCompleter = _.get(task, 'completion_progress.waiting', {});
              $ctrl.completePercent[task.id] = (100 * task.completion_progress.completed) / task.completion_progress.total;
              if ($rootScope.identity.guest) {
                return !_.includes(waitingCompleter.guests, $rootScope.identity.guest.email);
              } else {
                return _.includes(owners.users, $rootScope.identity.id) ? !_.includes(waitingCompleter.users, $rootScope.identity.id) : false;
              }
            }
          }

          function getButtonClass(task) {
            var buttonClass = 'btn btn-compact btn-compact-with-fa-icon text-uppercase v-c';
            buttonClass += task.everyone_must_complete
              ? (
                task.status !== 'completed'
                  ? (
                    $ctrl.iHaveCompleted(task)
                      ? ' i-have-completed' + (
                        $ctrl.isHover[task.id]
                          ? ' on-hover btn-dark'
                          : ''
                      )
                      : ''
                  )
                  : ' btn-dark')
              : (
                task.status !== 'completed'
                  ? ' nasty-green'
                  : ' btn-dark'
              );
            return buttonClass;
          }

          /**
           * @ngdoc method
           * @name getButtonText
           *
           * @description
           * get complete button text
           */
          function getButtonText(task) {
            var isUserOnlyAssignee = checkIfUserIsOnlyAssignee(task);
            var buttonText =
              task.task_type === 'email'
                ? (task.status !== 'completed' ? (isUserOnlyAssignee ? 'complete' : 'send') : 'reOpen')
                : (task.everyone_must_complete && $ctrl.isTaskCompletable(task)
                  ? (
                    task.status !== 'completed'
                      ? (
                        $ctrl.iHaveCompleted(task)
                          ? (
                            $ctrl.isHover[task.id]
                              ? 'reOpen'
                              : 'completePercent'
                          )
                          : 'completePercent'
                      )
                      : 'reOpen'
                  )
                  : (
                    task.status !== 'completed'
                      ? 'complete'
                      : 'reOpen'
                  )
                );
            return $filter('translate')('compact.task.detail.form.button.' + buttonText, {
              percent: $ctrl.completeProgressCaption[task.id]
            });
          }

          /**
           * @ngdoc method
           * @name getButtonIconClass
           *
           * @description
           * get complete button icon class
           */
          function getButtonIconClass(task) {
            return $ctrl.isHasIssue(task)
              ? 'pos-rlt fas fa-exclamation-triangle'
              : task.task_type === 'email'
                ? 'fas fa-envelope'
                : (task.everyone_must_complete
                  ? (
                    task.status !== 'completed'
                      ? (
                        $ctrl.iHaveCompleted(task)
                          ? (
                            $ctrl.isHover[task.id]
                              ? 'fas fa-check-circle'
                              : ''
                          )
                          : ''
                      )
                      : 'fas fa-check-circle'
                  )
                  : (
                    task.status !== 'completed'
                      ? (
                        $ctrl.isTaskCompletable(task)
                          ? 'far fa-circle'
                          : ''
                      )
                      : 'fas fa-check-circle'
                  ));
          }

          /**
           * @ngdoc method
           * @name getApprovalButtonIconClass
           *
           * @description
           * get approval button icon class
           * @param {String} buttonType
           */
          function getApprovalButtonIconClass(buttonType) {
            var buttonIcon;
            if (buttonType === 'approve') {
              buttonIcon = 'far fa-circle';
            } else {
              buttonIcon = 'fas fa-times';
            }
            return buttonIcon;
          }

          /**
           * @ngdoc method
           * @name getApprovalButtonText
           *
           * @description
           * get approval button text
           * @param {String} buttonType
           */
          function getApprovalButtonText(buttonType, task) {
            $ctrl.completerAssignId = _.includes($ctrl.userCompleters, $rootScope.identity.id);
            var buttonText;
            if (buttonType === 'approve') {
              if ($ctrl.completerAssignId || ($ctrl.iHaveCompleted(task) && $ctrl.isGuest)) {
                buttonText = 'reOpen';
              } else {
                buttonText = task.status === 'completed' ? $ctrl.isApproveButtonHover ? 'reOpen' : 'approved' : 'approvePercent';
              }
            } else {
              if ($ctrl.completerAssignId || ($ctrl.iHaveCompleted(task) && $ctrl.isGuest)) {
                buttonText = 'reOpen';
              } else {
                buttonText = task.status === 'completed' ? $ctrl.isRejectButtonHover ? 'reOpen' : 'rejected' : 'rejectPercent';
              }
            }
            return $filter('translate')('compact.task.detail.form.button.' + buttonText, {
              percent: buttonType === 'approve' ? $ctrl.approveProgressCaption[task.id] : $ctrl.rejecteProgressCaption[task.id]
            });
          }

          /**
           * @ngdoc method
           * @name getApprovalButtonClass
           *
           * @description
           * get approval button class
           * @param {String} buttonType
           */
          function getApprovalButtonClass(buttonType, task) {
            var buttonClass = 'btn btn-compact btn-compact-with-fa-icon text-uppercase v-c';
            if (buttonType === 'approve') {
              buttonClass = buttonClass + (task.status === 'completed' ? ' btn-dark ' : ' nasty-green');
            } else {
              buttonClass = buttonClass + (task.status === 'completed' ? ' btn-dark ' : ' red-tomato');
            }
            return buttonClass;
          }

          /**
           * @ngdoc method
           * @name getExpiringButtonText
           *
           * @description
           * get expiring type button text
           */
          function getExpiringButtonText(task) {
            $ctrl.buttonType = (task.status !== 'completed') ? task.task_type === 'expiring_email' ? 'sendRightNow' : 'acknowledge'
              : (task.status_label === 'acknowledged') ? 'unacknowledge'
                : task.status_label;
            return $filter('translate')('compact.task.detail.form.button.' + $ctrl.buttonType);
          }

          //Get step position from id
          function getStepPosition(stepId) {
            return _.find($ctrl.process.steps.data, { id: stepId }).position;
          }

          //Get embedded blueprint data
          function isEmbeddedBlueprint(step) {
            if (step.summary) {
              var summary = angular.copy(step.summary), attributes = summary.match(CONST.HTML_ATTRIBUTE_REGEX), content, blueprintId;
              attributes = _.filter(attributes, function (attribute) { return attribute.includes('data-blueprint-id'); });
              if (attributes && attributes.length) {
                blueprintId = attributes[0].split('="')[1].split('\"')[0]; //Extract blueprint id
                content = summary.match(CONST.CONTENT_IN_TAG_REGEX);

                if (content && content.length) {
                  content = content[0].split('>[[')[1].split(']]<')[0];
                }
              }
              var embeddedBP = {
                id: blueprintId,
                title: content
              };
              return blueprintId && content ? embeddedBP : null;
            }
            return null;
          }

          //View embedded blueprint
          function onViewBluePrint(step) {
            var summary = getStepSummary(step.summary);
            $uibModal.open({
              component: 'embeddedBPModal',
              windowClass: 'modal-fade fade embedded-bp',
              backdrop: 'static',
              resolve: {
                froalaContent: function () {
                  return summary;
                },
                step: function () {
                  return step;
                },
                process: function () {
                  return $ctrl.process;
                },
                stepsRuleTerms: function () {
                  return $ctrl.stepsRuleTerms;
                },
                insertVariablesForm: function () {
                  return $ctrl.insertVariablesForm;
                },
                activeProcess: function () {
                  return $ctrl.activeProcess;
                }
              }
            });
          }

          //Get step type rule action statement
          function getStepTypeRuleActions(step) {
            if (step.step_type === 'email' || step.step_type === 'expiring_email') {
              return $filter('translate')('process.readBlueprint.stepTypeRuleActions.email');
            }
            return $filter('translate')('process.readBlueprint.stepTypeRuleActions.' + step.step_type);
          }

          function checkIfUserIsOnlyAssignee(task) {
            if ($ctrl.isGuest || $ctrl.isPublic || $ctrl.isPublicProcess) {
              return false;
            }

            if ((task.owners.users.length > 1 || task.owners.guests.length > 1) || (task.owners.users.length === 1 && task.owners.guests.length === 1)) { // Not checking the groups here as multiple groups can have just a single and the same assignee too.
              return false;
            }

            if ((task.owners.users.length === 1 && task.owners.guests.length === 0 && task.owners.groups.length === 0 && task.owners.users[0] === $rootScope.identity.id) || (task.owners.users.length === 0 && task.owners.guests.length === 1 && task.owners.groups.length === 0 && task.owners.guests[0] === $rootScope.identity.guest.email)) {
              return true;
            }

            if (task.owners.groups.length > 0) {
              var allAssignedArr = _.concat(task.owners.users, task.owners.guests) || [];

              if (allAssignedArr.length > 1) {
                return false;
              }

              var groupsWithUserIds = getTaskGroups(task), isOnlyAssignee = false;

              for (var i = 0; i < groupsWithUserIds.length; i++) {
                var members = groupsWithUserIds[i].members || [], guests = groupsWithUserIds[i].guests || [];
                if (members.length > 1 || guests.length > 1) {
                  isOnlyAssignee = false;
                  break;
                }

                if (members.length) {
                  allAssignedArr = _.uniq(_.concat(allAssignedArr, members[0]));
                }

                if (guests.length) {
                  allAssignedArr = _.uniq(_.concat(allAssignedArr, guests[0]));
                }

                if (allAssignedArr.length > 1) {
                  isOnlyAssignee = false;
                  break;
                }
              }

              if (allAssignedArr.length === 1) {
                isOnlyAssignee = true;
              }

              return isOnlyAssignee;
            }

            return false;
          }

          //Get Task Groups
          function getTaskGroups(task) {
            var taskGroups = [];
            if (_.get($ctrl.orgGroups, 'length', 0)) {
              var groupsAssigned = _.get(task, 'owners.groups') || [];
              _.forEach(groupsAssigned, function (groupItem) {
                var groupBaseObj = _.find($ctrl.orgGroups, { id: groupItem });
                if (groupBaseObj) {
                  taskGroups.push(groupBaseObj);
                }
              });
            }
            return taskGroups;
          }

          function shouldShowInstructions(step) {
            return (
              ($ctrl.affectingAutomations[step.id] && $ctrl.affectingAutomations[step.id].length) || ($ctrl.asAutomationTriggers[step.id] && $ctrl.asAutomationTriggers[step.id].length)) ||
              (step.summary || ((step.captures || step.form_fields.data).length && (!$ctrl.activeProcess || ($ctrl.activeProcess && _.get($ctrl.process, 'processStepMeta.showFormFields')))));
          }

          function getRunTasks() {
            var stepData = [];
            for (var key in $ctrl.runTasks) {
              stepData = _.concat(stepData, $ctrl.runTasks[key]);
            }
            return stepData;
          }

          folderSelectedWatcher = $rootScope.$on('FOLDER:CLICK', function (e, data) {
            $ctrl.onMoveToFolder(data.blueprint, data.folder);
          });

          startIndexChangedWatcher = $rootScope.$on('STAGE:START_INDEX_CHANGED', function (e, data) {
            if ($ctrl.process.id !== data.process.id) {
              return;
            }
            var steps = [];
            if ($ctrl.guestDocTasks && ($ctrl.isGuest || $ctrl.isPublicProcess)) {
              steps = $ctrl.guestDocTasks;
            } else {
              steps = angular.copy($ctrl.run ? ($ctrl.showHiddenTasks ? getRunTasks($ctrl.runTasks) : $ctrl.run.tasks.data) : $ctrl.process.steps.data);
            }
            $ctrl.stageStepsInOrder = mapStageStepOrder(steps);
            setStepsForBlueprint(steps);
            setTimingsAsStr();
          });

          koFieldClickHandler = $rootScope.$on('KO_FIELD_ASSIGNEE:DESCRIPTION_CLICK', function (e, data) {
            var selectedPrerun = _.find($ctrl.process.prerun, { alias: data.alias });
            $rootScope.$emit('EDITOR_FIELD:FOCUS:' + selectedPrerun.id);
          });

          fieldEditorSelectedWatcher = $rootScope.$on('FIELD_EDITOR:SELECTED', function (e, data) {
            $ctrl.selectedField = _.find($ctrl.process.prerun, { id: data.field.id });
            $timeout(function () {
              if ($ctrl.selectedField) {
                var el = $element[0].querySelectorAll('editor-form-field[data-field-id*=\'' + $ctrl.selectedField.id + '\']');
                el[0].scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                });
              }
            }, 350);
          });

          blueprintToggleViewWatcher = $rootScope.$on('BLUEPRINT:TOGGLE_VIEW', function (e, data) {
            if (data === 'readBP') {
              initialization();
            }
          });
        }
    });
})();

