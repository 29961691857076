/**
 * @ngdoc service
 * @module tallyfy
 * 
 * @name FroalaService
 *
 * @description
 * all about froala service
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  angular
    .module('tallyfy')
    .service('FroalaService', FroalaService);

  /*@ngInject*/
  function FroalaService($rootScope, $q, $document, $compile, $filter, $timeout, _, OrganizationsService, Lightbox, DOMService, CONST, uiLoad, Helper, USER_STATE) {
    var globalCss;
    return {
      showImageLightbox: showImageLightbox,
      parseElement: parseElement,
      getRawContent: getRawContent,
      getViewContent: getViewContent,
      getViewContentFromSummary: getViewContentFromSummary,
      cleanHTML: cleanHTML,
      setDOMBreakLine: setDOMBreakLine,
      attachViewFullSize: attachViewFullSize,
      normalizeCodeTag: normalizeCodeTag,
      removeFroalaBanner: removeFroalaBanner,
      parseInsertVariableTag: parseInsertVariableTag,
      wrapFirstParagraphTag: wrapFirstParagraphTag,
      parseBlueprintTag: parseBlueprintTag,
      parseContentEditable: parseContentEditable,
      removeInlineBreakpoint: removeInlineBreakpoint,
      wrapOldMediaElement: wrapOldMediaElement,
      parseOldPageBreakContent: parseOldPageBreakContent,
      decodeLinkUri: decodeLinkUri,
      validateLinkProtocol: validateLinkProtocol,
      parseEmoticon: parseEmoticon,
      removeDuplicateMedia: removeDuplicateMedia,
      encodeVariable: encodeVariable,
      decodeVariable: decodeVariable,
      setCommentWithAssignUsers: setCommentWithAssignUsers,
      parseSnippetTag: parseSnippetTag,
      parseTextareaFroalaPreviewContent: parseTextareaFroalaPreviewContent,
      attachTableOfContentId: attachTableOfContentId,
      attachMenuClickHandler: attachMenuClickHandler,
      compileEditorFieldElement: compileEditorFieldElement,
      embedTallyfyEntityLink: embedTallyfyEntityLink,
      removeEntityWhiteSpace: removeEntityWhiteSpace,
      syncEntityFormField: syncEntityFormField,
      getAllTextNodes: getAllTextNodes,
      validateOrgGlobalCssString: validateOrgGlobalCssString,
      wrapStyleToElement: wrapStyleToElement,
      applyGlobalStyle: applyGlobalStyle,
      addLinkTargetFroalaFile: addLinkTargetFroalaFile
    };

    /**
     * @ngdoc method
     * @name getLightboxCaption
     * @private
     * 
     * @param {*} el
     * @returns {string} lightbox caption
     * 
     * @description
     * to get lightbox caption
     */
    function getLightboxCaption(el) {
      var name = _.get(el.attributes, 'data-caption-name.value', ''),
        url = _.get(el.attributes, 'data-caption-url.value', ''),
        size = _.get(el.attributes, 'data-caption-size.value', '');

      return [
        '<span contenteditable="false">',
        name,
        '</span><span class="fa fa-circle">&nbsp</span><span>',
        size,
        '</span><span class="fa fa-circle">&nbsp</span><a class="froala-img-download" href="',
        url,
        '" target="_blank">' + $filter('translate')('steps.downloadText') + '</a>'
      ].join("");
    }

    /**
     * @ngdoc method
     * @private
     * 
     * @name showImageLightbox
     * @param {*} element
     * 
     * @description
     * binding click event to show image lightbox on lightbox-image
     */
    function showImageLightbox($element, inEditor) {
      var li = $element.find('.lightbox-image' + (inEditor ? '.lightbox-image-link' : ''));
      if (Helper.isObjectEmpty(li)) return;
      li.bind('click', function (e) {
        if (e.target.className === 'froala-img-download') {
          return;
        }
        e.stopImmediatePropagation();
        Lightbox.openModal([{
          'url': e.target.src || e.target.attributes['data-view-url'].value,
          'caption': getLightboxCaption(e.currentTarget)
        }], 0);
      });
    }

    /**
     * @ngdoc method
     * @private
     * 
     * @name buildLinkElements
     * @param {*} $element
     * @returns {Array} array of replacer object
     * 
     * @description
     * generate acceptable link/image elements
     */
    function buildLinkElements($element) {
      // acceptable element is only element that has attribute [src] and <a>
      var linkElements = $element.find("[src], a"),
        replacerObjects = [],
        idReplacer, text, link;

      // iterate all link elements
      for (var i = 0; i < linkElements.length; i++) {
        // remove froala link images class element
        if (linkElements[i].classList.contains('lightbox-image-link')) {
          linkElements[i].parentNode.removeChild(linkElements[i]);
        } else {
          // logic to generate replacer object
          var image = undefined;
          if (linkElements[i].src && !linkElements[i].parentNode.classList.contains('lightbox-image')) {
            // checking element that contain src attribute
            var caption = linkElements[i].src.split('/').length >= 0 && linkElements[i].src.indexOf('blob') == 0
              ? linkElements[i].src.split('/').pop()
              : linkElements[i].src.replace(CONST.URL_PREFIX_CHECKER, "");
            text = caption || linkElements[i].innerHTML;
            if (text) {
              text = text.split('/').pop();
            }
            link = linkElements[i].src;
          } else if (linkElements[i].href && !linkElements[i].classList.contains('froala-img-download')) {
            // checking element that contain href attribute (<a>)
            text = linkElements[i].innerHTML || linkElements[i].href.replace(CONST.URL_PREFIX_CHECKER, "");
            link = linkElements[i].href;
          } else if (linkElements[i].classList.contains('lightbox-image')) {
            // single lightbox-image class element should be used as a lightbox and image props will be used to generate the lightbox properties
            image = {
              name: linkElements[i].dataset.captionName,
              url: linkElements[i].dataset.captionUrl,
              size: linkElements[i].dataset.captionSize
            }
            text = linkElements[i].dataset.captionName;
            link = linkElements[i].dataset.captionUrl;
          } else {
            // another object will be removed
            linkElements[i].parentNode.removeChild(linkElements[i]);
            continue;
          }
          idReplacer = Helper.guid();
          linkElements[i].outerHTML = idReplacer;
          // all acceptable element stored in replacerObjects to be used as replacer string object
          replacerObjects.push({
            id: idReplacer,
            caption: text,
            link: link,
            image: image
          });
        }
      }
      return replacerObjects;
    }

    /**
     * @ngdoc method
     * @public
     * 
     * @name parseElement
     * @param {*} $scope 
     * @param {*} $element 
     * @param {*} config
     * 
     * @description
     * parse text shorten element's text
     */
    function parseElement($scope, $element, config) {
      var parseElement = function (tempElement) {
        $element
          .empty()
          .removeClass('fr-view')
          .removeClass('bp-ctx')
          .html(tempElement.html());
        var childElements = $element.find("*");

        // add space on block elements
        for (var i = 0; i < childElements.length; i++) {
          if (['block', 'list-item'].indexOf(DOMService.getComputedStyle(childElements[i], 'display')) >= 0) {
            childElements[i].appendChild($document[0].createTextNode("\u00A0"));
          }
        }

        var container = angular.element('<div>');

        // only truncate element when element is not expand
        if (!config.isExpand) {
          container.html(
            truncate($scope, $element, config)
          );
          $compile(container)($scope);
          $element
            .empty()
            .append(container);
        }

        // only attach toggler if element is not empty
        if ($element.text()) {
          $element.addClass('fr-view');
          $element.addClass('bp-ctx');
          showImageLightbox($element);
          attachTogglerElement($scope, $element, container, config);
        }
      }

      if ($scope.froalaTextShorten) {
        var tempElement = angular.element('<div>').html($scope.froalaTextShorten);
        if ($rootScope.userState !== USER_STATE.GUEST && $rootScope.userState !== USER_STATE.PUBLIC) {
          if (Helper.isObjectEmpty($rootScope.organizationTemplates)) {
            $rootScope.organizationTemplates = {};
          }
          parseBlueprintTag(tempElement, $scope);
          var snippetTags = tempElement.find('.insert-snippet-tag');
          var requests = [], requestContents = [];
          for (var i = 0; i < snippetTags.length; i++) {
            var template = $rootScope.organizationTemplates[snippetTags[i].dataset.snippetId];
            if (template) {
              snippetTags[i].innerHTML = template.template;
              var linkedElements = angular.element(snippetTags[i]).find('img, a');
              if (!linkedElements.length) {
                snippetTags[i].innerHTML = $filter('linkify')(snippetTags[i].innerText, '_blank');
              }
            } else {
              requests.push(OrganizationsService.getTextTemplate(snippetTags[i].dataset.snippetId, true));
              requestContents.push(snippetTags[i]);
            }
          }
          if (requests.length) {
            $q.all(
              _.map(requests, function (request) {
                return request.catch(angular.noop);
              })
            ).then(function (res) {
              for (var i = 0; i < requests.length; i++) {
                if (res[i]) {
                  $rootScope.organizationTemplates[res[i].data.id] = res[i].data;
                  requestContents[i].innerHTML = res[i].data.template;
                  var linkedElements = angular.element(requestContents[i]).find('img, a');
                  if (!linkedElements.length) {
                    requestContents[i].innerHTML = $filter('linkify')(requestContents[i].innerText, '_blank');
                  }
                }
              }
              parseElement(tempElement);
            });
          } else {
            parseElement(tempElement);
          }
        }
      }

      /**
       * @ngdoc method
       * @private
       * 
       * @name getReplacerObjectHtmlLink
       * @param {*} replacerObject
       * 
       * @description
       * get replacer object html link element
       */
      function getReplacerObjectHtmlLink(replacerObject) {
        return '<a class="bp-link _600" '
          + (_.isUndefined(replacerObject.image) ? ' href="' + replacerObject.link + '" target="_blank"'
            : ' data-text="' + replacerObject.caption + '" data-size="' + _.get(replacerObject, 'image.size') + '" data-link="' + replacerObject.link + '" data-ng-click="showImageLightbox($event)"')
          + '>' + replacerObject.caption + '</a>';
      }

      /**
       * @name truncate
       * @param {*} $scope 
       * @param {*} $element 
       * @param {*} config
       * @returns {string} truncated html text
       * 
       * @description
       * to get truncated html element
       */
      function truncate($scope, $element, config) {
        var replacerObjects, elementText, length, tempLength;

        // initialize helper truncate variable
        replacerObjects = buildLinkElements($element);
        elementText = $element.text().replace(CONST.EXTRA_WHITESPACE_REGEX, ' ').trim();
        length = config.isExpand && !$scope.openModal ? elementText.length : config.threshold;
        tempLength = length;
        // loop all replacer object to replace with html link element
        for (var i = 0; i < replacerObjects.length; i++) {
          var index = elementText.indexOf(replacerObjects[i].id);
          // check if replacer.id is exist on temp element inner text
          if (index >= 0 && index <= length) {
            var linkHTML = getReplacerObjectHtmlLink(replacerObjects[i]);
            // increase length value with replacer link html text length 
            length += linkHTML.length;
            elementText = elementText.replace(replacerObjects[i].id, linkHTML);
          }
        }

        if (tempLength === length) {
          var shortenText = elementText.substring(0, tempLength)
          if ($scope.isSearchable) {
            return $filter("highlight")(shortenText, $scope.searchKeyword);
          }
          return shortenText;
        } else {
          var tempElementText = elementText;
          var variableTags = tempElementText.match(CONST.TEXT_IN_A_TAG_REGEX) || [];

          if (variableTags.length > 0) {
            var newTotal = tempNewTotal = load = saveTxt = tagValueLength = 0;
            _.forEach(variableTags, function (tagValue) {
              var newTagValue = tagValue.replace(CONST.REMOVE_HTML_TAG_REGEX, "");
              tagValueLength = tagValueLength + newTagValue.length;
              var totalLength = tempElementText.indexOf(tagValue) + tagValue.length;
              newTotal = newTotal + totalLength;
              tempNewTotal = tempNewTotal + (tempElementText.indexOf(tagValue) + newTagValue.length);

              if ((tempNewTotal > tempLength) && (load === 0)) {
                var diff = tempNewTotal - tempLength;
                load = 1;
                saveTxt = newTotal - diff;
              } else {
                tempElementText = elementText.slice(totalLength, elementText.length);
              }
            });

            if ((saveTxt > 0) && (load === 1)) {
              return elementText.substring(0, saveTxt);
            } else {
              if (length < tempLength) {
                return elementText.substring(0, length);
              } else {
                return elementText.substring(0, length - tagValueLength);
              }
            }
          } else {
            return elementText.substring(0, 250);
          }
        }
      }

      /**
       * @name attachTogglerElement
       * @param {*} $scope 
       * @param {*} $element 
       * @param {*} container 
       * @param {*} config 
       * 
       * @description
       * attach toggle element in to text shorten element
       */
      function attachTogglerElement($scope, $element, container, config) {
        var showToggler = $element.text().length >= config.threshold;

        if (showToggler) {
          // three dot only display when scope.openModal = true and element is not expand
          var dotSeparator = angular
            .element('<span>')
            .addClass("tpl-three-dot")
            .text($scope.openModal && !config.isExpand ? '...' : (!config.isExpand ? '...' : ''));
          $compile(dotSeparator)($scope);
          container.append(dotSeparator);
        }

        // toggler only apply if element text > config.threshold link element length exist
        if (showToggler || $element.find('a').length > 0) {
          var toggler = angular
            .element('<a data-ng-click="onToggle($event)"></a>')
            .addClass("tpl-nav")
            .addClass("_600")
            .text(config.isExpand && !$scope.openModal ? config.lessLabel : config.moreLabel);
          $compile(toggler)($scope);
          container.append(toggler);
        }

        container.text() ? $element.append(container) : angular.noop();
      }
    }

    /**
     * @name getRawContent
     * @param {*} content 
     * @returns raw content
     * 
     * @description
     * get raw content of froala element
     */
    function getRawContent(content, cleanUp) {
      if (typeof cleanUp === 'undefined') {
        cleanUp = true;
      }
      var tempDom = angular.element('<div>').html(content);
      var items = tempDom.find('.atwho-content');

      for (var i = 0; i < items.length; i++) {
        items[i].outerHTML = '@[' + angular.element(items[i]).find('.user-id').data('user-id') + ']';
      }

      // Check for tallyfyEntityEmbeds
      var tallyfyEntityEmbeds = tempDom.find('.embed-tallyfy-entityLink-previewed');
      for (var i = 0; i < tallyfyEntityEmbeds.length; i++) {
        tallyfyEntityEmbeds[i].classList.remove('embed-tallyfy-entityLink-previewed');
        tallyfyEntityEmbeds[i].innerHTML = 'embedLink';
      }

      return cleanUp ? cleanHTML(tempDom.html()) : tempDom.html();
    }


    /**
     * @name getViewContent
     * @param {*} content 
     * @returns view content
     * 
     * @description
     * get view content of froala element
     */
    function getViewContent(content, mentionData, cleanUp, removeButtonHide) {
      if (typeof cleanUp === 'undefined') {
        cleanUp = true;
      }
      var regex = /@\[[a-z0-9@+-.]+\]/mgi,
        matches = content.match(regex),
        addRemoveButtoneHtml = '';
      if (!removeButtonHide) {
        addRemoveButtoneHtml = '<span data-ng-click="onAtwhoRemoveElement($event)" class="atwho-remove">x</span>'; 
      }
      if (matches != null) {
        matches.forEach(function (id) {
          var user_id = id.substr(2).slice(0, -1);
          var user = _.find(mentionData, function (u) {
            return user_id === u.id.toString();
          });
          if (user) {
            var firstName = user.full_name ? user.full_name.split(' ')[0] : user.first_name,
              lastName = user.full_name ? user.full_name.split(' ')[1] : user.last_name;
            if (user && user.type === 'member') {
              if (user.profile_pic.indexOf('www.gravatar.com') >= 0) {
                content = content.replace(id, '<span class="atwho-content fr-deletable ng-scope d-inline-block pos-rlt mr-1" contenteditable="false"><span class="atwho-el pr-1"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user_id + '"></span><span class="flex-justify atwho-avatar w-24 circle text-u-c steel-dark text-white">' + firstName[0] + lastName[0] + '</span><span class="atwho-display-text _600">' + firstName + ' ' + lastName + '</span>'+addRemoveButtoneHtml+'</span></span></span>');
              } else {
                content = content.replace(id, '<span class="atwho-content fr-deletable ng-scope d-inline-block pos-rlt mr-1" contenteditable="false"><span class="atwho-el pr-1"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user_id + '"></span><img  class="atwho-avatar img" src="' + user.profile_pic + '" /><span class="atwho-display-text _600">' + firstName + ' ' + lastName + '</span>'+addRemoveButtoneHtml+'</span></span></span>');
              }
            } else if (user && user.type === 'group') {
              var firstName = user.full_name;
              if (user.logo) {
                content = content.replace(id, '<span class="atwho-content fr-deletable ng-scope d-inline-block pos-rlt mr-1" contenteditable="false"><span class="atwho-el pr-1"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user_id + '"></span><img  class="atwho-avatar img" src="' + user.logo + '" /><span class="atwho-display-text _600">' + firstName + '</span>'+addRemoveButtoneHtml+'</span></span></span>');
              } else {
                content = content.replace(id, '<span class="atwho-content fr-deletable ng-scope d-inline-block pos-rlt mr-1" contenteditable="false"><span class="atwho-el pr-1"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user_id + '"></span><i class="atwho-avatar avatar-icon v-c p-1 img fa fa-users"></i><span class="atwho-display-text _600">' + firstName + '</span>'+addRemoveButtoneHtml+'</span></span></span>');
              }
            } else {
              content = content.replace(id, '<span class="atwho-content fr-deletable ng-scope d-inline-block pos-rlt mr-1" contenteditable="false"><span class="atwho-el"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user_id + '"></span><span class="flex-justify atwho-avatar w-24 circle text-u-c steel-dark text-white">' + firstName[0] + lastName[0] + '</span><span class="atwho-display-text _600">' + firstName + ' ' + lastName + '</span>'+addRemoveButtoneHtml+'</span></span></span>');
            }
          } else {
            var guestEmail = id.startsWith('@[') ? id.substr(2).slice(0, -1) : id.substr(0).slice(0, -1);
            content = content.replace(id, '<span class="atwho-content fr-deletable ng-scope d-inline-block pos-rlt mr-1" contenteditable="false"><span class="atwho-el"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + guestEmail + '"></span><span class="flex-justify atwho-avatar w-24 circle text-u-c guest-avatar-bg text-white">' + guestEmail[0] + guestEmail[1] + '</span><span class="atwho-display-text _600">' + guestEmail + '</span>'+addRemoveButtoneHtml+'</span></span></span>');
          }
        });
      }
     return cleanUp ? cleanHTML(content) : content;
    }
    
    function getViewContentFromSummary(content, mentionData, cleanUp) {
      if (typeof cleanUp === 'undefined') {
        cleanUp = true;
      }
      content = content || "";
      var regex = /@\[[a-z0-9@+-.]+\]/mgi,
        matches = content.match(regex);
      if (matches != null) {
        matches.forEach(function (id) {
          var user_id = id.substr(2).slice(0, -1);
          var user = _.find(mentionData, function (u) {
            return user_id === u.id.toString();
          });
          if (user) {
            var firstName = user.full_name ? user.full_name.split(' ')[0] : user.first_name,
              lastName = user.full_name ? user.full_name.split(' ')[1] : user.last_name;
            if (user && user.type === 'member') {
              if (user.profile_pic.indexOf('www.gravatar.com') >= 0) {
                content = content.replace(id, '<span class="atwho-content fr-deletable ng-scope d-inline-block pos-rlt mr-1" contenteditable="false"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user_id + '"></span><span class="flex-justify atwho-avatar w-24 circle text-u-c steel-dark text-white">' + firstName[0] + lastName[0] + '</span><span class="atwho-display-text _600">' + firstName + ' ' + lastName + '</span></span></span>');
              } else {
                content = content.replace(id, '<span class="atwho-content fr-deletable ng-scope d-inline-block pos-rlt mr-1" contenteditable="false"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user_id + '"></span><img  class="atwho-avatar img" src="' + user.profile_pic + '" /><span class="atwho-display-text _600">' + firstName + ' ' + lastName + '</span></span></span>');
              }
            } else if (user && user.type === 'group') {
              var firstName = user.full_name;
              if (user.logo) {
                content = content.replace(id, '<span class="atwho-content fr-deletable ng-scope d-inline-block pos-rlt mr-1" contenteditable="false"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user_id + '"></span><img  class="atwho-avatar img" src="' + user.logo + '" /><span class="atwho-display-text _600">' + firstName + '</span></span></span>');
              } else {
                content = content.replace(id, '<span class="atwho-content fr-deletable ng-scope d-inline-block pos-rlt mr-1" contenteditable="false"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user_id + '"></span><i class="atwho-avatar avatar-icon v-c p-1 img fa fa-users"></i><span class="atwho-display-text _600">' + firstName + '</span></span></span>');
              }
            } else {
              content = content.replace(id, '<span class="atwho-content fr-deletable ng-scope d-inline-block pos-rlt mr-1" contenteditable="false"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user_id + '"></span><span class="flex-justify atwho-avatar w-24 circle text-u-c steel-dark text-white">' + firstName[0] + lastName[0] + '</span><span class="atwho-display-text _600">' + firstName + ' ' + lastName + '</span></span></span>');
            }
          } else {
            var guestEmail = id.substr(0).slice(0, -1);
            content = content.replace(id, '<span class="atwho-content fr-deletable ng-scope d-inline-block pos-rlt mr-1" contenteditable="false"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + guestEmail + '"></span><span class="flex-justify atwho-avatar w-24 circle text-u-c guest-avatar-bg text-white">' + guestEmail[0] + guestEmail[1] + '</span><span class="atwho-display-text _600">' + guestEmail + '</span></span></span>');
          }
        });
      }
      return cleanUp ? cleanHTML(content) : content;
    }

    /**
     * @name cleanHTML
     * @param {*} htmlContents
     * 
     * @description
     * clean up html tag
     */
    function cleanHTML(htmlContent) {
      var tempElement = angular
        .element('<div>')
        .html(removeWhiteSpace(htmlContent));
      _.forEach(tempElement.find('.atwho-query') || [], function (el) {
        el.classList.remove('atwho-query');
      });
      return tempElement.html();
    }

    /**
     * @ngdoc method
     * @name removeWhiteSpace
     * 
     * @param {*} htmlContent 
     * 
     * @description
     * remove editor whitespace
     */
    function removeWhiteSpace(htmlContent) {
      return htmlContent
        .replace(/&nbsp;/g, ' ').trim()
        .replace(/^(\s*<br( \/)?>)*|(<br( \/)?>\s*)*$/gm, '')
        .replace(/(?:<br>){3,}/g, '<br/><br/>');
    }

    /**
     * @ngdoc method
     * @name setBreakLine
     * 
     * @param {*} enter 
     * 
     * @description
     * set the DOM breakline of froala editor
     */
    function setDOMBreakLine(enter) {
      return enter === FroalaEditor.ENTER_BR ? '<br/>' : enter === FroalaEditor.ENTER_DIV ? '<div></div>' : '<p></p>';
    }

    /**
     * @name normalizeCodeTag
     * 
     * @param {*} element 
     * @param {*} rawValue 
     * 
     * @description
     * Parse code tag / normalize
     */
    function normalizeCodeTag(element, rawValue, variables) {
      var tempCodeTags = angular.element('<div>').html(rawValue).find('code');
      for (var i = 0; i < tempCodeTags.length; i++) {
        for (var j = 0; j < variables.length; j++) {
          angular.element(tempCodeTags[i]).html(_.replace(angular.element(tempCodeTags[i]).html(), new RegExp(variables[j].alias, 'g'), variables[j].value));
        }
        angular.element(element.find('code')[i]).html(angular.element(tempCodeTags[i]).html());
      }
    }

    /**
     * @name attachViewFullSize
     * @param {*} element 
     * 
     * @description
     * handler to attach full size caption on image
     * for old description value there are no 'view full size' caption
     */
    function attachViewFullSize(element) {
      var imageCaptions = element.find('.fr-img-wrap');
      for (var i = 0; i < imageCaptions.length; i++) {
        var captionLink = angular.element(imageCaptions[i]);
        var imgLink = captionLink.find('.lightbox-image-link');
        if (!imgLink.length) {
          var link = captionLink.find('.froala-img-lightbox');
          if (link.length) {
            var dataset = link.get(0).dataset;
            imageCaptions[i].appendChild(angular.element('<span>').addClass('fa fa-circle').get(0));

            var linkElement = angular.element('<a>')
              .addClass('froala-img-lightbox lightbox-image lightbox-image-link')
              .attr('src', angular.element(captionLink.find('.froala-img-lightbox').find('img').get(0)).attr('src'))
              .text($filter('translate')('steps.viewImage'));

            linkElement.get(0).setAttribute('data-view-url', dataset.captionUrl);
            _.extend(linkElement.get(0).dataset, dataset);

            imageCaptions[i].appendChild(linkElement.get(0));
          }
        }
      }
    }

    /**
     * @ngdoc method
     * @name removeFroalaBanner
     * 
     * @param {*} element
     * 
     * @description
     * remove froala banner 
     */
    function removeFroalaBanner(element) {
      var banner = element.find('[data-f-id="pbf"]');
      if (banner.length) {
        banner[0].parentNode.removeChild(banner[0]);
      }
    }

    /**
     * @ngdoc method
     * @name parseInsertVariableTag
     * @param {*} value 
     * 
     * @description
     * parse old value of insert variable tag
     */
    function parseInsertVariableTag(viewElement, variableValues) {
      var allVariable = [];
      _.map(variableValues || [], function (variable) {
        allVariable.push((variable.id === 'DATE') || (variable.id === 'TEMPLATE_NAME') ? variable : variable.capture);
      });
      var tempElements = viewElement.find('.insert-variable-tag');
      for (var i = 0; i < tempElements.length; i++) {
        tempElements[i].setAttribute('contenteditable', 'false');
        if (!tempElements[i].classList.contains('fr-deletable')) {
          tempElements[i].classList.add('fr-deletable');
        }
        if (tempElements[i].classList.contains('tooltipped-top')) {
          tempElements[i].classList.remove('tooltipped-top');
        }
        var parentNode = tempElements[i].parentNode;
        if (parentNode && !parentNode.classList.contains('fr-view') && parentNode.classList.contains('inline')) {
          parentNode.outerHTML = tempElements[i].outerHTML;
        }

        // Reset deleted variable changes
        if (tempElements[i].classList.contains('variable-deleted')) {
          tempElements[i].classList.remove('variable-deleted');
          if (tempElements[i].classList.contains('tooltipped')) {
            tempElements[i].classList.remove('tooltipped');
          }
          if (tempElements[i].classList.contains('tooltipped-right')) {
            tempElements[i].classList.remove('tooltipped-right');
          }
          tempElements[i].removeAttribute('data-title');
        }
      }
    }

    /**
     * @name wrapOldMediaElement
     * @param {*} viewElement 
     * 
     * @description
     * wrap old video element
     */
    function wrapOldMediaElement(viewElement) {
      var iframe = viewElement.find('iframe');
      for (var i = 0; i < iframe.length; i++) {
        var isVideo = iframe[i].hasAttribute('allowfullscreen');
        if (isVideo) {
          var parentNode = iframe[i].parentNode;
          if (parentNode && !parentNode.classList.contains('fr-view') && !parentNode.classList.contains('fr-video')) {
            parentNode.className = 'fr-video fr-deletable';
          }
        }
      }
    }

    /**
     * @name removeInlineBreakpoint
     * @description
     * remove inline breakpoint temporary handler
     */
    function removeInlineBreakpoint(viewElement) {
      var inline = viewElement.find('.inline');
      for (var i = 0; i < inline.length; i++) {
        var parentNode = inline[i].parentNode;
        if (parentNode && parentNode.classList.contains('fr-view')) {
          inline[i].outerHTML = inline[i].innerHTML;
        }
      }
    }

    /**
     * @ngdoc method
     * @name parseContentEditable
     * @param {*} value 
     * 
     * @description
     * method to add empty space
     */
    function parseContentEditable(viewElement) {
      var tempElements = viewElement.find('[contenteditable=false]');
      for (var i = 0; i < tempElements.length; i++) {
        if (tempElements[i].classList.contains('insert-snippet-tag') || tempElements[i].classList.contains('insert-variable-tag') || tempElements[i].classList.contains('atwho-content')) {
          var nextSibling = tempElements[i].nextSibling;
          var emptySpace = document.createTextNode("\uFEFF");
          if (nextSibling) {
            if (nextSibling.nodeType != 3) {
              var nextSiblingParent = nextSibling.parentNode;
              nextSiblingParent.insertBefore(emptySpace, nextSibling);
            }
          } else {
            viewElement[0].appendChild(emptySpace);
          }
          var previousSibling = tempElements[i].previousSibling;
          if (previousSibling) {
            if (previousSibling.nodeType != 3) {
              var previousSiblingParent = previousSibling.parentNode;
              previousSiblingParent.insertBefore(emptySpace, tempElements[i]);
            }
          } else {
            if (tempElements[i].nodeType != 3) {
              var previousSiblingParent = tempElements[i].parentNode;
              previousSiblingParent.insertBefore(emptySpace, tempElements[i]);
            }
          }
        }
      }
    }

    /**
     * @name decodeLinkUri
     * @param {*} fe 
     * 
     * @description
     * decode the encoded link url
     */
    function decodeLinkUri(fe) {
      var links = fe.find('a');
      for (var i = 0; i < links.length; i++) {
        var link = links.get(i);
        var linkStr = link.getAttribute('href') || link.innerText || '';
        var decodedLink = decodeURI(linkStr) || '';
        link.setAttribute('href', decodedLink.replace('%3A', ':'));
      }
    }

    /**
     * @name validateLinkProtocol
     * @param {*} fe 
     * 
     * @description
     * validate link url
     */
    function validateLinkProtocol(fe) {
      var links = fe.find('a');
      _.forEach(links, function (link) {
        var linkRef = link.getAttribute('href');
        if (linkRef) {
          var href = normalizeValue(linkRef);
          link.setAttribute('href', href);
        }
      });
    }

    function removeFirstNullArrayValue(array) {
      return !array[0] ? removeFirstNullArrayValue(array.splice(1, array.length)) : array;
    }

    function normalizeValue(href) {
      var arrStr = href.split('://'), result;
      if (arrStr.length == 1) {
        result = href.split(':').length > 1 ? arrStr : 'https://' + href;
      } else {
        var protocol = arrStr[0] ? arrStr[0] + '://' : 'https://';
        var arr = href.split(protocol);
        result = arr.length == 2 ? href : _.concat([""], removeFirstNullArrayValue(arr)).join(protocol);
      }
      return result;
    }

    /**
     * @ngdoc method
     * @name parseEmoticon
     * 
     * @param {*} fe 
     * @param {*} emoticons 
     * 
     * @description
     * parse emoticons
     */
    function parseEmoticon(fe, emoticons) {
      fe.selection.save();
      var str = fe.$el[0].innerHTML;
      for (var i = 0; i < emoticons.length; i++) {
        var isExist = str.includes((fe.$el[0].innerText.length <= 4 ? '' : ' ') + emoticons[i].desc + ' ');
        if (isExist) {
          fe.$el[0].innerHTML = str.replace(emoticons[i].desc, '<span class="fr-emoticon fr-deletable fr-emoticon-img" style="background: url(https://cdnjs.cloudflare.com/ajax/libs/emojione/2.0.1/assets/svg/' + emoticons[i].code + '.svg);">&nbsp;</span> ');
          break;
        }
      }
      fe.selection.restore();
    }

    /**
     * @name embedTallyfyEntityLink
     * @param {*} fe 
     * 
     * @description
     * on paste or when url is entered inside a froala to show preview we add iframe for the url
     * making sure url entered is tallyfy's url
     */
    function embedTallyfyEntityLink(scope, fe) {
      var links = fe.find('a');
      for (var i = 0; i < links.length; i++) {
        var link = links.get(i);
        if (link && !link.classList.contains('embed-tallyfy-entityLink-previewed')) {
          var linkStr = link.getAttribute('href') || link.innerText || '';
          if (linkStr.indexOf(window.location.origin) === 0) {
            var div = document.createElement('editor-pasted-url');
            if (linkStr.indexOf('/blueprints/') > -1 || linkStr.indexOf('activeTask=') > -1) {
              div.setAttribute('contenteditable', false);
              link.classList.add('embed-tallyfy-entityLink-previewed');
              div.classList.add('fr-deletable', 'embed-tallyfy-entityLink-previewed');
            }
            if (linkStr.indexOf('/blueprints/') > -1) {
              var blueprintId = linkStr.split('/blueprints/')[1].split('/')[0];
              if (blueprintId) {
                div.setAttribute('data-blueprint-id', "'" + blueprintId + "'");
                div.innerHTML = "{Blueprint - " + blueprintId + "}";
              }
            } else if (linkStr.indexOf('activeTask=') > -1) {
              var taskId = linkStr.split('activeTask=')[1].split('&')[0];
              if (taskId) {
                div.setAttribute('data-task-id', "'" + taskId + "'");
                div.innerHTML = "{Task - " + taskId + "}";
              }
            } else if (linkStr.indexOf('processes/') > -1) {
              var processId = linkStr.split('/processes/')[1].split('?')[0];
              if (processId) {
                div.setAttribute('data-process-id', "'" + processId + "'");
                div.innerHTML = "{Process - " + processId + "}";
              }
            }

            if (div.innerHTML.length) {
              div.setAttribute('data-pasted-url', "'" + linkStr + "'");
              link.insertAdjacentElement('afterend', div);
              link.parentElement.removeChild(link);
              $compile(div)(scope);
            }
          }
        }
      }
    }

    function removeDuplicateMedia(fe) {
      var tyFrMedia = fe.find('.ty-fr-media');
      _.forEach(tyFrMedia, function (media) {
        var el = angular.element(media);
        el.addClass('ty-fr-media');
        var isDuplicated = el.find('.ty-fr-media');
        if (isDuplicated.length) {
          el[0].outerHTML = isDuplicated[0].outerHTML;
        }
      });
    }

    function encodeVariable(context) {
      var variables = (context || '').match(CONST.DOUBLE_CURLY_REGEX) || [];
      variables.forEach(function (variable) {
        context = context.replace(variable, '\uFEFF<span class="insert-variable-tag fr-deletable" contenteditable="false">' + variable + '</span>\uFEFF');
      });
      return context;
    }

    function decodeVariable(parsedContext) {
      var el = angular.element('<div>' + (parsedContext || '').trim() + '</div>');
      return el[0].innerText;
    }

    function parseBlueprintTag(element, scope) {
      var blueprintTags = element.find('.insert-blueprint-tag:not(.rendered)');
      if (blueprintTags.length) {
        for (var i = 0; i < blueprintTags.length; i++) {
          var html = $compile('<froala-bp-view data-expand-all="true" data-blueprint-id="\'' + blueprintTags[i].dataset.blueprintId + '\'" data-blueprint-alias="\'' + blueprintTags[i].innerText.replace(/'/g, '\\\'').replace(/"/g, '&quot;') + '\'"></froala-bp-view>')(scope)
          angular.element(blueprintTags[i]).html(html);
        }
      }
    }

    function setCommentWithAssignUsers(param) {
      var comment = '';
      if (_.get(param, 'users', []).length > 0) {
        _.forEach(param.users, function (value) {
          if (_.get($rootScope, 'identity.id') !== value) {
            comment += '@[' + value + ']';
          }
        });
      }
      if (_.get(param, 'groups', []).length > 0) {
        _.forEach(param.groups, function (value) {
          comment += '@[' + value + ']';
        });
      }
      if (_.get(param, 'guests', []).length > 0) {
        _.forEach(param.guests, function (value) {
          comment += '@[' + value + ']';
        });
      }
      comment += _.get(param, 'comment', '');
      return getViewContent(comment, _.concat(_.get(param, 'orgUsers', []), _.get(param, 'orgGroups', [])));
    }

    function parseSnippetTag(element, isPublic, cb) {
      if (Helper.isObjectEmpty($rootScope.organizationTemplates)) {
        $rootScope.organizationTemplates = {};
      }
      var snippetTags = element.find('.insert-snippet-tag');
      var requests = [], requestContents = [];
      for (var i = 0; i < snippetTags.length; i++) {
        var template = $rootScope.organizationTemplates[snippetTags[i].dataset.snippetId];
        if (template) {
          angular.element(snippetTags[i]).addClass('rendered').html(template.template);
        } else {
          requests.push(OrganizationsService.getTextTemplate(snippetTags[i].dataset.snippetId, true, $rootScope.userState));
          requestContents.push(snippetTags[i]);
        }
      }
      if (requests.length) {
        $q.all(
          _.map(requests, function (request) {
            return request.catch(angular.noop);
          })
        ).then(function (res) {
          for (var i = 0; i < requests.length; i++) {
            if (res[i] && isPublic) {
              if (res[i].data.snippet_visible_public) {
                $rootScope.organizationTemplates[res[i].data.id] = res[i].data;
                angular.element(requestContents[i]).addClass('rendered').html(res[i].data.template);
              }
            }
          }
          removeDuplicateMedia(element);
          attachViewFullSize(element);
          showImageLightbox(element);
          removeFroalaBanner(element);
          removeInlineBreakpoint(element);
          wrapOldMediaElement(element);
          validateLinkProtocol(element);
          addLinkTargetFroalaFile(element);
          if (cb && typeof cb === 'function') {
            cb();
          }
        });
      } else {
        removeDuplicateMedia(element);
        attachViewFullSize(element);
        showImageLightbox(element);
        removeFroalaBanner(element);
        removeInlineBreakpoint(element);
        wrapOldMediaElement(element);
        validateLinkProtocol(element);
        addLinkTargetFroalaFile(element);
        if (cb && typeof cb === 'function') {
          cb();
        }
      }
    }

    function parseTextareaFroalaPreviewContent(viewElement, variableValues) {
      var tempElements = viewElement.find('.insert-variable-tag');
      var allCaptures = [];
      for (var i = 0; i < variableValues.length; i++) {
        allCaptures = _.concat(allCaptures, _.filter(variableValues[i].capture, { use_wysiwyg_editor: true }));
      }
      for (var i = 0; i < allCaptures.length; i++) {
        var froalaContents = _.filter(tempElements, function (content) {
          return content.dataset.variableId === '{{' + allCaptures[i].alias + '}}';
        });
        _.map(froalaContents, function (content) {
          content.innerHTML = content.innerText;
        });
      }
    }

    function attachTableOfContentId(froalaElement, scope) {
      var titles = froalaElement[0].querySelectorAll(_.get(scope.froalaOptions, 'tableOfContentElements', []));
      for (var i = 0; i < titles.length; i++) {
        var id = titles[i].getAttribute('id');
        if (!id) {
          angular.element(titles[i]).attr('id', Helper.getId());
        }
      }
    }

    function attachImageLightboxClick(el) {
      var _el = el;
      el.addEventListener('click', function () {
        var timeoutHandler = $timeout(function () {
          var popup = angular.element('.fr-popup.fr-desktop.fr-active');
          var buttonContainer = popup.find('.fr-buttons');
          buttonContainer
            .addClass('d-flex')
            .addClass('.align-items-center');

          var metadataDom = buttonContainer.find('.metadata-container');
          if (metadataDom.length) {
            metadataDom[0].parentNode.removeChild(metadataDom[0]);
          }

          var metadataContainer = angular.element('<div>');
          metadataContainer
            .addClass('metadata-container')
            .addClass('d-flex')
            .addClass('align-items-center');

          var viewFullSizeButton = angular.element('<button>');
          viewFullSizeButton.addClass('fr-command').addClass('fr-btn').addClass('fr-view-full-size-image');
          viewFullSizeButton.addClass('tooltipped').attr('data-title', 'View full size');
          var fullSizeIcon = angular.element('<i>');
          fullSizeIcon.addClass('far').addClass('fa-expand-wide');
          viewFullSizeButton.append(fullSizeIcon);

          viewFullSizeButton[0].addEventListener('click', function (e) {
            e.stopImmediatePropagation();
            var target = _el.querySelectorAll('.froala-img-lightbox.lightbox-image');
            Lightbox.openModal([{
              'url': target[0].src || target[0].attributes['data-view-url'].value,
              'caption': getLightboxCaption(target[0])
            }], 0);
            popup.removeClass('fr-active');
            metadataContainer[0].parentNode.removeChild(metadataContainer[0]);
          });

          var downloadButton = angular.element('<button>');
          downloadButton.addClass('fr-command').addClass('fr-btn').addClass('fr-download-button');
          downloadButton.addClass('tooltipped').attr('data-title', 'Download');
          var downloadIcon = angular.element('<i>');
          downloadIcon.addClass('fas').addClass('fa-download');
          downloadButton.append(downloadIcon);

          downloadButton[0].addEventListener('click', function (e) {
            e.stopImmediatePropagation();
            var target = _el.querySelectorAll('.froala-img-lightbox.lightbox-image');
            var url = target[0].src || target[0].attributes['data-view-url'].value;
            var tempLink = $document[0].createElement('a');
            tempLink.href = url;
            tempLink.target = "_blank";
            $document[0].body.appendChild(tempLink);
            tempLink.click();
            $document[0].body.removeChild(tempLink);
            popup.removeClass('fr-active');
            metadataContainer[0].parentNode.removeChild(metadataContainer[0]);
          });

          metadataContainer.append(viewFullSizeButton);
          metadataContainer.append(downloadButton);

          buttonContainer.append(metadataContainer);
          $timeout.cancel(timeoutHandler);
        }, 250);
      });
    }

    function attachMenuClickHandler(fe) {
      var element = fe.$el;
      var tyFrMedia = element.find('.ty-fr-media');
      for (var i = 0; i < tyFrMedia.length; i++) {
        attachImageLightboxClick(tyFrMedia[i], fe);
      }
    }

    function compileEditorFieldElement(scope, tagName) {
      var editorFields = [];
      if (scope.froalaOptions) {
        var element = _.get(scope.froalaOptions, 'froalaEditor.$el[0]');
        if (element) {
          editorFields = element.querySelectorAll([tagName]);
        }
      } else {
        var element = _.get(scope.element, '[0]');
        if (element) {
          editorFields = element.querySelectorAll([tagName]);
        }
      }
      for (var i = 0; i < editorFields.length; i++) {
        editorFields[i].setAttribute('contenteditable', 'false');
        if (tagName === 'editor-pasted-url') {
          editorFields[i].classList.add('embed-tallyfy-entityLink-previewed');
        }
        editorFields[i].classList.add('fr-deletable');
        $compile(editorFields[i])(scope);
      }
      if (scope.froalaOptions) {
        $timeout(function () {
          scope.froalaOptions.isBusy = false;
        }, 350);
      }
    }

    function getTextNodes(parentNode) {
      var strings = [], nodeList, length, i = 0;
      if (!parentNode instanceof Node) {
        return;
      }
      if (!parentNode.hasChildNodes()) {
        return null;
      }
      nodeList = parentNode.childNodes;
      length = nodeList.length;
      do {
        if ((nodeList[i].nodeType === Node.TEXT_NODE)) {
          strings.push(nodeList[i].nodeValue);
        }
        i++;
      } while (i < length);
      if (strings.length > 0) {
        return strings;
      }
      return null;
    }

    function removeEntityWhiteSpace(scope, tagName) {
      var editorFields = [];
      if (scope.froalaOptions) {
        editorFields = scope.froalaOptions.froalaEditor.$el[0].querySelectorAll([tagName]);
      } else {
        editorFields = scope.element[0].querySelectorAll([tagName]);
      }
      for (var i = 0; i < (editorFields || []).length; i++) {
        var textNodes = getTextNodes(editorFields[i]);
        for (var j = 0; j < (textNodes || []).length; j++) {
          if (textNodes[j] && !angular.element(textNodes[j].parentNode).hasClass('field-label')) {
            if (textNodes[j].parentNode) {
              textNodes[j].parentNode.removeChild(textNodes[j]);
            }
          }
        }
      }
    }

    function syncEntityFormField(scope) {
      if (!scope.froalaOptions.process) {
        return;
      }
      var preruns = scope.froalaOptions.process.prerun;
      var editorFormFields = scope.froalaOptions.froalaEditor.$el.find("editor-form-field");
      for (var i = 0; i < preruns.length; i++) {
        var isExist = _.find(editorFormFields, function (field) {
          return field.dataset.fieldId === "'" + preruns[i].id + "'";
        });
        if (!isExist) {
          $rootScope.$emit('FIELD_EDITOR:REMOVED', { fieldId: preruns[i].id, sync: true });
        }
      }
    }

    function wrapFirstParagraphTag(froalaElement) {
      var pTags = froalaElement.find('p');
      if (pTags.length) {
        for (var i = 0; i < pTags.length; i++) {
          var style = $(pTags[i]).attr('style');
          pTags[i].outerHTML = '<span style="' + style + '">' + pTags[i].innerHTML + '</span>';
        }
      }
    }

    function getAllTextNodes(el) {
      var n, a = [], walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null, false);
      while (n = walk.nextNode()) a.push(n);
      return a;
    }

    function validateOrgGlobalCssString(styles) {
      var orgGlobalStyle = styles || _.get($rootScope.identity, 'default_organization.global_css', '');
      var style = String('{' + orgGlobalStyle + '}')
        .replace(/"/gi, "'")
        .replace(/\s+/g, ' ')
        .replace(/^{/, '{\n')
        .replace(/\}/g, '}\n')
        .replace(/\n\s*([^\{]+)\s+?\{/g, '\n"$1":{')
        .replace(/([\{;])\s*([^:"\s]+)\s*:/g, '$1"$2":')
        .replace(/":\s*([^\}\{;]+)\s*(;|(\}))/g, '":"$1",$3')
        .replace(/\}/g, '},')
        .replace(/,\s*\}/g, '}');
      style = style.substring(0, style.length - 2);
      try {
        return JSON.parse(style);
      } catch (e) {
        return void 0;
      }
    }

    function wrapStyleToElement(elQuery, jsonCss) {
      var result = '', skipEncapsulationString = '.no-fr-view-encapsulation';
      for (var key in jsonCss) {
        var rules = key.split(',');
        for (var i = 0; i < rules.length; i++) {
          if (_.startsWith(rules[i], skipEncapsulationString)) {
            rules[i] = rules[i].replace(skipEncapsulationString, '');
            result += rules[i];
          } else {
            result += elQuery + ' ' + rules[i];
          }
          result += '{';
          for (var style in jsonCss[key]) {
            result += style + ':' + jsonCss[key][style] + ';';
          }
          result += '}';
        }
      }
      return result;
    }

    function applyGlobalStyle(id, action) {
      var jsonCss = validateOrgGlobalCssString(), orgGlobalStyle = wrapStyleToElement('.fr-view', jsonCss);
      if (orgGlobalStyle) {
        if (action === 'loadCSS') {
          uiLoad[action]('org-global-style-' + id, orgGlobalStyle);
        } else {
          uiLoad[action]('org-global-style-' + id);
        }
      }
    }

    function parseOldPageBreakContent(fe) {
      var element = fe.$el, tyPageBreakElements = (element) ? (element.find('.ty-fr-page-break') || []) : [];
      for (var i = 0; i < tyPageBreakElements.length; i++) {
        var isDeletable = tyPageBreakElements[i].classList.contains('fr-deletable');
        if (!isDeletable) {
          tyPageBreakElements[i].classList.add('fr-deletable');
        }
        if (!tyPageBreakElements[i].previousSibling) {
          tyPageBreakElements[i].parentNode.insertBefore(document.createTextNode("\uFEFF"), tyPageBreakElements[i]);
        }
      }
    }

    function addLinkTargetFroalaFile(element) {
      var froalaFileTags = element.find('.fr-file');
      for (var i = 0; i < froalaFileTags.length; i++) {
        froalaFileTags[i].classList.add('text-u-l');
        froalaFileTags[i].setAttribute('target', '_blank');
      }
    }
  }
})();