(function () {
  'use strict';
  angular
    .module('tallyfy')
    .service('Helper', Helper);

  /*@ngInject*/
  function Helper($window, _, $rootScope, CONST, AuthServerProvider, ENV_CONFIG, clipboard, $filter, Growl, modal, $uibModal, Principal, AuthPlan, FilesService,
    PLANS, $state, moment, WUFOO_URL, CONFIG, DateUtils, $localStorage, $document, BeaconService, OrganizationsService, Auth, $analytics, USER_STATE,
    $timeout, AccountService, store, $q, UsersService) {
    var self = this,
      classMap = {
        xls: 'fa-file-excel',
        xlsx: 'fa-file-excel',
        doc: 'fa-file-word',
        docx: 'fa-file-word',
        txt: 'fa-file-edit',
        pdf: 'fa-file-pdf',
        ppt: 'fa-file-powerpoint',
        pptx: 'fa-file-powerpoint',
        zip: 'fa-file-archive',
        rar: 'fa-file-archive',
        mp4: 'fa-file-video',
        mov: 'fa-file-video',
        wav: 'fa-file-audio',
        mp3: 'fa-file-audio',
        xml: 'fa-file-code'
      },
      growl = new Growl(),
      modalInstance,
      accountDisabledModalInstance,
      loginURL = (ENV_CONFIG.isDevEnv) ? ENV_CONFIG.AUTH_HOST + '/login?redirect_uri=' + CONST.CLIENT_LOCAL_DEV_URL : ENV_CONFIG.AUTH_HOST + '/login',
      eventTrackingEnvName = (_.toLower(ENV_CONFIG.APP_ENV) === 'prod') ? 'production' : _.toLower(ENV_CONFIG.APP_ENV);

    self.getFileObjectUrl = getFileObjectUrl;
    self.redirectToUrl = redirectToUrl;
    self.getEmailDomain = getEmailDomain;
    self.isStringContains = isStringContains;
    self.isImage = isImage;
    self.isValidEmail = isValidEmail;
    self.getFroalaOptions = getFroalaOptions;
    self.isMacOS = isMacOS;
    self.bytesToSize = bytesToSize;
    self.getFileClassName = getFileClassName;
    self.copyToClipboard = copyToClipboard;
    self.getCardTypes = getCardTypes;
    self.getFileLocation = getFileLocation;
    self.clearUniversalAlert = clearUniversalAlert;
    self.guid = guid;
    self.getId = getId;
    self.showCalendlyModal = showCalendlyModal;
    self.isObjectEmpty = isObjectEmpty;
    self.checkAccessAuthority = checkAccessAuthority;
    self.getDiffInDays = getDiffInDays;
    self.getTrialEndDate = getTrialEndDate;
    self.getDateDiffInHours = getDateDiffInHours;
    self.loginURL = loginURL;
    self.isMasquarade = isMasquarade;
    self.setOrgGoogleAnalyticsTracker = setOrgGoogleAnalyticsTracker;
    self.removeOrgGoogleAnalyticsTracker = removeOrgGoogleAnalyticsTracker;
    self.getDateDiffInMinutes = getDateDiffInMinutes;
    self.openAccountDisabledModal = openAccountDisabledModal;
    self.eventTrackingEnvName = eventTrackingEnvName;
    self.getCurrentCaretPositionOnWindow = getCurrentCaretPositionOnWindow;
    self.eventTrackingCategories = {
      'run': eventTrackingEnvName + '-PROCESS',
      'purchase': eventTrackingEnvName + '-PLAN-AND-PAYMENT',
      'signup': eventTrackingEnvName + '-SIGNUP',
      'blueprint': eventTrackingEnvName + '-BLUEPRINT',
      'quicklinks': eventTrackingEnvName + '-QUICKLINKS'
    };
    self.downloadAsset = downloadAsset;
    self.isJson = isJson;
    self.removeScriptTagOnString = removeScriptTagOnString;
    self.isMemberPresentInGroup = isMemberPresentInGroup;
    self.getRandomString = getRandomString;
    self.isMobileDevice = isMobileDevice;
    self.createNewOrg = createNewOrg;
    self.getMemberPrefixTranslation = getMemberPrefixTranslation;
    self.getHtmlTextContent = getHtmlTextContent;
    self.getAppExplanationItems = getAppExplanationItems;
    self.showChangesSavedGrowl = showChangesSavedGrowl;
    self.isNewGuestAddedProcessAndReturn = isNewGuestAddedProcessAndReturn;
    self.isGuestPresentInGroup = isGuestPresentInGroup;
    self.updateAssigneesOnGroupAddition = updateAssigneesOnGroupAddition;
    self.lowercaseKeys = lowercaseKeys;
    self.getFilteredUsers = getFilteredUsers;
    self.discardDisabledUsersFromArr = discardDisabledUsersFromArr;

    /**
     * @description
     * @param {any} blobParts
     * @param {any} contentType
     * @returns
     */
    function getFileObjectUrl(blobParts, contentType) {
      return URL.createObjectURL(new Blob([blobParts], { type: contentType }));
    }

    /**
     * @description
     * @param {any} url
     */
    function redirectToUrl(url) {
      $window.location.replace(url);
    }

    /**
     * @description
     * @param {any} emailId
     * @returns
     */
    function getEmailDomain(emailId) {
      var startPos = emailId.lastIndexOf('@') + 1;
      return _.toLower(emailId.substring(startPos, emailId.length));
    }

    /**
     * @description Returns if user is on MacOS
     * @returns
     */
    function isMacOS() {
      return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    }

    /**
     * @description
     * @param {String} str
     * @returns
     */
    function isValidEmail(str) {
      return CONST.EMAIL_REGEX.test(str);
    }

    /**
     * @description
     * @param {any} dataContainer
     * @param {any} dataToCheck
     * @param {any} seperator
     * @returns
     */
    function isStringContains(dataContainer, dataToCheck, seperator) {
      if (!_.isUndefined(dataContainer)) {
        dataContainer = _.split(_.replace(dataContainer, " ", ""), seperator);
        if (_.includes(dataContainer, dataToCheck)) {
          return true;
        }
      }
      return false;
    }

    /**
     * @ngdoc method
     * @description check if file type is image
     * @param {any} name
     */
    function isImage(name) {
      var ext, ValidImageTypes = ["gif", "jpeg", "png", "jpg"];
      ext = (name || '').toLowerCase().split('.').pop();
      return _.lastIndexOf(ValidImageTypes, ext) >= 0;
    }

    function getDefaultFroalaToolbars(options, isStandardMode) {
      var toolbarsButtons = [];
      var toolbars = {
        moreText: {
          buttons: _.concat(
            toolbarsButtons, [
            "bold",
            "italic",
            "underline",
            "align",
            "formatOL",
            "formatUL",
            "codeTag",
            "emoticons",
            "insertImage",
            "insertFile",
            "insertVideo",
            "insertLink",
            "insertTable",
            "embedly",
            "pageBreak"
          ]),
          align: 'left',
          buttonsVisible: 8 + toolbarsButtons.length
        }
      };
      if (options.enableFullscreen) {
        toolbars.moreText.buttons.splice(8, 0, "fullscreen");
        toolbars.moreText.buttonsVisible++;
      }
      if (!isStandardMode) {
        toolbars.moreText.buttons.splice(7, 0, "paragraphFormat");
        toolbars.moreText.buttons.splice(8, 0, "fontSize");
        toolbars.moreText.buttons.splice(9, 0, "textColor");
        toolbars.moreText.buttons.splice(10, 0, "quote");
        toolbars.moreText.buttonsVisible = 13;
        toolbars.colorsStep = 5;
        toolbars.colorsText = ['#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE', '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'];
      }
      return toolbars;
    }

    /**
     * @ngdoc method
     * @name getFroalaOptions
     * @description Return froala options
     * @param {any} options
     * @returns {Object}
     */
    function getFroalaOptions(options, isStandardMode, customToolbars) {
      var token = AuthServerProvider.getToken();
      var toolbars = customToolbars ? customToolbars : getDefaultFroalaToolbars(options, isStandardMode);
      var header = {};
      if (token && AuthServerProvider.hasValidToken()) {
        header = {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json'
        };
      }
      var orgId = options.isGuest ? _.get($rootScope.identity, 'guest.organization.id') : _.get(options, 'currentUser.default_organization.id', $rootScope.identity);
      var atOptions = {};
      options.mentionData = _.filter(options.mentionData, function (user) {
        return user && (user.status !== 'disabled');
      });
      if (options.mentionData) {
        var names = _.map(options.mentionData, function (user) {
          if (user.type === 'member') {
            var firstName = options.isGuest && user.full_name ? user.full_name.split(' ')[0] : user.first_name,
              lastName = options.isGuest && user.full_name ? user.full_name.split(' ')[1] : user.last_name;
            var name = {
              id: user.id,
              email: user.email,
              name: user.text,
              first_name: firstName,
              last_name: lastName,
              display_tpl: '<li><img class="atwho-avatar img" src="' + user.profile_pic + '"/><span class="atwho-display-text _600">' + firstName + ' ' + lastName + '</span></li>',
              insert_tpl: '<span class="atwho-el"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user.id + '"></span><img class="atwho-avatar img" src="' + user.profile_pic + '" /><span class="atwho-display-text _600">' + user.text + '</span><span data-ng-click="onAtwhoRemoveElement($event)" class="atwho-remove">x</span></span></span>'
            };
            if (user.profile_pic.indexOf('www.gravatar.com') >= 0 && !options.isGuest) {
              name.display_tpl = '<li><span class="atwho-avatar w-24 circle flex-justify text-u-c steel-dark text-white">' + firstName[0] + lastName[0] + '</span><span class="atwho-display-text _600">' + firstName + ' ' + lastName + '</span></li>';
              name.insert_tpl = '<span class="atwho-el"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user.id + '"></span><span class="flex-justify atwho-avatar w-24 circle text-u-c steel-dark text-white">' + firstName[0] + lastName[0] + '</span><span class="atwho-display-text _600">' + firstName + ' ' + lastName + '</span><span data-ng-click="onAtwhoRemoveElement($event)" class="atwho-remove">x</span></span></span>';
            }
          } else if (user.type === 'group') {
            var firstName = user.full_name;
            var name = {
              id: user.id,
              email: user.email,
              name: user.full_name,
              first_name: firstName,
              display_tpl: '<li class="d-flex"><i class="atwho-avatar avatar-icon v-c p-1 img fa fa-users"></i><span class="atwho-display-text _600">' + firstName + '</span></li>',
              insert_tpl: '<span class="atwho-el"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user.id + '"></span><i class="atwho-avatar avatar-icon v-c p-1 img fa fa-users"></i><span class="atwho-display-text _600">' + user.full_name + '</span><span data-ng-click="onAtwhoRemoveElement($event)" class="atwho-remove">x</span></span></span>'
            };
          } else if (user.type === 'guest') {
            var name = {
              id: user.email,
              email: user.email,
              name: user.email,
              first_name: user.email,
              display_tpl: '<li><span class="atwho-avatar w-24 circle flex-justify text-u-c steel-dark text-white">' + user.email[0] + user.email[1] + '</span><span class="atwho-display-text _600">' + user.email + '</span></li>',
              insert_tpl: '<span class="atwho-el"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + user.email + '"></span><span class="flex-justify atwho-avatar w-24 circle text-u-c steel-dark text-white">' + user.email[0] + user.email[1] + '</span><span class="atwho-display-text _600">' + user.email + '</span><span data-ng-click="onAtwhoRemoveElement($event)" class="atwho-remove">x</span></span></span>'
            };
          }
          return name;
        });

        atOptions = {
          data: names,
          displayTpl: '${display_tpl}',
          insertTpl: '${insert_tpl}',
          limit: _.get(options, 'limit')
        };
      }
      var froalaToolbars = isStandardMode ? (options.toolbar || toolbars) : toolbars;
      froalaToolbars.moreMisc = froalaToolbars.moreMisc || {};
      froalaToolbars.moreMisc.buttons = froalaToolbars.moreMisc.buttons || [];
      if (options.mentionData && options.mentionData.length && !options.isGuest) {
        froalaToolbars.moreMisc.buttons = _.concat(['atMentionButton'], froalaToolbars.moreMisc.buttons);
      }
      froalaToolbars.moreMisc.buttons = _.concat(['textTemplate'], froalaToolbars.moreMisc.buttons);
      froalaToolbars.moreMisc.align = 'right';
      froalaToolbars.moreMisc.buttonsVisible = 2;
      var frConfig = {
        accessibility: _.get(options, 'accessibility'),
        autofocus: _.get(options, 'autofocus', true),
        qAutoSave: _.get(options, 'qAutoSave'),
        allowEdit: _.get(options, 'allowEdit', true),
        contentChangedCallback: _.get(options, 'contentChangedCallback'),
        charCounterCount: false,
        codeMirror: window.CodeMirror,
        confirmBeforeBrowserRefresh: options.confirmBeforeBrowserRefresh || false,
        dragInline: false,
        embedlyKey: '77a4250e07dc47e3b413207c0bcd642f',
        emoticonsStep: 4,
        emoticonsSet: [{
          id: 'people',
          name: 'Smileys & People',
          code: '1f600',
          emoticons: [
            { code: '1f642', desc: ':-)' },
            { code: '1f609', desc: ';-)' },
            { code: '1f603', desc: ':-D' },
            { code: '1f617', desc: ':-X' },
            { code: '1f60b', desc: ':-P' },
            { code: '1f917', desc: '^A^' },
            { code: '1f60e', desc: 'B)' },
            { code: '1f914', desc: ':-o7' },

            { code: '1f644', desc: '*<|' },
            { code: '2639', desc: ':(' },
            { code: '1f610', desc: ':|' },
            { code: '1f631', desc: ':-o>' },
            { code: '1f635', desc: 'X|X' },
            { code: '1f61c', desc: ';-D' },
            { code: '1f60d', desc: '@-)' },
            { code: '1f913', desc: 'B-3' },

            { code: '1f44d', desc: '%|' },
            { code: '270a', desc: '%:|' },
            { code: '1f448', desc: '|O' },
            { code: '1f449', desc: 'O.o' },
            { code: '1f4a5', desc: '&#boom' },
            { code: '1f4a3', desc: '&#bomb' },
            { code: '2764', desc: '&#heart' },
            { code: '1f494', desc: '&#Xheart' },

            { code: '1f47d', desc: '&#alien' },
            { code: '1f47b', desc: '&#ghost' },
            { code: '1f648', desc: '^&^' },
            { code: '1f64a', desc: '^o^' },
            { code: '1f428', desc: '[:-B' },
            { code: '1f43c', desc: '[:-O' },
            { code: '2705', desc: '&#check' },
            { code: '1f6ab', desc: '#X#X' },

            { code: '1f4af', desc: '@100' },
            { code: '1f339', desc: '&#rose' },
            { code: '1f4b0', desc: '($)' },
            { code: '2709', desc: '&#mail' },
            { code: '1f4ce', desc: '&#clip' },
            { code: '1f396', desc: '#$#' },
            { code: '260e', desc: '#tel#' },
            { code: '1f4c8', desc: '#^^' }
          ]
        }],
        enter: FroalaEditor.ENTER_BR,
        entityType: options.entityType,
        fileEditButtons: ['fileOpen', 'fileEdit'],
        fileInsertButtons: ['fileBack', '|'],
        fullFeature: !isStandardMode,
        focusCallback: _.get(options, 'focusCallback'),
        heightMax: _.get(options, 'heightMax', 450),
        heightMin: options.heightMin || (isStandardMode ? 230 : 280),
        iconsTemplate: 'font_awesome_5',
        imageEditButtons: ['imageRemove', 'imageTUI'],
        imageInsertButtons: ['imageUpload'],
        imageAddNewLine: true,
        imageMaxSize: 100 * 1024 * 1024,
        imageManagerPageSize: 100,
        imageResize: true,
        imageUpload: true,
        imageUploadParam: 'name',
        imageUploadURL: _.get(options, 'isGuest', false)
          ? ENV_CONFIG.API_HOST + '/public/organizations/' + orgId + '/guests/' + _.get($rootScope.identity, 'guest.guest_code') + '/upload'
          : ENV_CONFIG.API_HOST + '/organizations/' + orgId + '/upload',
        initOnClick: _.get(options, 'initOnClick', false),
        inlineMode: false,
        isGuest: options.isGuest || false,
        fileMaxSize: 100 * 1024 * 1024,
        fileUploadParam: 'name',
        fileUploadURL: options.isGuest
          ? ENV_CONFIG.API_HOST + '/public/organizations/' + orgId + '/guests/' + _.get($rootScope.identity, 'guest.guest_code') + '/upload'
          : ENV_CONFIG.API_HOST + '/organizations/' + orgId + '/upload',
        hideHtmlToggle: options.hideHtmlToggle,
        isPublic: options.isPublic || ($rootScope.userState === USER_STATE.PUBLIC),
        key: 'TEB9iE6B5D5A4D3H3C8aIVLEABVAYFKc1Ce1MYGD1c1NYVMiB3B9B6B4C2C4B3H3G3F3==',
        lineBreakerOffset: 50,
        lineBreakerTags: ['table', 'hr', 'form', 'dl', 'br', 'span.fr-video', '.fr-embedly', '.fr-img-caption'],
        lineHeights: {
          '1': '1',
          '1.15': '1.15',
          '1.5': '1.5',
          '2': '2'
        },
        linkAutoPrefix: [],
        linkAlwaysBlank: true,
        linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
        linkInsertButtons: [],
        atOptions: atOptions,
        multiline: true,
        placeholderText: _.get(options, 'placeholder'),
        pasteAllowedStyleProps: [],
        pasteDeniedAttrs: [
          // w3schools attribute reference
          'accept',
          'accept-charset',
          'accesskey',
          'action',
          'align',
          'alt',
          'async',
          'autocomplete',
          'autofocus',
          'autoplay',
          'bgcolor',
          'border',
          'charset',
          'checked',
          'cite',
          //'class',
          'color',
          'cols',
          'colspan',
          'content',
          'contenteditable',
          'controls',
          'coords',
          'data',
          'data-*',
          'datetime',
          'default',
          'defer',
          'dir',
          'dirname',
          'disabled',
          'download',
          'draggable',
          'enctype',
          'for',
          'form',
          'formaction',
          'headers',
          'height',
          'hidden',
          'high',
          //'href',
          'hreflang',
          'http-equiv',
          'id',
          'ismap',
          'kind',
          'label',
          'lang',
          'list',
          'loop',
          'low',
          'max',
          'maxlength',
          'media',
          'method',
          'min',
          'multiple',
          'muted',
          'name',
          'novalidate',
          'onabort',
          'onafterprint',
          'onbeforeprint',
          'onbeforeunload',
          'onblur',
          'oncanplay',
          'oncanplaythrough',
          'onchange',
          'onclick',
          'oncontextmenu',
          'oncopy',
          'oncuechange',
          'oncut',
          'ondblclick',
          'ondrag',
          'ondragend',
          'ondragenter',
          'ondragleave',
          'ondragover',
          'ondragstart',
          'ondrop',
          'ondurationchange',
          'onemptied',
          'onended',
          'onerror',
          'onfocus',
          'onhashchange',
          'oninput',
          'oninvalid',
          'onkeydown',
          'onkeypress',
          'onkeyup',
          'onload',
          'onloadeddata',
          'onloadedmetadata',
          'onloadstart',
          'onmousedown',
          'onmousemove',
          'onmouseout',
          'onmouseover',
          'onmouseup',
          'onmousewheel',
          'onoffline',
          'ononline',
          'onpagehide',
          'onpageshow',
          'onpaste',
          'onpause',
          'onplay',
          'onplaying',
          'onpopstate',
          'onprogress',
          'onratechange',
          'onreset',
          'onresize',
          'onscroll',
          'onsearch',
          'onseeked',
          'onseeking',
          'onselect',
          'onstalled',
          'onstorage',
          'onsubmit',
          'onsuspend',
          'ontimeupdate',
          'ontoggle',
          'onunload',
          'onvolumechange',
          'onwaiting',
          'onwheel',
          'open',
          'optimum',
          'pattern',
          'placeholder',
          'poster',
          'preload',
          'readonly',
          'rel',
          'required',
          'reversed',
          'rows',
          'rowspan',
          'sandbox',
          'scope',
          'selected',
          'shape',
          'size',
          'sizes',
          'span',
          'spellcheck',
          'src',
          'srcdoc',
          'srclang',
          'srcset',
          'start',
          'step',
          'style',
          'tabindex',
          'target',
          'title',
          'translate',
          'type',
          'usemap',
          'value',
          'width',
          'wrap'
        ],
        pasteDeniedTags: [],
        pastePlain: true,
        pluginsEnabled: [
          'align',
          'codeBeautifier',
          'codeView',
          'colors',
          'draggable',
          'embedly',
          'emoticons',
          'entities',
          'file',
          'fontAwesome',
          'fontFamily',
          'fontSize',
          'fullscreen',
          'image',
          'imageTUI',
          'imageManager',
          'inlineStyle',
          'inlineClass',
          'insertVariable',
          'insertVariableStarter',
          'lineBreaker',
          'lineHeight',
          'link',
          'lists',
          'paragraphFormat',
          'paragraphStyle',
          'quote',
          'save',
          'table',
          'url',
          'video',
          'wordPaste'
        ],
        quickInsertButtons: ['image', 'video', 'embedly', 'ul', 'ol', 'hr'],
        quickInsertTags: [''],
        requestHeaders: header,
        scaytAutoload: false,
        spellcheck: !!options.spellcheck,
        tableCellMultipleStyles: true,
        tableEditButtons: [
          'tableHeader',
          'tableRows',
          'tableColumns',
          'tableCellHorizontalAlign',
          'tableCellStyle',
          'tableStyle',
          'tableCells',
          'tableRemove'
        ],
        tableOfContentElements: _.get(options, 'tableOfContentElements', void 0),
        tableResizerOffset: 10,
        tableResizingLimit: 50,
        toolbarButtons: froalaToolbars,
        toolbarButtonsMD: froalaToolbars,
        toolbarButtonsSM: froalaToolbars,
        toolbarButtonsXS: froalaToolbars,
        toolbarBottom: false,
        toolbarSticky: true,
        toolbarStickyOffset: 50,
        videoAllowedTypes: ['mp4', 'webm', 'ogg', 'quicktime'],
        videoInsertButtons: ['videoByURL', 'videoUpload'],
        videoEditButtons: ['videoRemove'],
        videoMaxSize: 100 * 1024 * 1024,
        videoUpload: true,
        videoUploadMethod: 'POST',
        videoUploadURL: options.isGuest
          ? ENV_CONFIG.API_HOST + '/public/organizations/' + orgId + '/guests/' + _.get($rootScope.identity, 'guest.guest_code') + '/upload'
          : ENV_CONFIG.API_HOST + '/organizations/' + orgId + '/upload',
        videoUploadParam: 'name',
        videoResize: false,
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        type: _.get(options, 'type', 'default')
      };

      if (!(options.isGuest || frConfig.isPublic) && !_.get(options, 'hideTemplatePlugin')) {
        frConfig.pluginsEnabled.push('textTemplate');
        frConfig.pluginsEnabled.push('codeView');
      }
      return frConfig;
    }

    function bytesToSize(bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return 'n/a';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      if (i === 0) return bytes + ' ' + sizes[i];
      return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    }

    /**
     * @ngMethod
     * @name getFileClassName
     * @description get class name for specific file type
     * @param filename
     * @returns {string}
     */
    function getFileClassName(filename) {
      var extension = filename.split('.').pop();
      return classMap[extension] || 'fa-file';
    }

    /**
     * @ngdoc method
     * @name copyToClipboard
     * @description Clipboard callback method when alias copied
     */
    function copyToClipboard(copiedText, type, $event, param, growlReference) {
      if ($event) {
        $event.stopPropagation();
      }
      clipboard.copyText(copiedText);
      var message = $filter('translate')('global.growlMessages.copied.' + type);
      if (param) {
        message = $filter('translate')('global.growlMessages.copied.message', { type: param });
      }
      growl.success(message, { referenceId: growlReference || 'global', disableIcons: true, disableCloseButton: true });
    }

    /**
     * @ngdoc method
     * @name getCardTypes
     * @description Get all card types images
     * @returns {Object}
     */
    function getCardTypes() {
      return {
        'unknown': '/assets/images/creditcard/default-deselected.svg',
        'american_express': '/assets/images/creditcard/american-selected.svg',
        'master': '/assets/images/creditcard/master-selected.svg',
        'visa': '/assets/images/creditcard/visa-selected.svg'
      };
    }

    /**
     * @ngdoc method
     * @name getFileLocation
     * @description get file location 
     * @private 
     * @param {object} file
     * @param {string} org
     */
    function getFileLocation(file, org) {
      return org ? ENV_CONFIG.FILES_HOST + '/organizations/' + org.id + '/file/' + file.id + '/dl' : ENV_CONFIG.API_HOST + '/' + file.url;
    }

    /**
     * @ngdoc method
     * @name clearUniversalAlert
     * @description Clear growl messages based on reference id
     * @param {String} referenceId
     */
    function clearUniversalAlert(referenceId) {
      var messages = Growl.getAllMessages(referenceId);
      if (messages.length > 0) {
        Growl.clearAllMessages(referenceId);
      }
    }

    /**
     * @ngdoc method
     * @name guid
     * @returns {string} guid string
     * 
     * @description
     * method to create GUID (use this method to create a unique ID at the same time)
     * 
     * reference URL: http://guid.us/GUID/JavaScript
     */
    function guid() {
      /**
       * private guid method helper
       */
      function s4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      return (s4() + s4() + "-" + s4() + "-4" + s4().substr(0, 3) + "-" + s4() + "-" + s4() + s4() + s4()).toLowerCase();
    }

    /**
     * @ngdoc method
     * @name getId
     * @description To generate string ID
     * @return {String} Random string
     */
    function getId() {
      return (Math.random() + 1).toString(36).substring(7);
    }

    /**
     * @ngdoc method
     * @name showCalendlyModal
     * @description Helper method to show calendly modal
     */
    function showCalendlyModal() {
      modal.show({
        templateUrl: 'app/modules/process/components/edit/help.html',
        windowClass: 'black-overlay modal-fade fade',
        backdrop: false
      });
    }

    /**
     * @ngdoc method
     * public
     * @param {*} value 
     * 
     * @description
     * checking for object isEmpty or undefined for all type of object
     */
    function isObjectEmpty(value) {
      return ((typeof value !== 'number') && (_.isUndefined(value) || _.isEmpty(value)));
    }

    /**
     * @function
     * @name checkAccessAuthority
     * @param {Boolean} openModal
     * @description check authority for perform
     * any edit or create action
     */
    function checkAccessAuthority(openModal) {
      openModal = _.isUndefined(openModal);
      var currentOrg = Principal.getCurrentOrganization();
      if (AuthPlan.getCurrentPlanCode() === PLANS.FREE && !currentOrg.in_trial && currentOrg.plan_code) {
        if (openModal)
          showAccessDeniedModal();
        return false;
      }
      return true;
    }

    /**
     * @function
     * @name openAccessDeniedModal
     * @description open edit run name modal
     */
    function showAccessDeniedModal() {
      modalInstance = $uibModal.open({
        templateUrl: 'app/components/trialEndedModal/trialEndedModal.html',
        windowClass: 'modal-trial',
        controller: function ($rootScope) {
          var $ctrl = this;
          $ctrl.orgId = $rootScope.identity.organizations.id;
          $ctrl.cancelOrgUrl = WUFOO_URL.URL + $ctrl.orgId;
          this.closeModal = function () {
            if ($state.current.name === 'process.create') {
              $state.go('process.templates');
            }
            modalInstance.close();
          };
          this.openAskQuestion = function () {
            BeaconService.open();
            this.closeModal();
          };
        },
        controllerAs: '$ctrl'
      });
    }

    /**
     * @function
     * @name getDiffInDays
     * @description Get difference between created date and today's date in a day format
     * @param {Date} createdAt
     * @returns {Number}
     */
    function getDiffInDays(createdAt) {
      if (createdAt < CONFIG.CUTOFF_DATE) {
        createdAt = CONFIG.CUTOFF_DATE;
      }
      return Math.ceil((Math.abs(moment.utc().toDate().getTime() - moment.utc(createdAt).toDate().getTime())) / (1000 * 3600 * 24));
    }

    /**
     * @function
     * @name getTrialEndDate
     * @description Get trial end date
     * @param {Date} createdAt
     * @param {Integer} freeTrialDays
     * @returns {unresolved}
     */
    function getTrialEndDate(createdAt, freeTrialDays) {
      var dateFormat = OrganizationsService.getDateFormat();
      if (createdAt < CONFIG.CUTOFF_DATE) {
        createdAt = CONFIG.CUTOFF_DATE;
      }
      return DateUtils.toLocal(moment.utc(createdAt).toDate().setDate(moment.utc(createdAt).toDate().getDate() + freeTrialDays)).format(dateFormat);
    }

    /**
     * @ngdoc method
     * @name getDateDiffInHours
     * @param {*} date 
     * 
     * @description get date / time difference in hours
     */
    function getDateDiffInHours(date) {
      var dateStart = DateUtils.toLocal(moment.utc());
      var dateEnd = DateUtils.toLocal(date);
      return Math.abs(parseInt(moment.duration(dateEnd.diff(dateStart)).asHours()));
    }

    /**
     * @ngdoc method
     * @name getDateDiffInMinutes
     * @param {*} date 
     * 
     * @description get date / time difference in minutes
     */
    function getDateDiffInMinutes(date) {
      var dateStart = DateUtils.toLocal(moment.utc());
      var dateEnd = DateUtils.toLocal(date);
      return Math.abs(parseInt(moment.duration(dateEnd.diff(dateStart)).asMinutes()));
    }

    /**
     * @ngdoc method
     * @name isMasquarade
     * @description return admin id if masquarade
     * @returns {*}
     */
    function isMasquarade() {
      return ($localStorage.adminID);
    }

    function setOrgGoogleAnalyticsTracker(orgId, gaId, user) {
      var isTracked = document.getElementById("org_gaId-" + orgId);
      if (!isTracked) {
        var newScript = document.createElement("script");
        newScript.id = "org_gaId-" + orgId;
        newScript.appendChild(document.createTextNode('ga("create","' + gaId + '", "auto", "orgTracker");ga("orgTracker.send", "pageview");'));
        $document[0].body.appendChild(newScript);
        if (_.isArray($analytics.settings.ga.additionalAccountNames)) {
          var orgGaTrackerIndex = $analytics.settings.ga.additionalAccountNames.indexOf('orgTracker');
          if (orgGaTrackerIndex < 0) {
            $window.ga('orgTracker.set', 'userId', user.email.toLowerCase());
            $analytics.settings.ga.additionalAccountNames.push('orgTracker');
          }
        } else {
          $analytics.settings.ga.additionalAccountNames = ['orgTracker'];
        }
      }
    }

    function removeOrgGoogleAnalyticsTracker(orgId) {
      var orgGaTracker = document.getElementById("org_gaId-" + orgId);
      if (orgGaTracker) {
        orgGaTracker.parentNode.removeChild(orgGaTracker);
        if (!_.isArray($analytics.settings.ga.additionalAccountNames)) {
          $analytics.settings.ga.additionalAccountNames = [$analytics.settings.ga.additionalAccountNames];
        }
        var orgGaTrackerIndex = $analytics.settings.ga.additionalAccountNames.indexOf('orgTracker');
        if (orgGaTrackerIndex > -1) {
          $analytics.settings.ga.additionalAccountNames.splice(orgGaTrackerIndex, 1);
        }
      }
    }

    function createNewOrg() {
      AccountService.me().then(function (response) {
        var user = _.get(response, 'data');
        Principal.authenticate(user);
        Principal.setAuthoritiesAndAuthenticated();
        $state.go('organizations.create', { allow_new_creation: user.id }, { inherit: false });
      });
    }

    /**
     * @function
     * @name openAccountDisabledModal
     * @description Open Account Disabled Modal
     */
    function openAccountDisabledModal() {
      if (accountDisabledModalInstance) {
        return;
      }
      accountDisabledModalInstance = $uibModal.open({
        templateUrl: 'app/components/modals/accountDisabledModal/accountDisabledModal.html',
        keyboard: false,
        backdrop: 'static',
        controller: function () {
          var this_parent = this;
          this.createNewOrg = function () {
            this_parent.isRedirectingToCreate = true;
            AccountService.me().then(function (response) {
              var user = _.get(response, 'data');
              //set user identity
              Principal.authenticate(user);
              Principal.setAuthoritiesAndAuthenticated();
              this_parent.isRedirectingToCreate = false;
              $state.go('organizations.create', { allow_new_creation: user.id }, { inherit: false });
            }, function () {
              this_parent.isRedirectingToCreate = false;
            });
          };
          this.close = function () {
            this.onLoading = true;
            closeAccountDisabledModal();
          };
        },
        controllerAs: '$ctrl'
      });
    }

    /**
     * @function
     * @name closeVisitingModal
     * @description Close visiting modal
     */
    function closeAccountDisabledModal() {
      Auth.logout().then(function () { }, function () { }).finally(function () {
        accountDisabledModalInstance.close();
        $state.go('authHandler', null, { inherit: false });
      });
    }

    function downloadAsset(file, saveLocally, imageCacheObject, orgId, isGuest) {
      if (saveLocally && file.source === 'url') {
        window.open(file.url, '_blank');
      } else {
        if (saveLocally && imageCacheObject[file.id]) {
          saveToLocal(file.filename, imageCacheObject[file.id]);
        } else {
          FilesService.downloadFile({
            org: orgId,
            fileId: file.id
          }, isGuest).then(function (response) {
            var blobUrl = getFileObjectUrl(response.data, response.contentType),
              isImageFile = isImage(file.source === 'url' ? file.url : file.filename) && !file.isTemp;
            if (isImageFile) {
              imageCacheObject[file.id] = blobUrl;
            }
            if (saveLocally) {
              saveToLocal(file.filename, blobUrl);
            }
          });
        }
      }
    }

    function saveToLocal(filename, url) {
      var tempLink = $document[0].createElement('a');
      tempLink.href = url;
      tempLink.download = filename.replaceAll("[^a-zA-Z0-9 .]|(?<!\\d)[.]|[.](?!\\d)", "_");
      $document[0].body.appendChild(tempLink);
      tempLink.click();
      $document[0].body.removeChild(tempLink);
    }

    function isJson(string) {
      try {
        JSON.parse(string);
      } catch (e) {
        return false;
      }
      return true;
    }

    function removeScriptTagOnString(text) {
      return angular.element('<div>').text(text).html();
    }

    function isMemberPresentInGroup(member, assignedGroups, allGroups, displayEmail, displayErrorMessage) {
      if (!member || !_.get(allGroups, 'length', 0)) {
        return false;
      }
      var isMemberPresentInGroup = false;
      _.each(assignedGroups, function (group) {
        var groupBaseObj = _.find(allGroups, { id: group.id || group }), idx = _.indexOf(groupBaseObj.users || groupBaseObj.members, member.id);
        if (idx > -1) {
          isMemberPresentInGroup = true;
          if (displayErrorMessage) {
            $timeout(function () {
              Growl.clearAllMessages('global');
              growl.error($filter('translate')('global.task.messages.memberAlreadyPresentInGroup', { memberName: displayEmail ? member.email + ' (' + member.text + ')' : member.text, groupName: groupBaseObj.name }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            }, 100);
          }
          return false;
        }
      });
      return isMemberPresentInGroup;
    }

    function getCurrentCaretPositionOnWindow() {
      var range = document.getSelection().getRangeAt(0),
        node = range.startContainer, offset = range.startOffset, rect, range2;
      if (offset > 0) {
        range2 = document.createRange();
        range2.setStart(node, (offset - 1));
        range2.setEnd(node, offset);
        rect = range2.getBoundingClientRect();
        return {
          left: rect.right + 8,
          top: rect.bottom - 32
        };
      } else {
        rect = node.getBoundingClientRect();
        return {
          left: rect.x + 24,
          top: rect.y + 8
        };
      }
    }

    function getRandomString(length) {
      var result = '', charList = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (var i = 0; i < length; i++) {
        result += charList.charAt(Math.floor(Math.random() * charList.length));
      }
      return result;
    }

    function isMobileDevice() {
      var agent = navigator.userAgent || navigator.vendor || window.opera;
      return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(agent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(agent.substr(0, 4));
    }

    function getMemberPrefixTranslation() {
      return $rootScope.identity && ($rootScope.identity.last_known_country == 'US' || $rootScope.identity.last_known_country == 'CA') ? 'Co' : '';
    }

    function getHtmlTextContent(htmlString) {
      var tempEl = angular.element('<div>').html(htmlString);
      return tempEl.text();
    }

    function getAppExplanationItems() {
      return [
        {
          id: 'home',
          stateCheck: 'home',
          iconClasses: 'fa fa-check-square',
          titleTranslationStr: 'app_explanation.tasks',
          infoTranslationStr: 'app_explanation.tasksInfo'
        },
        {
          id: 'run_dashboard',
          stateCheck: 'run.dashboard',
          iconClasses: 'fa fa-th-large',
          titleTranslationStr: 'app_explanation.tracker',
          infoTranslationStr: 'app_explanation.trackerInfo'
        },
        {
          id: 'process_templates',
          stateCheck: 'process.templates',
          iconClasses: 'fas fa-cabinet-filing',
          titleTranslationStr: 'app_explanation.library',
          infoTranslationStr: 'app_explanation.blueprintInfo'
        }
      ];
    }

    function showChangesSavedGrowl() {
      Growl.clearAllMessages('global');
      $timeout(function () {
        growl.info($filter('translate')('global.messages.changesSaved'), {
          referenceId: 'global',
          disableIcons: true,
          disableCloseButton: true,
          ttl: 3000
        });
      }, 250);
    }

    function isNewGuestAddedProcessAndReturn(assignedGuests) {
      assignedGuests = assignedGuests || [];
      var defer = $q.defer();
      if (!assignedGuests.length || ($rootScope.userState !== USER_STATE.MEMBER)) {
        defer.resolve({ isNewGuestAdded: false });
      } else {
        store.getLightweightGuests().then(function (response) {
          var allGuests = response, isNewGuestAdded = false;
          for (var i = 0; i < assignedGuests.length; i++) {
            var findGuest = _.find(allGuests, { email: assignedGuests[i] });
            if (!findGuest) {
              isNewGuestAdded = true;
              break;
            }
          }

          if (isNewGuestAdded) {
            store.clearGuestsStore();
            store.getLightweightGuests().then(function (res) {
              defer.resolve({ isNewGuestAdded: true, updatedGuests: res });
            });
          } else {
            defer.resolve({ isNewGuestAdded: false });
          }
        }, function () {
          defer.resolve({ isNewGuestAdded: false });
        });
      }
      return defer.promise;
    }

    function isGuestPresentInGroup(guest, assignedGroups, allGroups, displayEmail, displayErrorMessage) {
      if (!_.get(allGroups, 'length', 0)) {
        return false;
      }
      var isGuestPresentInGroup = false;
      _.each(assignedGroups, function (group) {
        var groupBaseObj = _.find(allGroups, { id: group.id || group });
        var idx = _.indexOf(groupBaseObj.guests, guest.email);
        if (idx > -1) {
          isGuestPresentInGroup = true;
          if (displayErrorMessage) {
            $timeout(function () {
              Growl.clearAllMessages('global');
              growl.error($filter('translate')('global.task.messages.memberAlreadyPresentInGroup', { memberName: guest.email, groupName: groupBaseObj.name }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            }, 100);
          }

          return false;
        }
      });
      return isGuestPresentInGroup;
    }

    function updateAssigneesOnGroupAddition(assigneeList, addedGroup, allGroups, shouldDisplayUA) {
      var assigneeListCopy = assigneeList ? angular.copy(assigneeList) : [], finalAssigneeList = [], removedAssigneeList = [];
      _.forEach(assigneeListCopy, function (item) {
        if (item.type === 'member') {
          if (!isMemberPresentInGroup(item, [addedGroup], allGroups, false, false)) {
            finalAssigneeList.push(item);
          } else {
            removedAssigneeList.push(item.full_name);
          }
        } else if (item.type === 'guest') {
          if (!isGuestPresentInGroup(item, [addedGroup], allGroups, false, false)) {
            finalAssigneeList.push(item);
          } else {
            removedAssigneeList.push(item.email);
          }
        } else {
          finalAssigneeList.push(item);
        }
      });
      if (removedAssigneeList.length && shouldDisplayUA) {
        var removedAssigneesNames = removedAssigneeList.join(", ");
        $timeout(function () {
          Growl.clearAllMessages('global');
          growl.info($filter('translate')('global.task.messages.membersRemovedAsAlreadyPresentInGroup', { membersNames: removedAssigneesNames, groupName: addedGroup.name }), {
            referenceId: 'global',
            disableIcons: true,
            disableCloseButton: true
          });
        }, 100);
      }
      return finalAssigneeList;
    }

    function lowercaseKeys(obj) {
      var output = {};
      for (var i in obj) {
        var current = obj[i];
        if (_.isObject(current) || _.isArray(current)) {
          output[i.toLowerCase()] = lowercaseKeys(current);
        } else {
          output[i.toLowerCase()] = current;
        }
      }
      return output;
    }

    function getFilteredUsers(activeUsers, usersArr) {
      var filteredUsers = [], usersArrFinal = usersArr || [];
      for (var i = 0; i < usersArrFinal.length; i++) {
        var index = _.findIndex(activeUsers, { 'id': usersArr[i] });
        if (index > -1) {
          filteredUsers.push(usersArr[i]);
        }
      }
      return filteredUsers;
    }

    /**
     * @function
     * @name discardDisabledUsersFromArr
     * @param {Array} usersArr
     * @description Array param consists of userIDs, need to remove the inactive / disabled ones from the array
     */
    function discardDisabledUsersFromArr(usersArr) {
      var defer = $q.defer();
      if (!_.get(usersArr, 'length', 0)) {
        defer.resolve([]);
      } else {
        store.getUsers().then(function (res) {
          var activeUsers = UsersService.getBilledUsers(res),
            filteredUsers = getFilteredUsers(activeUsers, usersArr);
          if (!filteredUsers.length) {
            filteredUsers.push($rootScope.identity.id);
          }
          defer.resolve(filteredUsers);
        });
      }
      return defer.promise;
    }
  }
})();
