(function () {
  'use strict';
  angular.module('tallyfy')
    .component('compactKoFormTaskDetail', {
      templateUrl: 'app/components/compact/components/koFormTaskDetail/compact-ko-form-task-detail.component.html',
      bindings: {
        process: '<',
        usersInOrg: '<',
        orgGroups: '<',
        metadata: '=?',
        isGuest: '<',
        isPublic: '<',
        isPublicProcess: '<',
        focusField: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $stateParams, $timeout, $analytics, RunsService, CompactTaskService, FieldService, DOMService, Helper, USER_STATE, moment, DESCRIPTIONSIZE, COMMON) {
          var $ctrl = this;

          $ctrl.isOpenPopOver = false;
          $ctrl.disableCompletion = false;
          $ctrl.maxLength = DESCRIPTIONSIZE.taskStepTitle;
          $ctrl.tooltipLimit = COMMON.TITLE_TOOLTIP_LIMIT;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onCaptureUpdated = onCaptureUpdated;
          $ctrl.onCompleteTaskClick = onCompleteTaskClick;
          $ctrl.haveAuthority = Helper.checkAccessAuthority;
          $ctrl.onPaneClose = onPaneClose;

          function onInit() {
            $ctrl.isPublicProcess = $rootScope.userState === USER_STATE.PUBLIC_PROCESS;
            var focusField = _.get($ctrl.metadata, 'item.focusField');
            if (focusField || $stateParams.focusField) {
              var alias = $stateParams.focusField || focusField.alias;
              $timeout(function () {
                var koFormField = _.find(_.get($ctrl.process, 'ko_form_fields.data', []), { alias: alias });
                if (koFormField) {
                  DOMService.centerObjectToView('[data-field-alias=\'' + alias + '\']');
                }
              }, 350);
            }
          }

          function onChanges() { }

          function onDestroy() { }

          function onCompleteTaskClick() {
            if ($ctrl.disableCompletion) {
              return;
            }
            $ctrl.formSubmitted = true;
            var preruns = CompactTaskService.processPreruns($ctrl.process),
              isHasEmptyRequiredFields = CompactTaskService.validateCaptures(preruns),
              isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded(preruns);
            if (isHasExceededTextChars || isHasEmptyRequiredFields) {
              $rootScope.$emit('COMPACT:FORM_VALIDATION_ERROR');
            } else {
              $ctrl.process.prerun_status === 'complete' ? inCompleteTask() : completeTask();
            }
          }

          function inCompleteTask() {
            $ctrl.onSaving = true;
            CompactTaskService.koTaskReOpen($ctrl.process)
              .then(function (res) {
                angular.extend($ctrl.process, res.data);
                $rootScope.$emit('KICK_OFF_FORM:UPDATED_STATUS');
                trackEvent('KO task re-opened', {});
                $ctrl.onSaving = false;
              }, function () {
                $ctrl.onSaving = false;
              });
          }

          function completeTask() {
            $ctrl.onSaving = true;
            CompactTaskService.koTaskComplete($ctrl.process)
              .then(function (res) {
                angular.extend($ctrl.process, res.data);
                $rootScope.$emit('KICK_OFF_FORM:UPDATED_STATUS');
                trackEvent('KO task completed', {});
                $ctrl.onSaving = false;
              }, function () {
                $ctrl.onSaving = false;
              });
          }

          function onCaptureUpdated(field) {
            $ctrl.process.prerun[field.id] = FieldService.getFieldValue(field);
            var isHasEmptyRequiredFields = CompactTaskService.validateCaptures([field]), isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded([field]);
            if (isHasExceededTextChars || isHasEmptyRequiredFields) {
              $rootScope.$emit('COMPACT:FORM_VALIDATION_ERROR');
              return;
            }
            RunsService.update({ id: $ctrl.process.id }, { prerun: mapProcessPrerunValue() })
              .then(function (res) {
                angular.extend($ctrl.process.prerun, res.data.prerun);
                $rootScope.$broadcast('CAPTURE:UPDATE', { updatedTask: 'ko-form', updatedCapture: field });
                $rootScope.$broadcast('CAPTURE:UPDATE' + field.alias, { updatedTask: 'ko-form', updatedCapture: field });
              });
          }

          function mapProcessPrerunValue() {
            var prerun = {};
            for (var key in $ctrl.process.prerun) {
              var data = _.find($ctrl.process.ko_form_fields.data, { id: key }) || {};
              if (data.field_type === 'table') {
                var value = $ctrl.process.prerun[key];
                if (value && value.length) {
                  if (Helper.isObjectEmpty(prerun[key])) {
                    prerun[key] = [];
                  }
                  for (var i = 0; i < value.length; i++) {
                    prerun[key].push(
                      !Helper.isObjectEmpty(value) && !Helper.isObjectEmpty(value[i]) && value[i].id
                        ? value[i]
                        : {
                          id: data.id,
                          label: data.label,
                          value: value[i]
                        }
                    );
                  }
                }
              } else {
                prerun[key] = $ctrl.process.prerun[key];
              }
            }
            return prerun;
          }

          function trackEvent(eventName, properties) {
            properties.type = 'process';
            properties.processId = $ctrl.process ? $ctrl.process.id : void 0;
            $analytics.eventTrack(eventName, properties);
          }

          /**
           * @ngdoc method
           * @name onPaneClose
           * @param {*} e 
           * 
           * @description
           * on right pane method handler
           */
          function onPaneClose(e) {
            e.preventDefault();
            $rootScope.$emit('RIGHT_PANE:CLOSE', { event: e });
            $rootScope.$emit('KO_TASK:CLOSE');
          }
        }
    });
})();