/**
 * @ngdoc Component
 * @name tallyfy.organizations.component.updateOrgPlan
 * @module tallyfy.organizations
 *
 * @description
 * A component for upgrade organization plan.
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.organizations')
    .component('updateOrgPlan', {
      templateUrl: [
        '$attrs',
        function ($attrs) {
          var layoutType = $attrs.layout || 'updateOrgPlan';
          return 'app/modules/organizations/updateOrgPlan/'+layoutType+'.html';
        }
      ],
      controller:
        /*@ngInject*/
        function (_, $rootScope, Auth, blockUI, UsersService, SupportService, $state, $filter, CONFIG, PLANS, $timeout, ENV_CONFIG, OrganizationsService, $analytics, store, $q, AuthPlan, $localStorage, OrganizationsRepository, $sce, Helper, UtilsService, VALIDATION) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('updateOrgPlan');

          /**
           * public properties
          */
          $ctrl.upgradePlanForm = {};
          $ctrl.user = {};
          $ctrl.allPlans = [];
          $ctrl.validCouponCode = false;
          $ctrl.notValidCouponCode = false;
          $ctrl.hidePaymentInfo = false;
          
          $ctrl.plans = AuthPlan.getPlans();
          $ctrl.selectedPlanPeriod = "annual";
          $ctrl.planPeriod = { "annual": "Pay Annually", "monthly": "Pay Monthly" };
          $ctrl.orgUser = 1;
          $ctrl.currentSubscription = {};
          $ctrl.maxLength = VALIDATION.FN_LN_MAX_LENGTH;
          $ctrl.lightUsers = [];
          $ctrl.otherUsers = [];

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
          /**
           * public method's
          */
          $ctrl.changePlanName = changePlanName;
          $ctrl.updatePlan = updatePlan;
          $ctrl.setPlanAsActive = setPlanAsActive;
          $ctrl.setPlanAsInactive = setPlanAsInactive;
          $ctrl.applyCouponCode = applyCouponCode;
          $ctrl.setDefualtValue = setDefualtValue;
          $ctrl.calculatePlanCost = calculatePlanCost;
          $ctrl.continueWithPurchase = continueWithPurchase;
          $ctrl.updatePlanApply = updatePlanApply;
          $ctrl.getMinimumUser = getMinimumUser;
          $ctrl.copyLink = copyLink;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            trackEvent('PLAN AND PAYMENT - PAGE VIEWED');
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.organization = _.get($rootScope.identity, 'default_organization');
            $ctrl.currentSubscription = _.get($rootScope.identity, 'default_organization.subscription.data');
            $ctrl.recurlyBillingLink = _.get( $ctrl.currentSubscription, 'billing_manage_url');
            $ctrl.isMinMemberBillingEnabled = !_.get($rootScope.identity, 'organizations.disable_min_members_billing', true);
            setChangedPlanDetails(0);
            $ctrl.user = getGeneralInfo($rootScope.identity);
            UtilsService.getCountriesData().then(function(response) {
              if (response) {
                $ctrl.country = _.find(response, { name: $ctrl.organization.country });
              }
            });
            $ctrl.htmlPopoverWebhookHelpText = $sce.trustAsHtml($filter('translate')('payment.label.couponHelpText'));
            loadDatas();
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name copyLink
           * @private
           * @description copy link
           */
          function copyLink($event) {
            Helper.copyToClipboard($ctrl.recurlyBillingLink, 'link', $event);
          }

          /**
           * @ngdoc method
           * @name loadDatas
           * @private
           * @description Get user and plans
           */
          function loadDatas() {
            blockUI.start();
            $q.all([
              store.getUsers(),
              Auth.getPlans()
            ]).then(function (response) {
              $ctrl.orgUser = $ctrl.currentSubscription.light_plan ? $ctrl.currentSubscription.quantity : UsersService.getBilledUsers(response[0]).length;
              $ctrl.lightUsers = _.filter(UsersService.getBilledUsers(response[0]), { role: "light" });
              $ctrl.otherUsers = _.filter(UsersService.getBilledUsers(response[0]), function(r) { return r.role !== "light"; });
              $ctrl.totalUsersCount = ($ctrl.otherUsers.length+$ctrl.lightUsers.length);
              if (!_.isUndefined(response[1])) {
                $ctrl.allPlans = response[1];
              }
              $ctrl.user.company_name = $ctrl.organization.name;
              $ctrl.availablePlans = _.reverse(setAvailablePlans());
              $ctrl.selectedPlan = _.head($ctrl.availablePlans);
              $ctrl.totalCost = (_.get($ctrl.selectedPlan, 'cost', 0) * $ctrl.otherUsers.length) + ($ctrl.lightUsers.length * _.get($ctrl.selectedPlan, 'light_member_cost', 0));
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name setAvailablePlans
           * @private
           * @description Set some property in plan object
           * @returns {object} availablePlans
          */
          function setAvailablePlans() {
            var availablePlans = [], period;
            _.forEach($ctrl.allPlans.PUBLIC_PRICING_PLANS, function (plan, key) {
              if (_.startsWith(plan.code, $ctrl.planName)) {
                plan.active = false;
                period = _.last(_.split(plan.code, '-'));
                plan.annualCost = plan.cost;
                if (plan.code === PLANS.PROANNUAL) {
                  plan.annualCost = PLANS.PRO_ANNUAL_PRICE;
                  plan.minUsers = CONFIG.PRO_MIN_USERS;
                }
                if (plan.code === PLANS.BASICANNUAL) {
                  plan.annualCost = PLANS.BASIC_ANNUAL_PRICE;
                  plan.minUsers = CONFIG.BASIC_MIN_USERS;
                }
                if (plan.code === PLANS.DOCSANNUAL) {
                  plan.annualCost = PLANS.DOCS_ANNUAL_PRICE;
                  plan.minUsers = CONFIG.DOCS_MIN_USERS;
                }
                setMinUsersOfPlan(plan);
                if (_.endsWith(plan.code, $ctrl.selectedPlanPeriod)) {
                  $ctrl.setPlanAsActive(plan);
                }
                plan.period = $ctrl.planPeriod[period];
                availablePlans.push(plan);
              }
            });
            return availablePlans;
          }

          /**
           * @ngdoc method
           * @name setMinUsersOfPlan
           * @description set plan min users
           * @param {Any} plan
           */
          function setMinUsersOfPlan(plan) {
            if (plan.code === PLANS.PROMONTHLY) {
              plan.minUsers = CONFIG.PRO_MIN_USERS;
            }
            if (plan.code === PLANS.BASICMONTHLY) {
              plan.minUsers = CONFIG.BASIC_MIN_USERS;
            }
            if (plan.code === PLANS.DOCSMONTHLY) {
              plan.minUsers = CONFIG.DOCS_MIN_USERS;
            }
          }

          /**
           * @ngdoc method
           * @name changePlanName
           * @description Change plan name
           * @param {integer} seletedIndex
           * @returns {string} plan name
          */
          function changePlanName(seletedIndex) {
            if ($ctrl.planName === $ctrl.plans[seletedIndex].name)
              return;

            setChangedPlanDetails(seletedIndex);
            $ctrl.selectedPlanPeriod = _.last(_.split($ctrl.selectedPlan.code, '-'));
            $ctrl.availablePlans = _.reverse(setAvailablePlans());
          }

          /**
           * @ngdoc method
           * @name setPlanAsActive
           * @description Set plan as active plan
           * @param {Object} plan
           * @returns {Object} plan
          */
          function setPlanAsActive(plan) {
            plan.active = true;
            $ctrl.user.plan_code = plan.code;
            $ctrl.selectedPlan = plan;
            $ctrl.previousSelectedPlan = angular.copy($ctrl.selectedPlan);
            $ctrl.totalCost = (_.get($ctrl.selectedPlan, 'cost', 0) * $ctrl.otherUsers.length) + ($ctrl.lightUsers.length * _.get($ctrl.selectedPlan, 'light_member_cost', 0));
            checkPlanPrice();
          }

          /**
           * @ngdoc method
           * @name setPlanAsInactive
           * @description Set plan as inactive plan
           * @returns {Object} availablePlans
          */
          function setPlanAsInactive() {
            var idx = _.findIndex($ctrl.availablePlans, ['active', true]);
            if (idx !== -1) {
              $ctrl.availablePlans[idx].active = false;
            }
          }

          /**
           * @ngdoc method
           * @name getGeneralInfo
           * @description Get some property in all object
           * @param {Object} account
           * @returns {Object}
          */
          function getGeneralInfo(account) {
            return _.pick(account, ['email', 'first_name', 'last_name', 'phone', 'default_organization.address1', 'default_organization.address2', 'default_organization.state', 'default_organization.city', 'default_organization.zipcode', 'default_organization.country']);
          }

          /**
           * @ngdoc method
           * @name updatePlanApply
           * @description If Coupon code than apply and update plan
          */
          function updatePlanApply() {
            if ($ctrl.user.couponCode) {
              applyCouponCode(true);
            } else {
              updatePlan();
            }
          }

          /**
           * @ngdoc method
           * @name updatePlan
           * @description Get recurly token and save billing information
           * @returns {string} token
          */
          function updatePlan() {
            if ($ctrl.hidePaymentInfo) {
              if ($ctrl.hidePaymentInfo && $ctrl.orgUser > CONFIG.APPSUMO_ACCOUNT_USER_LIMIT) {
                $ctrl.appsumoLimit = $ctrl.notValidCouponCode = true;
                return;
              }
              blockUI.start();
              changePlan();
              return;
            }

            trackEvent('PLAN AND PAYMENT - CC DETAILS SUBMITTED');
            var form = angular.element(document).find('form');
            recurly.token(form, function (error, token) {
              if (error) {
                $rootScope.$emit('PAYMENT:RECURLY_ERROR', error);
                trackEvent('PLAN AND PAYMENT - FAILURE AT RECURLY');
              } else {
                if (!$ctrl.upgradePlanForm.$valid || _.isUndefined($ctrl.country)) {
                  return;
                }
                blockUI.start();
                $ctrl.user.country = $ctrl.country.iso2;
                SupportService.addBillingInfo($ctrl.organization.id, token.id).then(function (response) {
                  trackEvent('PLAN AND PAYMENT - PURCHASE SUCCESSFUL');
                  updateBillingInfo();
                }, function (error) {
                  blockUI.stop();
                  if (error.data.message) {
                    $ctrl.invalidNumber = error.data.message;
                  }
                });
              }
            });
          }

          /**
           * @ngdoc method
           * @name updateBillingInfo
           * @description Update billing information
           * @returns {string}
          */
          function updateBillingInfo() {
            var param = {
              address: {
                address1: _.get($ctrl.user, 'default_organization.address1'),
                address2: _.get($ctrl.user, 'default_organization.address2'),
                state: _.get($ctrl.user, 'default_organization.state'),
                city: _.get($ctrl.user, 'default_organization.city'),
                zip: _.get($ctrl.user, 'default_organization.zipcode'),
                country: _.get($ctrl.country, 'code'),
                phone: _.get($ctrl.user, 'phone')
              },
              email: _.toLower($ctrl.user.email)
            };
            SupportService.updateBillingInfo($ctrl.organization.id, param).then(function () {
              changePlan();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name changePlan
           * @description Update organization plan code
           * @returns {Object}
          */
          function changePlan() {
            var data = {
              plan_code: $ctrl.selectedPlan.code,
              unit_amount_in_cents: $ctrl.selectedPlan.cost * 100,
              quantity: $ctrl.orgUser
            };
            if (!_.isUndefined($ctrl.couponCode)) {
              data.coupon_code = $ctrl.couponCode;
            }
            SupportService.changePlan({ org: $ctrl.organization.id }, data).then(function () {
              getOrganizationsDetails();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name applyCouponCode
           * @param {Boolean} isPlanPurchase 
           * @description Returns coupon code is valid or not
           * @returns {Object} 
          */
          function applyCouponCode(isPlanPurchase) {
            blockUI.start();
            var pricing = recurly.Pricing();
            pricing
              .plan($ctrl.selectedPlan.code, { quantity: $ctrl.orgUser })
              .coupon($ctrl.user.couponCode).then(function (price) {
                $timeout(function () {
                  blockUI.stop();
                  $ctrl.validCouponCode = true;
                  $ctrl.couponName = price.name;
                  $ctrl.couponCode = price.code;
                  if (_.includes(price.code, _.toLower(ENV_CONFIG.IF_COUPON_STRING_CONTAINS))) {
                    checkAppSumoCode(price.code);
                    return;
                  }
                  _.filter($ctrl.allPlans.PUBLIC_PRICING_PLANS, function (plan) {
                    if (price.discount.type === 'percent') {
                      plan.couponDiscount = plan.cost * Math.max(getMinimumUser(plan.code), $ctrl.orgUser) * price.discount.rate;
                      $ctrl.couponDiscount = price.discount.rate * 100 + '%';
                      return;
                    }
                    plan.couponDiscount = price.discount.amount.USD;
                    $ctrl.couponDiscount = '$' + price.discount.amount.USD;
                    $ctrl.couponPrice = price.discount.amount.USD;
                  });
                  checkPlanPrice();
                  if (isPlanPurchase) {
                    updatePlan();
                  }
                });
              }, function () {
                $timeout(function () {
                  blockUI.stop();
                  $ctrl.notValidCouponCode = true;
                });
              });
          }

          /**
           * @ngdoc method
           * @name getMinimumUser
           * @param {*} selectedPlan 
           * @description Get Minimum User for selected plan
           * @returns {void}
           */
          function getMinimumUser(selectedPlan) {
            return _.includes([PLANS.PROMONTHLY, PLANS.PROANNUAL], selectedPlan) ? CONFIG.PRO_MIN_USERS 
              : _.includes([PLANS.BASICMONTHLY, PLANS.BASICANNUAL], selectedPlan) ? CONFIG.BASIC_MIN_USERS
              : _.includes([PLANS.DOCSMONTHLY, PLANS.DOCSANNUAL], selectedPlan) ? CONFIG.DOCS_MIN_USERS
              : '';
          }

          /**
           * @ngdoc method
           * @name setDefualtValue
           * @description Set defualt value
           * @returns {void}
          */
          function setDefualtValue() {
            $ctrl.validCouponCode = false;
            $ctrl.notValidCouponCode = false;
            $ctrl.hidePaymentInfo = false;
            $ctrl.showAmountDueMsg = false;
            $ctrl.appsumoLimit = false;
            $ctrl.couponDiscount = 0;
            $ctrl.couponCode = '';
            $ctrl.selectedPlan = $ctrl.previousSelectedPlan;
          }

          /**
           * @ngdoc method
           * @name checkAppSumoCode
           * @description Check coupon code string contains appsumo
           * @param {any} code
           * @returns {void}
           */
          function checkAppSumoCode(code) {
            if (!_.isUndefined($ctrl.allPlans.SPECIAL_PRICING_PLANS)) {
              var appsumo = _.find($ctrl.allPlans.SPECIAL_PRICING_PLANS, { 'code': ENV_CONFIG.FIX_PLAN });
              if (!_.isUndefined(appsumo)) {
                $ctrl.selectedPlan = appsumo;
                $ctrl.hidePaymentInfo = true;
              }
            }
          }

          /**
           * @ngdoc method
           * @name checkPlanPrice
           * @description Check coupon code price with selected plan
           * @returns {Boolean}
           */
          function checkPlanPrice() {
            $ctrl.showAmountDueMsg = false;
            if (($ctrl.selectedPlan.cost * $ctrl.orgUser) <= $ctrl.couponPrice && $ctrl.validCouponCode) {
              $ctrl.showAmountDueMsg = true;
            }
          }

          /**
           * @ngdoc method
           * @name calculatePlanCost
           * @description Check coupon code price with selected plan
           * @param {integer} cost
           * @param {integer} userCount
           * @returns {Boolean}
           */
          function calculatePlanCost(cost, couponDiscount, minUsers) {
            if ($ctrl.isMinMemberBillingEnabled && minUsers && $ctrl.orgUser < minUsers) {
              if (!_.isUndefined(couponDiscount)) {
                return (((cost * Math.max(minUsers, $ctrl.orgUser)) - couponDiscount) < 0) ? '0' : ((cost * Math.max(minUsers, $ctrl.orgUser)) - couponDiscount);
              }
              return cost * Math.max(minUsers, $ctrl.orgUser);
            } else {
              if (!_.isUndefined(couponDiscount)) {
                return (((cost * $ctrl.orgUser) - couponDiscount) < 0) ? '0' : (cost * $ctrl.orgUser) - couponDiscount;
              }
              return cost * $ctrl.orgUser;
            }
          }

          /**
           * @name continueWithPurchase
           * 
           * @description
           * Continue with purchase
           */
          function continueWithPurchase() {
            trackEvent('PLAN AND PAYMENT - CONTINUE');
          }

          /**
           * @name trackEvent
           * @param {*} eventLabel
           *
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventLabel) {
            $analytics.eventTrack('Click', { label: eventLabel, category: Helper.eventTrackingCategories['purchase'] });
          }

          /**
           * @ngdoc method
           * @name setChangedPlanDetails
           * @description Set details for changed plan
           * @param {Integer} seletedIndex
           * @returns {Void}
           */
          function setChangedPlanDetails(seletedIndex) {
            $ctrl.planName = $ctrl.plans[seletedIndex].name;
            $ctrl.planImage = $ctrl.plans[seletedIndex].image;
            $ctrl.selectedPlanBackColor = $ctrl.plans[seletedIndex].backColor;
          }

          /**
           * @ngdoc method
           * @name getOrganizationsDetails
           * @description get organizations details
           * after update/change the plan
           */
          function getOrganizationsDetails() {
            OrganizationsRepository.get({
              with: 'subscription',
              org: $ctrl.organization.id
            }).then(function (response) {
              angular.extend($rootScope.identity.default_organization, response.data);
              $rootScope.$emit("ORGANIZATION:UPDATEORGLOGO");
              $rootScope.$broadcast('UPDATE_FREE_TRIAL_DAYS');
              $state.go('home', { 'referenceId': 'growlNotification', 'success': $filter('translate')('updateOrgPlan.payment.messages.upgradePlan', { planName: $ctrl.hidePaymentInfo ? $ctrl.selectedPlan.name : $ctrl.planName }) });
                blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }
        }
    });
})();
