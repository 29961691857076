(function () {
  'use strict';
  angular.module('tallyfy.process')
    .component('automationAction', {
      templateUrl: 'app/modules/process/components/edit/automation/wrapper/action/action.component.html',
      bindings: {
        form: '=',
        process: '=',
        isFirst: '<',
        isLast: '<',
        isNew: '<',
        automation: '=',
        action: '=',
        usersInOrg: '<',
        groupsInOrg: '<',
        guestsInOrg: '<',
        validationActive: '<',
        actionUpdatedCallback: '&',
        deleteActionCallback: '&',
        index: '<',
        removeSelectedStep: '='
      },
      controller:
        /*@ngInject*/
        function (_, $scope, $filter, ProcessService, Helper, AUTOMATION_ACTION_TYPE) {
          var $ctrl = this, localIdWatcher, usersAssigneWatcher, guestsAssigneWatcher, groupsAssigneWatcher, removeSelectedStepWatcher;

          $ctrl.automationTypes = ProcessService.getAutomationTypes();
          $ctrl.deadlineTriggers = ['from', 'before']; // [plus (+), minus (-)]
          $ctrl.deadlineTriggerValues = ['minutes', 'hours', 'days', 'weeks', 'months'];

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.conditionSelected = conditionSelected;
          $ctrl.actionableSelected = actionableSelected;
          $ctrl.setSelectedValue = setSelectedValue;
          $ctrl.getSelectedConditionLabel = getSelectedConditionLabel;
          $ctrl.getSelectedActionableLabel = getSelectedActionableLabel;

          $ctrl.actionUpdated = actionUpdated;
          $ctrl.deleteAction = deleteAction;

          $ctrl.automationTypeChanged = automationTypeChanged;

          $ctrl.onAssigneeAdded = onAssigneeAdded;
          $ctrl.onAssigneeAdding = onAssigneeAdding;
          $ctrl.onAssigneeRemoved = onAssigneeRemoved;
          $ctrl.loadAssignee = loadAssignee;

          $ctrl.assigneeData = [];

          function onInit() {
            if (_.isUndefined($ctrl.action.position)) {
              $ctrl.action.position = $ctrl.index;
            }
            $ctrl.memberPrefixTranslation = Helper.getMemberPrefixTranslation();
            if (!$ctrl.action.id) {
              localIdWatcher = $scope.$watch('$ctrl.action.id', function (val) {
                if (val) {
                  delete $ctrl.action.localId;
                }
              });
            }
            if ($ctrl.action.action_type) {
              $ctrl.selectedActionType = _.find($ctrl.automationTypes, { type: $ctrl.action.action_type });
            }
            prepareActionable();
          }

          function onChanges(changes) {
            if ($ctrl.action.action_type) {
              $ctrl.selectedActionType = _.find($ctrl.automationTypes, { type: $ctrl.action.action_type });
            }
            prepareActionable();
          }

          function onDestroy() {
            if (localIdWatcher) {
              localIdWatcher();
            }
            usersAssigneWatcher();
            guestsAssigneWatcher();
            groupsAssigneWatcher();
            removeSelectedStepWatcher();
          }

          function prepareActionable() {
            $ctrl.availableActionables = [];
            if ($ctrl.action.action_type === AUTOMATION_ACTION_TYPE.DEADLINE) {
              if (!_.isEmpty($ctrl.process.prerun)) {
                _.map($ctrl.process.prerun, function (item) {
                  if (item.field_type === 'date') {
                    $ctrl.availableActionables.push({
                      id: item.id,
                      name: $filter('translate')('steps.logic.label.field', { label: item.label, field: $filter('translate')('steps.logic.label.fieldType.' + item.field_type.toLowerCase()) }),
                      nameText: $filter('translate')('steps.logic.label.fieldText', { label: item.label }),
                      title: $filter('translate')('steps.logic.label.kickOffTitle', { label: item.label }),
                      subjectType: $filter('translate')('steps.logic.label.pre_run'),
                      class: 'item-field',
                      type: 'Prerun',
                      required: item.required,
                      fieldtype: item.field_type,
                      options: item.options,
                      columns: item.columns,
                      fieldValidation: item.field_validation || []
                    });
                  }
                });
              }
              if ($ctrl.process.steps.data.length) {
                _.map(_.orderBy(getStepsOrdered(), ['stage_order', 'position']), function (step, index) {
                  $ctrl.availableActionables.push({
                    id: step.id,
                    name: $filter('translate')('steps.logic.label.step_plain', { position: step.position, title: step.title }),
                    nameText: $filter('translate')('steps.logic.label.step_plain_text', { position: step.position, title: step.title }),
                    title: $filter('translate')('steps.logic.label.stepTitle', { position: step.position, title: step.title }),
                    subjectType: '',
                    class: 'item-step',
                    type: 'Step',
                    stepType: step.step_type
                  });
                  var dateFields = _.filter(step.captures, function (capture) {
                    return capture.field_type === 'date';
                  });
                  if (dateFields.length) {
                    _.map(dateFields, function (item) {
                      if (item.field_type === 'date') {
                        $ctrl.availableActionables.push({
                          id: item.id,
                          name: $filter('translate')('steps.logic.label.field', { label: item.label, field: $filter('translate')('steps.logic.label.fieldType.' + item.field_type.toLowerCase()) }),
                          nameText: $filter('translate')('steps.logic.label.fieldText', { label: item.label }),
                          title: $filter('translate')('steps.logic.label.captureTitle', { position: step.position, title: step.title, fieldName: item.label }),
                          subjectType: '',
                          class: 'item-field ml-3',
                          type: 'Capture',
                          parent_step_id: step.id,
                          required: item.required,
                          fieldtype: item.field_type,
                          options: item.options,
                          columns: item.columns,
                          fieldValidation: item.field_validation || []
                        });
                      }
                    });
                  }
                });
              }
            } else if ($ctrl.action.action_type === AUTOMATION_ACTION_TYPE.ASSIGMENT) {
              _.map($ctrl.process.prerun, function (item) {
                if (item.field_type === 'assignees_form') {
                  $ctrl.availableActionables.push({
                    id: item.id,
                    name: $filter('translate')('steps.logic.label.field', { label: item.label, field: $filter('translate')('steps.logic.label.fieldType.' + item.field_type.toLowerCase()) }),
                    nameText: $filter('translate')('steps.logic.label.fieldText', { label: item.label }),
                    title: $filter('translate')('steps.logic.label.kickOffTitle', { label: item.label }),
                    subjectType: $filter('translate')('steps.logic.label.pre_run'),
                    class: 'item-field',
                    type: 'Prerun',
                    required: item.required,
                    fieldtype: item.field_type,
                    options: item.options,
                    columns: item.columns,
                    fieldValidation: item.field_validation || []
                  });
                }
              });
              if ($ctrl.process.steps.data.length) {
                _.map(_.orderBy(getStepsOrdered(), ['stage_order', 'position']), function (step) {
                  var assigneFields = _.filter(step.captures, function (capture) {
                    return capture.field_type === 'assignees_form';
                  });
                  if (assigneFields.length) {
                    _.map(assigneFields, function (item) {
                      if (item.field_type === 'assignees_form') {
                        $ctrl.availableActionables.push({
                          id: item.id,
                          name: $filter('translate')('steps.logic.label.field', { label: item.label, field: $filter('translate')('steps.logic.label.fieldType.' + item.field_type.toLowerCase()) }),
                          nameText: $filter('translate')('steps.logic.label.fieldText', { label: item.label }),
                          title: $filter('translate')('steps.logic.label.captureTitle', { position: step.position, title: step.title, fieldName: item.label }),
                          subjectType: step.title,
                          class: 'item-field',
                          type: 'Capture',
                          parent_step_id: step.id,
                          required: item.required,
                          fieldtype: item.field_type,
                          options: item.options,
                          columns: item.columns,
                          fieldValidation: item.field_validation || []
                        });
                      }
                    });
                  }
                });
              }
            }
            var action_verb = !$ctrl.removeSelectedStep && _.get($ctrl.action, 'action_verb') === 'hide';
            prepareConditions(action_verb);
            setSelectedActionableValue();
          }

          function getStepsOrdered(flag) {
            var data = [], automation_steps = _.filter($ctrl.automation.conditions, function(r) { return _.toLower(r.conditionable_type) === "step";});
            if (flag) {
              data = _.map($ctrl.process.steps.data, function (task) {
                var stageData = _.find(_.get($ctrl.process, 'stages.data', []), function (stage) {
                  return !task.stage_id ? false : (stage.id === task.stage_id.toString());
                });
                task.stage_order = stageData ? stageData.position : null;
                return task;
              });
            } else {
                if (!$ctrl.removeSelectedStep && ($ctrl.selectedActionType.type === 'deadline' || $ctrl.selectedActionType.type === 'assignment')) {
                  data = _.map($ctrl.process.steps.data, function (task) {
                    var stageData = _.find(_.get($ctrl.process, 'stages.data', []), function (stage) {
                      return !task.stage_id ? false : (stage.id === task.stage_id.toString());
                    });
                    task.stage_order = stageData ? stageData.position : null;
                    return task;
                  });
                } else if ($ctrl.removeSelectedStep || ($ctrl.selectedActionType.type !== 'deadline' && $ctrl.selectedActionType.type !== 'assignment')) {
                  var list = _.filter($ctrl.process.steps.data, function(r) { return !_.includes(_.map(automation_steps, 'conditionable_id'), r.id); });
                  data = _.map(list, function (task) {
                    var stageData = _.find(_.get($ctrl.process, 'stages.data', []), function (stage) {
                      return !task.stage_id ? false : (stage.id === task.stage_id.toString());
                    });
                    task.stage_order = stageData ? stageData.position : null;
                    return task;
                  });
                }
            }
            return data;
          }

          function setSelectedActionableValue() {
            $ctrl.selectedActionable = _.find($ctrl.availableActionables, { id: $ctrl.action.actionable_id });
          }

          function prepareConditions(flag) {
            $ctrl.availableConditions = [];
            if ($ctrl.process.steps.data.length) {
              _.map(_.orderBy(getStepsOrdered(flag), ['stage_order', 'position']), function (step) {
                $ctrl.availableConditions.push({
                  id: step.id,
                  name: $filter('translate')('steps.logic.label.step_plain', { position: step.position, title: step.title }),
                  nameText: $filter('translate')('steps.logic.label.step_plain_text', { position: step.position, title: step.title }),
                  title: $filter('translate')('steps.logic.label.stepTitle', { position: step.position, title: step.title }),
                  subjectType: '',
                  class: 'item-step',
                  type: 'Step',
                  stepType: step.step_type
                });
              });
            }
            setSelectedValue();
          }

          function getSelectedConditionLabel(e) {
            var condition = $ctrl.selectedCondition;
            if (!Helper.isObjectEmpty(condition)) {
              if (condition.type.toLowerCase() === 'prerun') {
                return $filter('translate')('steps.logic.label.pre_run') + " > " + condition.title;
              }
              if (condition.type.toLowerCase() === 'capture') {
                var parent_step = _.find($ctrl.availableConditions, function (availableCondition) {
                  return availableCondition.id == condition.parent_step_id;
                });
                return parent_step.name + " > " + condition.title;
              }
              return condition.title;
            }
            return '';
          }

          function getSelectedActionableLabel(e) {
            var actionable = $ctrl.selectedActionable;
            if (!Helper.isObjectEmpty(actionable)) {
              if (actionable.type.toLowerCase() === 'prerun') {
                return $filter('translate')('steps.logic.label.pre_run') + " > " + actionable.title;
              }
              if (actionable.type.toLowerCase() === 'capture') {
                return actionable.title;
              }
              return actionable.title;
            }
            return '';
          }

          function isDeadlineOrAssignmentActionType() {
            return ($ctrl.selectedActionType.type.toLowerCase() == AUTOMATION_ACTION_TYPE.ASSIGMENT) || ($ctrl.selectedActionType.type.toLowerCase() == AUTOMATION_ACTION_TYPE.DEADLINE);
          }

          function conditionSelected(e) {
            $ctrl.action.target_step_id = e.selected.type === 'Step' ? e.selected.id : null;
            actionUpdated();
          }

          function actionableSelected(e) {
            var isDeadlineOrAssignment = isDeadlineOrAssignmentActionType();
            $ctrl.action.actionable_type = isDeadlineOrAssignment ? e.selected.type : null;
            $ctrl.action.actionable_id = isDeadlineOrAssignment ? e.selected.id : null;
            actionUpdated();
          }

          function actionUpdated() {
            if (!$ctrl.selectedActionType || ($ctrl.selectedActionType && !$ctrl.selectedActionType.type)) {
              return;
            }
            var action_verb = !$ctrl.removeSelectedStep && $ctrl.action.action_verb === 'hide';
            prepareConditions(action_verb);
            if ($ctrl.selectedActionType.type === AUTOMATION_ACTION_TYPE.DEADLINE) {
              if (!$ctrl.action.deadline) {
                $ctrl.action.deadline = { value: 1, unit: 'days', option: 'from' };
              }
            } else {
              $ctrl.action.deadline = null;
            }
            if ($ctrl.selectedActionType.type === AUTOMATION_ACTION_TYPE.ASSIGMENT) {
              if (!$ctrl.action.assignees) {
                $ctrl.action.assignees = { users: [], groups: [], guests: [] };
              }
            } else {
              $ctrl.action.assignees = null;
            }
            if (!$ctrl.action.actionable_type || !$ctrl.action.actionable_id) {
              $ctrl.action.actionable_type = null;
              $ctrl.action.actionable_id = null;
            }
            $ctrl.actionUpdatedCallback({ action: $ctrl.action });
          }

          function setSelectedValue() {
            $ctrl.selectedCondition = _.find($ctrl.availableConditions, { id: $ctrl.action.target_step_id });
          }

          function automationTypeChanged(type, index) {
            if (!type) {
              return;
            }
            if (index) {
              $ctrl.form['actionType_' + ($ctrl.action.id || $ctrl.action.localId) + '_' + index].$setDirty();
            }
            $ctrl.selectedActionType = _.find(angular.copy($ctrl.automationTypes), { type: type });
            $ctrl.action.action_type = $ctrl.selectedActionType.type;
            $ctrl.selectedCondition = null;
            $ctrl.action.actionable_id = null;
            $ctrl.action.actionable_type = null;
            $ctrl.action.action_verb = null;
            $ctrl.action.target_step_id = null;
            prepareActionable();
            actionUpdated();
          }

          function deleteAction() {
            $ctrl.deleteActionCallback({ action: $ctrl.action });
          }

          function isUserExist(users, user) {
            return _.some(users, function (owner) {
              return (owner.email === user.text) || (owner.email === user.email);
            });
          }

          function loadAssignee($query) {
            var assigneeData = _.concat($ctrl.usersInOrg, $ctrl.guestsInOrg, $ctrl.groupsInOrg);
            return _.filter(assigneeData, function (owner) {
              return owner.text && (owner.text.toLowerCase().indexOf($query.toLowerCase()) !== -1);
            });
          }

          function onAssigneeAdded(value) {
            if (!isUserExist($ctrl.usersInOrg, value)) {
              if (value.type === 'guest' || value.type === undefined) {
                value.id = value.text;
                $ctrl.action.assignees.guests.push(value.id);
              } else {
                $ctrl.action.assignees.groups.push(value.id);
              }
            } else {
              angular.extend(value, _.find($ctrl.usersInOrg, function (user) {
                return user.email === value.text;
              }));
              $ctrl.action.assignees.users.push(value.id);
            }
            $ctrl.actionUpdatedCallback({ action: $ctrl.action });
          }

          function onAssigneeAdding(value) {
            if (!value.id) {
              if (!Helper.isValidEmail(value.text)) {
                return false;
              } else if ($ctrl.action.assignees.groups.length && value.type !== 'group' && isUserExist($ctrl.usersInOrg, value)) {
                angular.extend(value, _.find($ctrl.usersInOrg, function (user) {
                  return user.email === value.text;
                }));
                return !Helper.isMemberPresentInGroup(value, angular.copy($ctrl.action.assignees.groups), $ctrl.orgGroups, true, true);
              }
              return true;
            } else if ($ctrl.action.assignees.groups.length && value.type !== 'group' && Helper.isMemberPresentInGroup(value, angular.copy($ctrl.action.assignees.groups), $ctrl.orgGroups, false, true)) {
              return false;
            }
            return true;
          }

          function onAssigneeRemoved(value) {
            var isMember = _.find($ctrl.usersInOrg, { id: value.id });
            if (isMember) {
              $ctrl.action.assignees.users = _.filter($ctrl.action.assignees.users, function (assignee) {
                return assignee !== value.id;
              });
            } else {
              if (value.id && value.type === 'group') {
                $ctrl.action.assignees.groups = _.filter($ctrl.action.assignees.groups, function (group_id) {
                  return group_id !== value.id;
                });
              } else {
                $ctrl.action.assignees.guests = _.filter($ctrl.action.assignees.guests, function (guest) {
                  return guest !== value.id;
                });
              }
            }
            $ctrl.actionUpdatedCallback({ action: $ctrl.action });
          }

          removeSelectedStepWatcher = $scope.$watch('$ctrl.removeSelectedStep', function (value, oldValue) {
            prepareActionable();
          });
          
          usersAssigneWatcher = $scope.$watchCollection('$ctrl.action.assignees.users', function (value, oldValue) {
            if ($ctrl.action.action_type !== AUTOMATION_ACTION_TYPE.ASSIGMENT) {
              return;
            }
            if (!$ctrl.assigneeData) {
              $ctrl.assigneeData = [];
            }
            $ctrl.assigneeData = $ctrl.assigneeData.filter(function (u) {
              return u.type !== 'member';
            });
            for (var i = 0; i < $ctrl.action.assignees.users.length; i++) {
              var user = _.find($ctrl.usersInOrg, { id: $ctrl.action.assignees.users[i] });
              if (user && !_.find($ctrl.assigneeData, { id: $ctrl.action.assignees.users[i] })) {
                $ctrl.assigneeData.push(user);
              }
            }
          });

          guestsAssigneWatcher = $scope.$watchCollection('$ctrl.action.assignees.guests', function (value, oldValue) {
            if ($ctrl.action.action_type !== AUTOMATION_ACTION_TYPE.ASSIGMENT) {
              return;
            }
            if (!$ctrl.assigneeData) {
              $ctrl.assigneeData = [];
            }
            $ctrl.assigneeData = $ctrl.assigneeData.filter(function (u) {
              return u.type !== 'guest';
            });
            for (var i = 0; i < $ctrl.action.assignees.guests.length; i++) {
              var guest = _.find($ctrl.guestsInOrg, { id: $ctrl.action.assignees.guests[i] });
              if (guest && !_.find($ctrl.assigneeData, { id: $ctrl.action.assignees.guests[i] })) {
                $ctrl.assigneeData.push(guest);
              } else {
                $ctrl.assigneeData.push({ id: $ctrl.action.assignees.guests[i], text: $ctrl.action.assignees.guests[i] });
              }
            }
          });

          groupsAssigneWatcher = $scope.$watchCollection('$ctrl.action.assignees.groups', function (value, oldValue) {
            if ($ctrl.action.action_type !== AUTOMATION_ACTION_TYPE.ASSIGMENT) {
              return;
            }
            if (!$ctrl.assigneeData) {
              $ctrl.assigneeData = [];
            }
            $ctrl.assigneeData = $ctrl.assigneeData.filter(function (u) {
              return u.type !== 'group';
            });
            for (var i = 0; i < $ctrl.action.assignees.groups.length; i++) {
              var group = _.find($ctrl.groupsInOrg, { id: $ctrl.action.assignees.groups[i] });
              if (group && !_.find($ctrl.assigneeData, { id: $ctrl.action.assignees.groups[i] })) {
                $ctrl.assigneeData.push(group);
              }
            }
          });
        }
    });
})();