/**
 * @ngdoc Component
 * @name tallyfy.home.component.home
 * @module tallyfy.home
 *
 * @description
 * A component to manage org users
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('home', {
      templateUrl: 'app/modules/home/home.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $state, Growl, $filter, $q, $confirm, store, blockUI, $timeout, $stateParams, UsersService, Helper, TasksService, CompactTaskService,
          AuthPlan, DATEFORMAT, DateUtils, moment, PLANS, AccountService, $uibModal, $location, TagsService, RunsService, TasksFilterService, FolderService, StepService, USER_STATE, preferencesStore, $window) {
          var $ctrl = this,
            growl = new Growl(),
            oldTask,
            shouldOpenOOTModalOnInit = false,
            taskSelectedItemThreadsWatcher,
            unregisteredIssueReportedEventHandler,
            unregisteredIssueResolvedEventHandler,
            onTaskCompletedHandler,
            tasksFiltersConfigWatchers,
            taskSelectedItemThreadCountWatcher,
            blockUI = blockUI.instances.get('homeTasks'),
            updateOrgPlanEventHandler,
            rightPaneEditModeEventHandler,
            tasksLengthChangesHandler,
            tasksAllFoldersListEventHandler,
            mainSectionHeightHandler,
            body = angular.element('body'),
            windowElem = angular.element($window);
          //,modalInstance;

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.resetFilter = resetFilter;

          // public method
          $ctrl.onItemSwitched = onItemSwitched;
          $ctrl.onRightPaneClosed = onRightPaneClosed;
          $ctrl.dirtyCheckHandler = dirtyCheckHandler;
          $ctrl.beforeRightPaneClose = beforeRightPaneClose;
          $ctrl.saveSelectedTask = saveSelectedTask;
          $ctrl.discardSelectedTask = discardSelectedTask;
          $ctrl.metadata = {};
          $ctrl.filterMetadata = {};
          $ctrl.tasksFiltersConfig = {};
          $ctrl.foldersList = [];
          $ctrl.isBusy = true;
          $ctrl.isActive = true;

          windowElem.on('resize', onWindowResize);

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            body.removeClass('compact-task-active');
            $ctrl.currentUser = $rootScope.identity;
            shouldOpenOOTModalOnInit = !!($state.params.isRequireToOpenOneOffTaskModal);
            $ctrl.metadata.highlightedComment = $stateParams.comment;
            setCurrentPlan();
            $timeout(function () {
              var refId = $state.params.referenceId;
              $state.params.success
                ? growl.success($filter('translate')($state.params.success), { referenceId: refId ? refId : 'logoutController', disableIcons: true, disableCloseButton: true })
                : (
                  $state.params.error
                    ? growl.error($filter('translate')($state.params.error), { referenceId: 'logoutController', disableIcons: true, disableCloseButton: true })
                    : angular.noop()
                );
            });
            $ctrl.taskId = $stateParams.activeTask;
            $ctrl.hasAuthority = Helper.checkAccessAuthority(false);
            if (shouldOpenOOTModalOnInit) {
              blockUI.start();
              var OOTTimeoutHandler = $timeout(function () {
                shouldOpenOOTModalOnInit = false;
                TasksService.standaloneModal();
                blockUI.stop();
                $timeout.cancel(OOTTimeoutHandler);
              }, 5000);
            }
            $ctrl.playTaskSound = AccountService.getPreference($rootScope.identity.preferences, 'play_tasks_sound', 'yes');
            $ctrl.isBusy = true;
            blockUI.start();
            var resources = [
              getUsers(),
              getGuests(),
              getGroups(),
              getFolders(),
              getTagsList()
            ];
            if ($rootScope.userState === USER_STATE.MEMBER) {
              resources.push(StepService.getAllRoles());
            }
            $q.all(resources)
              .then(function (res) {
                if ($rootScope.userState === USER_STATE.MEMBER) {
                  $ctrl.roles = res.pop().data;
                }
                $ctrl.allUsers = res[0] || [];
                $ctrl.orgGuests = res[1] || [];
                $ctrl.orgGroups = res[2] || [];
                $ctrl.foldersList = res[3].data || [];
                $ctrl.allTags = _.sortBy(res[4].data, 'title');
                $ctrl.orgAllUsers = UsersService.getAllOrgUsers($ctrl.allUsers);
                $ctrl.availableUsers = UsersService.getBilledUsers(res[0]);
                $ctrl.isBusy = false;
                var assignee = _.find(_.concat($ctrl.allUsers, $ctrl.orgGuests), function (user) {
                  return user.id === $stateParams.assignee || user.email === $stateParams.assignee;
                });
                if(!assignee) {
                  angular.extend($stateParams, {
                    assignee: $ctrl.currentUser.id
                  });
                }
              });
            if ($stateParams.status !== 'all' && $stateParams.status !== 'archived') {
              TasksService.getTaskById({
                task_id: $stateParams.activeTask
              }).then(function (res) {
                $ctrl.selectedTask = _.get(res, 'data');
                var params = {
                  assignee: $stateParams.assignee ? $stateParams.assignee : $ctrl.currentUser.id,
                  activeTask: _.get(res.data, 'id'),
                  status: _.get(res.data, 'status')
                };
                if ($ctrl.selectedTask.status) {
                  params.status = $ctrl.selectedTask.status === 'all' ? 'all' : $ctrl.selectedTask.status === 'completed' ? 'completed' : 'to-do';
                }
                $state.go($state.current, angular.extend($stateParams, params), { notify: false, location: 'replace' });
                blockUI.stop();
              } , function () { });
            } else {
              blockUI.stop();
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            taskSelectedItemThreadsWatcher();
            unregisteredIssueReportedEventHandler();
            unregisteredIssueResolvedEventHandler();
            onTaskCompletedHandler();
            updateOrgPlanEventHandler();
            taskSelectedItemThreadCountWatcher();
            rightPaneEditModeEventHandler();
            tasksLengthChangesHandler();
            tasksAllFoldersListEventHandler();
            tasksFiltersConfigWatchers();
            mainSectionHeightHandler ? $timeout.cancel(mainSectionHeightHandler) : angular.noop();
            windowElem.off('resize', onWindowResize);
            body.removeClass('compact-task-active');
          }

          function getTagsList() {
            var defer = $q.defer();
            TagsService.get({ page: 1, per_page: 999, action: 'tags' })
              .then(function (res) {
                defer.resolve(res);
              });
            return defer.promise;
          }

          function getFolders() {
            var defer = $q.defer();
            FolderService.getTaskOrRunFolders({
              pagination: false,
              sort: 'title',
              with: 'children',
              folder_type: 'run'
            }).then(function (res) {
              defer.resolve(res);
            });
            return defer.promise;
          }

          function getUsers() {
            var defer = $q.defer();
            store.getUsers()
              .then(function (res) {
                defer.resolve(res);
              });
            return defer.promise;
          }

          function getGuests() {
            var defer = $q.defer();
            store.getLightweightGuests()
              .then(function (res) {
                defer.resolve(res);
              });
            return defer.promise;
          }

          function getGroups() {
            var defer = $q.defer();
            store.getLightweightGroups()
              .then(function (res) {
                defer.resolve(res);
              });
            return defer.promise;
          }

          /**
           * @ngdoc method
           * @name onItemSwitched
           * @param {*} item
           *
           * @description
           * on item switched
           */
          function onItemSwitched(item) {
            oldTask = item.id !== 'ko-task' ? angular.copy(item.task) : void 0;
            $state.go($state.current, angular.extend($stateParams, {
              activeTask: item.id === 'ko-task' ? 'ko-task' : _.get(item.task, 'id', void 0)
            }), {
              notify: false, location: 'replace'
            });
            onWindowResize();
          }

          /**
           * @ngdoc method
           * @name onRightPaneClosed
           * @param {Object} data
           *
           * @description
           * on right pane closed method callback
           */
          function onRightPaneClosed(data) {
            if (!$ctrl.selectedItem && $stateParams.activeTask && $ctrl.selectedTask) {
              angular.extend($stateParams, {
                activeTask: void 0
              });
            }
            if (!Helper.isObjectEmpty(data)) {
              $state.go($state.current, angular.extend($stateParams, {
                activeTask: void 0
              }), {
                notify: false, location: 'replace'
              });
            }
          }

          function dirtyCheckHandler(args) {
            var defer = $q.defer();
            if ($ctrl.selectedItem) {
              dirtyHandler(args).then(
                function (status) {
                  status.isValid
                    ? $ctrl.selectedItem.isChatBoxOpen
                    = args.isChatBoxOpen : angular.noop();
                  defer.resolve({
                    isValid: args.isChatBoxOpen ? false : status.isValid
                  });
                }, function (status) {
                  if ($ctrl.selectedItem) {
                    status.isValid ? $ctrl.selectedItem.isChatBoxOpen = args.isChatBoxOpen : angular.noop();
                  }
                  defer.resolve({
                    isValid: args.isChatBoxOpen ? false : status.isValid
                  });
                }
              );
            } else {
              if ($ctrl.selectedItem) {
                $ctrl.selectedItem.isChatBoxOpen = args.isChatBoxOpen;
              }
              defer.resolve({
                isValid: !args.isChatBoxOpen
              });
            }
            return defer.promise;
          }

          function beforeRightPaneClose(args) {
            var defer = $q.defer();
            dirtyHandler(args).then(
              function (status) {
                defer.resolve({
                  isValid: status.isValid
                });
              }, function (status) {
                defer.resolve({
                  isValid: status.isValid
                });
              }
            );
            return defer.promise;
          }

          function dirtyHandler(args) {
            var editMode = _.get(args, 'item.editMode');
            var defer = $q.defer();
            if ($ctrl.editForm && ($ctrl.editForm.$dirty || !$ctrl.editForm.$pristine)) {
              $confirm({
                'body': 'global.unsaved.body',
                'header': 'global.unsaved.header',
                'buttons': {
                  'accept': 'global.unsaved.buttons.accept',
                  'cancel': 'global.unsaved.buttons.cancel'
                },
                'modalType': 'modal-task'
              }).then(function () {
                if (_.get($ctrl.selectedItem.task, 'original_title')) {
                  _.set($ctrl.selectedItem.task, 'title', $ctrl.selectedItem.task.original_title);
                  _.set($ctrl.selectedItem.task, 'name', $ctrl.selectedItem.task.original_title);
                }
                var diff = _.get($ctrl.task, 'step.data.is_soft_start_date') ? true : TasksService.getDiffInDate($ctrl.selectedItem.task.started_at, $ctrl.selectedItem.task.deadline);
                if (diff) {
                  saveSelectedTask({
                    task: $ctrl.selectedItem.task
                  }).then(function (data) {
                    if (!editMode) {
                      $ctrl.editMode = false;
                    }
                    defer.resolve(data);
                  }, function (data) {
                    defer.resolve({
                      isValid: data.isValid
                    });
                  });
                }
              }, function () {
                discardSelectedTask({
                  task: $ctrl.selectedItem.task,
                  oldTask: oldTask
                })
                  .then(function (data) {
                    if (!editMode) {
                      $ctrl.editMode = false;
                    }
                    defer.resolve({
                      isValid: data.isValid
                    });
                  }, function (data) {
                    defer.resolve({
                      isValid: data.isValid
                    });
                  });
              });
            } else {
              if (!editMode) {
                $ctrl.editMode = false;
              }
              defer.resolve({
                isValid: true
              });
            }
            return defer.promise;
          }

          function saveSelectedTask(args) {
            var defer = $q.defer(), deadline = void 0, task = args.task;

            if (!args.assigneeOnly) {
              deadline = DateUtils.toUTC(moment(task.deadline).format()).format(DATEFORMAT.DEFAULT);
            } else {
              deadline = DateUtils.toUTC(moment(task.deadline_unformatted)).format(DATEFORMAT.DEFAULT);
            }

            var params = task.run_id
              ? { id: task.id, action_id: task.run_id, update_dependent_deadlines: args.update_dependent_deadlines }
              : { id: task.id };
            var savedTask = angular.extend({}, task, { deadline: deadline });

            blockUI.start();
            if (!task.run_id && task.is_oneoff_task) {
              var started_at = DateUtils.toUTC(moment(task.started_at)).format(DATEFORMAT.DEFAULT),
                formFieldsToUpdate = _.filter(_.get(task, 'form_fields.data'), function (t) { return t.isFormUpdate && !t.isFormCreate; }),
                formFieldsToCreate = _.filter(_.get(task, 'form_fields.data'), function (t) { return t.isFormCreate; });
              angular.extend(savedTask, {
                started_at: started_at
              });
              if (savedTask.original_title) {
                savedTask.title = savedTask.name = savedTask.original_title;
              }

              var updateFormsRequests = [];
              if (formFieldsToUpdate.length > 0) {
                _.forEach(formFieldsToUpdate, function (field) {
                  delete field.isFormUpdate;
                  updateFormsRequests.push(TasksService.updateOOTForm({ id: task.id, field_id: field.id }, field));
                });
              }

              var deleteFormsRequests = [];
              if (_.get(args, 'deletedOOTFields', []).length > 0) {
                _.forEach(args.deletedOOTFields, function (field) {
                  if (_.get(field, 'isFormUpdate')) {
                    delete field.isFormUpdate;
                  }
                  deleteFormsRequests.push(TasksService.deleteOOTForm({ id: task.id, field_id: field.id }));
                });
              }

              var createFormsRequest = [];
              if (formFieldsToCreate.length > 0) {
                _.forEach(formFieldsToCreate, function (field) {
                  if (field.isNew) {
                    delete field.isNew;
                    delete field.id;
                  }
                  delete field.slug;
                  delete field.isFormCreate;
                  field.name = field.label;
                  if (field.options) {
                    field.options = prepareOptions(field.options);
                  }
                  createFormsRequest.push(TasksService.createOOTForm({ id: task.id }, field));
                });
              }
              var qRequests = _.concat(updateFormsRequests, createFormsRequest, TasksService.updateStandaloneTask(params, savedTask), deleteFormsRequests);
              $q.all(qRequests).then(function (response) {
                if (updateFormsRequests.length > 0) {
                  _.forEach(updateFormsRequests, function (item, i) {
                    var index = _.findIndex(task.form_fields.data, ['id', response[i].data.id]);
                    index > -1 ? task.form_fields.data[index] = response[i].data : angular.noop();
                  });
                }
                if (createFormsRequest.length > 0) {
                  _.forEach(createFormsRequest, function (item, i) {
                    var index = _.findIndex(task.form_fields.data, function (f) {
                      return !f.id && f.label === _.get(response[i + updateFormsRequests.length].data, 'label');
                    });
                    if (index > -1) {
                      task.form_fields.data[index] = response[i + updateFormsRequests.length].data;
                    }
                  });
                }
                var taskData = {
                  task: response[updateFormsRequests.length + createFormsRequest.length].data
                };
                updateDataAfterTaskSaved(taskData);
                defer.resolve({
                  isValid: true,
                  task: taskData.task
                });
                blockUI.stop();
              }, function () {
                defer.resolve({
                  isValid: false
                });
                blockUI.stop();
              });
              return defer.promise;
            } else {
              CompactTaskService.saveTask(params, savedTask)
                .then(function (data) {
                  updateDataAfterTaskSaved(data);
                  defer.resolve({
                    isValid: true,
                    task: data.task
                  });
                  blockUI.stop();
                }, function (error) {
                  blockUI.stop();
                  defer.resolve({
                    max_assignable: !!_.get(error.data, 'data.errors.max_assignable[0]'),
                    isValid: false
                  });
                });
              return defer.promise;
            }
          }

          function updateDataAfterTaskSaved(data) {
            var growlParams = {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            };
            var isGuestRemain = _.isEqual(oldTask.owners.guests.sort(), data.task.owners.guests.sort());
            if (data.task.owners.guests.length && !isGuestRemain) {
              angular.extend(growlParams, {
                variables: {
                  method: copyGuestLink,
                  param: data.task.owners,
                  linkText: $filter('translate')('tasks.messages.copyGuestLink')
                }
              });
            }
            if ($ctrl.editForm) {
              $ctrl.editForm.$setPristine();
            }
            if (_.get($ctrl.selectedItem, 'task')) {
              angular.extend($ctrl.selectedItem.task, data.task);
            }
            oldTask = angular.copy(data.task);
            growl.success($filter('translate')('tasks.messages.taskUpdated'), growlParams);
            $ctrl.editMode = false;
          }

          function discardSelectedTask(args) {
            var defer = $q.defer();
            angular.extend(args.task, {}, args.oldTask);
            if ($ctrl.editForm) {
              $ctrl.editForm.$setPristine();
            }
            $ctrl.editMode = _.get(args, 'editMode', false);
            defer.resolve({
              isValid: true
            });
            return defer.promise;
          }

          /**
           * @ngdoc method
           * @param {*} owners
           *
           * @description
           * copy guest link to clipboard
           */
          function copyGuestLink(owners) {
            if (owners.guests.length > 1) {
              $uibModal.open({
                component: 'copyGuestUrl',
                windowClass: 'copy-guest-url-modal',
                backdrop: 'static',
                resolve: {
                  owners: function () {
                    return owners;
                  }
                }
              });
            } else if (owners.guests.length === 1) {
              Growl.clearAllMessages('global');
              $timeout(function () {
                Helper.copyToClipboard(owners.taskUrls[0], 'guestUrl');
              }, 500);
            }
          }

          /**
           * @function
           * @name prepareOptions
           * @description Prepare field options
           * @param {Object} options
           * @returns {Object}
           */
          function prepareOptions(options) {
            return _.remove(options, function (option) {
              return (option.text !== null && option.text !== "");
            });
          }

          /**
           * @function
           * @name setCurrentPlan
           * @description set current plan value
           * @returns {void}
           */
          function setCurrentPlan() {
            $ctrl.isDocPlan = AuthPlan.isRestrictedWithDocsPlan();
            $ctrl.isProPlan = ([PLANS.PRO, PLANS.PROANNUAL, PLANS.PROMONTHLY].indexOf(AuthPlan.getCurrentPlanCode()) > -1);
            $ctrl.isBasicPlan = ([PLANS.BASIC, PLANS.BASICANNUAL, PLANS.BASICMONTHLY].indexOf(AuthPlan.getCurrentPlanCode()) > -1);
          }

          function savePreferences() {
            AccountService.setUserPreference({
              slug: "primary-filter",
              value: "yes",
              metadata: _.pick($ctrl.tasksFiltersConfig, TasksFilterService.getApplicableFilterKeysAndStateParams())
            }).then(function (response) {
              preferencesStore.updatePreferences(response.data);
            });
          }

          function resetFilter() {
            AccountService.setUserPreference({
              slug: "primary-filter",
              value: "yes",
              metadata: {}
            }).then(function (response) {
              preferencesStore.updatePreferences(response.data);
              $location.search({});
            });
          }

          function onWindowResize () {
            mainSectionHeightHandler = $timeout(function () {
              var entityFilterElem = angular.element(document.getElementById('entityFilter')),
              createTaskElem = angular.element(document.getElementById('createTask')),
              leftSection = angular.element(document.getElementById('leftSection')),
              contentContainer = angular.element(document.getElementById('content')),
              mainSection = angular.element('.main-section'),
              isMasquerading = contentContainer.get(0).classList.contains('masquerading'),
              fixedHeight = isMasquerading ? 100 : 60;
              if (leftSection && leftSection.get(0)) {
                var height = 'calc(100vh - ' + Math.ceil(Number(entityFilterElem.height()) + Number(createTaskElem.height()) + fixedHeight) + 'px)';
                leftSection.get(0).style.height = mainSection.get(0).style.height = height;
              }
            }, 0);
          }

          tasksFiltersConfigWatchers = $scope.$watchCollection(function () {
            return _.pick($ctrl.tasksFiltersConfig, TasksFilterService.getApplicableFilterKeysAndStateParams());
          }, function (value, oldValue) {
            if (value === oldValue) {
              return;
            }
            if (!Helper.isObjectEmpty(value) && $ctrl.tasksFiltersConfig.areTasksFiltersReady) {
              $state.go($state.current, angular.extend($stateParams, _.pick($ctrl.tasksFiltersConfig, TasksFilterService.getApplicableFilterKeysAndStateParams())), { notify: false, location: 'replace' });
              $ctrl.isActive = _.get($ctrl.tasksFiltersConfig, 'status') !== 'archived';
              $rootScope.$broadcast("TASK:FILTER_CONFIG");
              if (Helper.isValidEmail($ctrl.tasksFiltersConfig.assignee) || _.isNumber($ctrl.tasksFiltersConfig.assignee)) {
                $ctrl.tasksFiltersConfig.group = '';
              }
              savePreferences();
            }
          });

          // watch for thread count
          taskSelectedItemThreadCountWatcher = $scope.$watch('$ctrl.selectedItem.task.threads_count', function (value) {
            if (!Helper.isObjectEmpty(value)) {
              var isHasIssue = CompactTaskService.isHasIssue($ctrl.selectedItem.task);
              $ctrl.rightPaneCustomClass = 'compact-container-task-detail ' + (isHasIssue ? ' has-issue ' : '') + ((($ctrl.selectedItem.task.status === 'completed') && ($ctrl.selectedItem.task.task_type === 'approval') && !$ctrl.selectedItem.task.is_approved) ? ' rejected-task' : '');
            }
          });

          // watch for threads data
          taskSelectedItemThreadsWatcher = $scope.$watchCollection('$ctrl.selectedItem.task.threads.data', function (value) {
            if (value) {
              var isHasIssue = CompactTaskService.isHasIssue($ctrl.selectedItem.task);
              $ctrl.rightPaneCustomClass = 'compact-container-task-detail ' + (isHasIssue ? ' has-issue ' : '') + ((($ctrl.selectedItem.task.status === 'completed') && ($ctrl.selectedItem.task.task_type === 'approval') && !$ctrl.selectedItem.task.is_approved) ? ' rejected-task' : '');
            }
          });

          // watch for threads data edited
          unregisteredIssueReportedEventHandler = $rootScope.$on('ISSUE:REPORTED', function (e, data) {
            _.extend($ctrl.selectedItem.task, { problem: _.get(data, 'issue.label') === 'problem' ? true : false });
            if (data.issue.task_id === $ctrl.selectedItem.task.id) {
              $timeout(function () {
                var isHasIssue = CompactTaskService.isHasIssue($ctrl.selectedItem.task);
                $ctrl.rightPaneCustomClass = "compact-container-task-detail " + (isHasIssue ? " has-issue " : "");
              }, 0);
            }
          });

          // watch for threads data edited
          unregisteredIssueResolvedEventHandler = $rootScope.$on('ISSUE:RESOLVED', function (e, data) {
            if (data.issue.task_id === $ctrl.selectedItem.task.id) {
              var isHasIssue = CompactTaskService.isHasIssue($ctrl.selectedItem.task);
              $ctrl.rightPaneCustomClass = "compact-container-task-detail " + (isHasIssue ? " has-issue " : "");
            }
          });

          onTaskCompletedHandler = $rootScope.$on('COMPACT_TASK:COMPLETED', function (e, data) {
            if (_.get(data, 'task.id') === $ctrl.selectedItem.task.id) {
              $ctrl.rightPaneCustomClass = $ctrl.rightPaneCustomClass +
                ((data.task.status === 'completed' && data.task.task_type === 'approval' && !data.task.is_approved) ? " rejected-task " : "");
            }
          });

          updateOrgPlanEventHandler = $rootScope.$on('ORGANIZATION:UPDATE_PLAN', function () {
            setCurrentPlan();
          });

          rightPaneEditModeEventHandler = $rootScope.$on('RIGHT_PANE:EDIT_MODE_TASK_OPEN', function (e, data) {
            $ctrl.editMode = data.editMode;
          });

          tasksLengthChangesHandler = $scope.$watch('$ctrl.taskLength', function (value) {
            $ctrl.filterMetadata.taskCount = 0;
            if (value) {
              $ctrl.filterMetadata.taskCount = TasksFilterService.formatCount($ctrl.taskLength, true);
            }
          }, true);

          tasksAllFoldersListEventHandler = $rootScope.$on('TASK_ALL_FOLDERS_LIST:UPDATED', function (e, data) {
            $ctrl.foldersList = data;
          });
        }
    });
})();
