/**
 * @ngdoc Component
 * @name tallyfy.organizations.users.component.orgRegularUsers
 * @module tallyfy.organizations.users
 *
 * @description
 * A component to manage org users
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations.users')
    .component('orgRegularUsers', {
      templateUrl: 'app/modules/users/regularUsers/regular-users.html',
      controller:
        /*@ngInject*/
        function (_, $q, UsersService, Growl, blockUI, $filter, $rootScope, AuthPlan, PLANS, CONFIG, $state, TasksService, Helper, store, $uibModal,
          ENV_CONFIG, $stateParams, SecurityAndLogService, RegularUserTableService, OrganizationsService, AccountService, $confirm, $log) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('regularUser'),
            activeUsers = [],
            unregisterUpdateOrganizationMembers;

          /**
           * Component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.userPagination = { per_page: 10, page: 1 };
          $ctrl.perPageData = [5, 10, 20];

          /**
           * Expose bindable methods
           * these methods are accessible in view
          */
          $ctrl.inviteUser = inviteUser;
          $ctrl.getUserRoleNameById = getUserRoleNameById;

          $ctrl.initTableConfig = initTableConfig;
          $ctrl.initCadencesTableConfig = initCadencesTableConfig;
          $ctrl.getUsers = getUsers;
          $ctrl.copySSOLink = copySSOLink;
          $ctrl.setTabActive = setTabActive;

          /**
           * public properties
          */
          $ctrl.user = {};
          $ctrl.data = [];
          $ctrl.showUpgradeBar = false;

          /**
           * @function $onInit
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
          */
          function initialization() {
            $ctrl.currentUser = $rootScope.identity.id;
            $ctrl.currentUserRole = $rootScope.identity.role;
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.allow_user_invite = _.get($rootScope, 'orgSamlInfo.allow_user_invite');
            $ctrl.orgID = _.get($rootScope.identity, 'default_organization.id');
            $ctrl.orgPlanCode = _.get($rootScope.identity, 'default_organization.subscription.data.plan_code');
            $ctrl.isFreePlan = $ctrl.orgPlanCode === PLANS.FREE && !(_.get($rootScope.identity, 'default_organization.in_trial'));
            $ctrl.activeTab = _.get($stateParams, 'tab', 'table') === 'activity' ? 'activity' : 'table';
            $ctrl.orgDetail = _.get($rootScope, 'orgSamlInfo');
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
          */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
          */
          function onDestroy() {
            unregisterUpdateOrganizationMembers();
          }

          function getTableConfig() {
            return {
              beautyScrollStyle: true,
              gridConfig: RegularUserTableService.getOptions(),
              tableState: {
                sort: {},
                columns: {}
              },
              tableMenus: {
                export: true,
                hiddenColumnsOnExport: ['convertMemberToGuest'],
                hideAndShowColumns: [{
                  field: 'status',
                  title: $filter('translate')('regularUsers.table.header.status'),
                  initState: true
                }, {
                  field: 'lastAccessed',
                  title: $filter('translate')('regularUsers.table.header.lastAccessed'),
                  initState: true
                }, {
                  field: 'created_at',
                  title: $filter('translate')('regularUsers.table.header.firstCreated'),
                  initState: true
                }, {
                  field: 'member',
                  title: $filter('translate')('regularUsers.table.header.member'),
                  initState: true
                }, {
                  field: 'role',
                  title: $filter('translate')('regularUsers.table.header.role'),
                  initState: true
                }, {
                  field: 'email',
                  title: $filter('translate')('regularUsers.table.header.email'),
                  initState: true
                }, {
                  field: 'convertMemberToGuest',
                  title: $filter('translate')('regularUsers.table.header.convertMemberToGuest'),
                  initState: true
                }, {
                  field: 'lastEmailStatus',
                  title: $filter('translate')('regularUsers.table.header.lastEmailStatus'),
                  initState: true
                }, {
                  field: 'lastCity',
                  title: $filter('translate')('regularUsers.table.header.lastCity'),
                  initState: true
                }, {
                  field: 'lastCountry',
                  title: $filter('translate')('regularUsers.table.header.lastCountry'),
                  initState: true
                }, {
                  field: 'groups',
                  title: $filter('translate')('regularUsers.table.header.groups'),
                  initState: true
                }, {
                  field: 'action',
                  title: $filter('translate')('regularUsers.table.header.action'),
                  initState: true
                }, {
                  field: 'emailLogs',
                  title: $filter('translate')('regularUsers.table.header.emailLogs'),
                  initState: true
                }, {
                  field: 'deleteUser',
                  title: $filter('translate')('global.standAlone.label.delete'),
                  initState: true
                }]
              },
              tableFilterModel: {
                status: {
                  value: '',
                  operator: function (status, args) {
                    if (!args) {
                      return true;
                    }
                    return status === args;
                  }
                },
                full_name: {
                  value: '',
                  operator: 'contains'
                },
                role: {
                  value: '',
                  operator: function (role, args) {
                    if (!args) {
                      return true;
                    }
                    return role === args;
                  }
                },
                email: {
                  value: '',
                  operator: 'contains'
                },
                groups: {
                  value: '',
                  operator: function (groups, args) {
                    if (groups.length) {
                      return _.filter(groups, function (group) {
                        return args ? group.name.toLowerCase().indexOf(args.toLowerCase()) !== -1 : true;
                      }).length;
                    }
                    return false;
                  }
                }
              },
              templateScope: {
                callbacks: {
                  getLastEmailStatus: getLastEmailStatus,
                  goToUserDetails: goToUserDetails,
                  showEmailLogs: showEmailLogs,
                  viewTasks: viewTasks,
                  getGroupData: getGroupData,
                  withdrawInviteUser: withdrawInviteUser,
                  resendInviteUser: resendInviteUser,
                  editMaxLimit: editMaxLimit,
                  confirmDeleteMember: confirmDeleteMember,
                  convertMemberToGuest: convertMemberToGuest,
                  sortTable: function (column) {
                    $ctrl.tableOptions.gridConfig.dataSource.data([]);
                    $ctrl.isLoading = true;
                    if ($ctrl.tableOptions.tableState.sort.field !== column) {
                      $ctrl.tableOptions.tableState.sort.dir = 'asc';
                      $ctrl.tableOptions.tableState.sort.field = column;
                    } else {
                      if ($ctrl.tableOptions.tableState.sort.dir === 'desc') {
                        $ctrl.tableOptions.tableState.sort = {};
                      } else {
                        $ctrl.tableOptions.tableState.sort.dir = $ctrl.tableOptions.tableState.sort.dir !== 'asc' ? 'asc' : 'desc';
                        $ctrl.tableOptions.tableState.sort.field = column;
                      }
                    }
                    kendo.ui.progress($ctrl.tableOptions.tableElement, true);
                    var colName = (column == 'created_at' ? 'created' : column);
                    if (colName == 'full_name') {
                      colName = 'first_name,last_name';
                    }
                    getUsers(
                      { sort: !Helper.isObjectEmpty($ctrl.tableOptions.tableState.sort) ? ($ctrl.tableOptions.tableState.sort.dir !== 'asc' ? '-' : '') + colName : void 0 }
                    ).then(function (res) {
                      $ctrl.tableOptions.gridConfig.dataSource.data(res);
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    }, function () {
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    });
                  },
                  onColumnFilter: function (column) {
                    $ctrl.tableOptions.gridConfig.dataSource.data([]);
                    $ctrl.isLoading = true;
                    kendo.ui.progress($ctrl.tableOptions.tableElement, true);
                    var params = { page: 1 };
                    for (var key in $ctrl.tableOptions.tableFilterModel) {
                      if (key === 'full_name') {
                        params.name = $ctrl.tableOptions.tableFilterModel[key].value;
                      } else {
                        params[key] = $ctrl.tableOptions.tableFilterModel[key].value;
                      }
                    }
                    getUsers(params)
                      .then(function (res) {
                        $ctrl.tableOptions.gridConfig.dataSource.data(res);
                        $ctrl.isLoading = false;
                        kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                      }, function () {
                        $ctrl.isLoading = false;
                        kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                      });
                  }
                },
                variables: {
                  lastEmailStatus: {},
                  checkStatusLabel: $filter('translate')('regularUsers.buttons.checkStatus'),
                  currentUser: $rootScope.identity.id,
                  currentUserRole: $rootScope.identity.role,
                  orgID: _.get($rootScope.identity, 'default_organization.id'),
                  userGroups: {},
                  maxLimit: 4,
                  dateFormat: OrganizationsService.getDateFormat()
                }
              }
            };
          }

          function getCadencesTableConfig() {
            return {
              beautyScrollStyle: true,
              gridConfig: RegularUserTableService.getOptions('cadences'),
              tableState: {
                sort: {},
                columns: {}
              },
              tableMenus: {
                export: true,
                hideAndShowColumns: [{
                  field: 'status',
                  title: $filter('translate')('regularUsers.table.header.status'),
                  initState: true
                }, {
                  field: 'cadenceDays',
                  title: $filter('translate')('regularUsers.table.header.cadenceDays'),
                  initState: true
                }]
              },
              tableFilterModel: {
                status: {
                  value: '',
                  operator: function (status, args) {
                    if (!args) {
                      return true;
                    }
                    return status === args;
                  }
                },
                full_name: {
                  value: '',
                  operator: 'contains'
                }
              },
              templateScope: {
                callbacks: {
                  getCadenceDays: getCadenceDays,
                  getCadenceDaysClick: getCadenceDaysClick,
                  cadenceDays: OrganizationsService.getCadenceDays()
                },
                variables: {
                  checkStatusLabel: $filter('translate')('regularUsers.buttons.checkStatus'),
                  currentUser: $rootScope.identity.id,
                  currentUserRole: $rootScope.identity.role,
                  orgID: _.get($rootScope.identity, 'default_organization.id')
                }
              }
            };
          }

          function setTabActive(selected) {
            $ctrl.activeTab = selected;
            $state.transitionTo($state.current, angular.extend($stateParams, {
              tab: selected
            }), { notify: false });
          }

          function getCadenceDays(e, uid, day) {
            var user = _.find($ctrl.cadencesTableOptions.gridConfig.dataSource.data(), { uid: uid });
            return _.includes(user.cadence_days, day);
          }

          function getCadenceDaysClick(e, uid, day) {
            var user = _.find($ctrl.cadencesTableOptions.gridConfig.dataSource.data(), { uid: uid });
            $ctrl.onSaving = true;
            if (day && !_.includes(user.cadence_days, day)) {
              user.cadence_days.push(day);
            } else if (day && _.includes(user.cadence_days, day)) {
              user.cadence_days = _.remove(user.cadence_days, function (d) { return d !== day; });
            }
            if (user.cadence_days.length < 1) {
              user.cadence_days.push('Monday');
            }
            AccountService.updateCadenceDays({ user_id: _.get(user, 'id') },
              { cadence_days: user.cadence_days }).then(function () {
                $ctrl.onSaving = false;
                growl.success($filter('translate')('myAccount.messages.update.preferences'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              });
          }

          function getLastEmailStatus(e, email) {
            if ($ctrl.tableOptions.templateScope.variables.lastEmailStatus[email]) {
              if ($ctrl.tableOptions.templateScope.variables.lastEmailStatus[email].event === '-') {
                return;
              }
              $ctrl.tableOptions.templateScope.callbacks.showEmailLogs(email);
            } else {
              var params = {
                org_id: _.get($rootScope.identity, 'default_organization.id'),
                user_email: email
              };
              $ctrl.tableOptions.templateScope.variables.lastEmailStatus[email] = {
                loading: true
              };
              SecurityAndLogService
                .getLastEmailStatus(params)
                .then(function (res) {
                  $ctrl.tableOptions.templateScope.variables.lastEmailStatus[email] = !Helper.isObjectEmpty(res.data) ? res.data : { event: '-' };
                });
            }
          }

          /**
           * @ngdoc method
           * @name getUsers
           * @description Get organization user
           * @returns {Object}
          */
          function getUsers(params) {
            var defer = $q.defer();
            $ctrl.showUpgradeBar = false;
            var request = [store.getUsersFullData(angular.extend($ctrl.userPagination, params), true)];
            if (!$ctrl.groups) {
              request.push(store.getLightweightGroups());
            }
            $q.all(request).then(function (data) {
              if (request.length == 2) {
                $ctrl.groups = data[1];
              }
              angular.extend($ctrl.userPagination, data[0].meta.pagination);
              $ctrl.users = _.map(UsersService.usersWithoutBot(data[0].data), function (user) {
                user.groups = _.filter($ctrl.groups, function (group) {
                  return _.indexOf(group.members, user.id) !== -1;
                });
                user.tooltipTitle = $filter('translate')('regularUsers.by', { user: user.full_name });
                user.defaultAvatar = defaultAvatar(user.profile_pic);
                user.defaultAvatarText = defaultAvatarText(user.first_name || user.name, user.last_name);
                return user;
              });

              //Set user groups in template scope to toggle UI
              _.map($ctrl.users, function (user) {
                $ctrl.tableOptions.templateScope.variables.userGroups[user.id] = {
                  groups: user.groups,
                  maxLimit: $ctrl.tableOptions.templateScope.variables.maxLimit,
                  moreText: (user.groups.length > $ctrl.tableOptions.templateScope.variables.maxLimit) ? ('+' + (user.groups.length - $ctrl.tableOptions.templateScope.variables.maxLimit)) : null,
                  popupText: getPopupText(user.groups)
                };
              });
              var currentIndex = _.findIndex($ctrl.users, { 'id': $ctrl.currentUser });
              if (currentIndex > -1) {
                $ctrl.users.splice(0, 0, $ctrl.users.splice(currentIndex, 1)[0]);
              }
              activeUsers = $ctrl.users;
              if (activeUsers.length >= CONFIG.FREE_ACCOUNT_ACTIVE_USERS_LIMIT && $ctrl.isFreePlan) {
                $ctrl.showUpgradeBar = true;
              }
              setMinimumUsersMsg();
              defer.resolve($ctrl.users);
            }, function () {
              defer.reject();
            });
            return defer.promise;
          }

          function getPopupText(groups) {
            var text = '';
            var remainingGroups = _.slice(groups, $ctrl.tableOptions.templateScope.variables.maxLimit);
            _.map(remainingGroups, function (group) {
              text += text ? (', ' + group.name) : group.name;
            });
            return text;
          }

          function getInviteData(user) {
            return (user.status === 'invited' && user.invited_by) ? user.invited_by : (user.status === 'active' && user.reactivated_by) ? user.reactivated_by : (user.status === 'disabled' && user.disabled_by) ? user.disabled_by : angular.noop();
          }

          /**
           * Un-register listener for update organization member
           * @type {*|(function())}
           */
          unregisterUpdateOrganizationMembers = $rootScope.$on('USER:UPDATE_USERS', function (e, data) {
            if (data.member) {
              var actUserId = getInviteData(data.member),
                actUserIdObj = _.find($ctrl.users, { id: actUserId });
              data.member.groups = _.filter($ctrl.groups,
                function (group) {
                  return _.indexOf(group.members, data.member.id) !== -1;
                }
              );
              data.member.tooltipTitle = actUserId && actUserIdObj ? $filter('translate')('regularUsers.by', { user: actUserIdObj.full_name }) : angular.noop();
              data.member.defaultAvatar = defaultAvatar(data.member.profile_pic);
              data.member.defaultAvatarText = defaultAvatarText(data.member.first_name, data.member.last_name);
              $rootScope.$emit('USER:INVITED', { member: data.member });
              $ctrl.tableOptions.gridConfig.dataSource.add(data.member);
              $ctrl.tableOptions.gridConfig.dataSource.sync();
              $ctrl.tableOptions.tableElement.data('kendoGrid').refresh();
            }
            getUsers();
          });

          /**
           * @ngdoc method
           * @name inviteUser
           * @description Invite new user, Show upgrade modal when limit reached
           * @returns {Object}
          */
          function inviteUser() {
            if (!Helper.checkAccessAuthority())
              return;
            if (activeUsers.length >= CONFIG.FREE_ACCOUNT_ACTIVE_USERS_LIMIT && !(AuthPlan.hasAnyAuthority(PLANS.FREE, 'coworkerLimit')))
              return;

            UsersService.openInviteModal();
          }

          /**
           * @ngdoc method
           * @name resendInviteUser
           * @description Resend invitation
           * @param {String} userId
           * @returns {Object}
          */
          function resendInviteUser(userId) {
            if (!Helper.checkAccessAuthority())
              return;
            blockUI.start();
            UsersService.resendInvite(userId).then(function (response) {
              showGrowlMessage($filter('translate')('regularUsers.buttons.resend.messages.success') + response.data.email);
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name withdrawInviteUser
           * @description Withdraw invitation
           * @param {Object} uid
           * @returns {void}
          */
          function withdrawInviteUser(uid) {
            var dataSource = $ctrl.tableOptions.gridConfig.dataSource,
              user = _.find(dataSource.data(), { uid: uid }) || {},
              index = _.findIndex(dataSource.data(), { uid: uid });
            if (!Helper.checkAccessAuthority())
              return;
            blockUI.start();
            UsersService.withdrawInvite(user.id).then(function () {
              blockUI.stop();
              store.removeUser(user);
              $ctrl.users.splice(index, 1);
              $ctrl.tableOptions.gridConfig.dataSource.data($ctrl.users);
              $rootScope.$emit('USER:DELETED', { member: user });
              store.clearUserStore();
              getUsers();
              var message = $filter('translate')('regularUsers.buttons.withdraw.messages.success', {
                userEmail: user.email
              });
              showGrowlMessage(message);
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name viewTasks
           * @description redirect to view task with selected user
           * @param {Integer} userId
          */
          function viewTasks(userId) {
            if (($ctrl.currentUser !== userId && !AuthPlan.hasAnyAuthority(PLANS.FREE, 'myTask')))
              return;

            $state.go('home', { org_id: $ctrl.orgID, assignee: userId });
          }

          /**
           * @ngdoc method
           * @name defaultAvatar 
           * @public
           * @description set default avatar
           * @param {string} avatar 
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText 
           * @public
           * @description set default avatar Text
           * @param {string} firstname 
           * @param {string} lastname 
           * @return {string} first character of name
           */
          function defaultAvatarText(firstname, lastname) {
            return TasksService.setDefaultAvatarText(firstname, lastname);
          }

          /**
           * @ngdoc method
           * @name showGrowlMessage 
           * @description To display global growl message
           * @param {string} message
           */
          function showGrowlMessage(message) {
            Helper.clearUniversalAlert('global');
            growl.success(message, {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            });
          }

          /**
           * @ngdov method
           * @name setMinimumUsersMsg
           * @description Set message for minimum user in view
           * @returns void
           */
          function setMinimumUsersMsg() {
            $ctrl.activeUsers = activeUsers.length;
            if (_.get($rootScope.identity, 'default_organization.plan_code') !== ENV_CONFIG.FIX_PLAN) {
              $ctrl.minimumUsersInCurrentPlan = _.includes([PLANS.PROMONTHLY, PLANS.PROANNUAL], $ctrl.currentPaidPlan) ? CONFIG.PRO_MIN_USERS : _.includes([PLANS.BASICMONTHLY, PLANS.BASICANNUAL], $ctrl.currentPaidPlan) ? CONFIG.BASIC_MIN_USERS : _.includes([PLANS.DOCSMONTHLY, PLANS.DOCSANNUAL], $ctrl.currentPaidPlan) ? CONFIG.DOCS_MIN_USERS : '';
            } else {
              $ctrl.isPlanAppSumo = true;
              $ctrl.maximumUsersLimitInCurrentPlan = CONFIG.APPSUMO_ACCOUNT_USER_LIMIT;
            }
          }

          function showEmailLogs(email) {
            var user = _.find($ctrl.users, { email: email });
            $uibModal.open({
              component: 'securityAndLog',
              windowClass: 'email-logs',
              size: 'lg',
              resolve: {
                user: user
              }
            });
          }

          function confirmDeleteMember(uid) {
            var user = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid }) || {};
            $confirm({
              'header': $filter('translate')('permanentlyDeleteItem.header', {
                itemName: user.full_name
              }),
              'body': '',
              'buttons': {
                'accept': 'global.confirmAction.buttons.yes',
                'cancel': 'global.confirmAction.buttons.close'
              },
              modalType: 'modal-danger'
            }).then(function () {
              deleteMember(user, uid);
            }, function () {
              $log.info('Member deletion is cancelled.');
            });
          }

          function deleteMember(user, uid) {
            var params = {
              action: 'users',
              id: user.id
            };
            blockUI.start();
            UsersService.permanentlyDeleteMemberOrGuest(params).then(function () {
              var tableData = $ctrl.tableOptions.gridConfig.dataSource.data();
              $ctrl.tableOptions.gridConfig.dataSource.data(
                _.filter(tableData, function (data) {
                  return data.uid !== uid;
                })
              );
              $ctrl.tableOptions.gridConfig.dataSource.sync();
              $ctrl.tableOptions.tableElement.data('kendoGrid').refresh();
              store.removeUser(user);
              store.getUsers().then(function (res) {
                $rootScope.$emit('USER:DELETED', { member: res });
              });
              showGrowlMessage($filter('translate')('permanentlyDeleteItem.successMessage', { itemName: user.full_name }));
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name getUserRoleById
           * @description Find & return user role name by user role Id
           * @param {Integer} roleId
           * @returns {String} name
           */
          function getUserRoleNameById(roleId) {
            return (_.find($ctrl.userRoles, { 'id': roleId })).name;
          }

          function goToUserDetails(data) {
            $ctrl.currentUser === data ? $state.go('settings.me.update') : $state.go('userProfile', { org_id: $ctrl.orgID, id: data });
          }

          function getGroupData(uid) {
            var row = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid }) || {};
            return row.groups.length ? row.groups : [];
          }

          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function initCadencesTableConfig() {
            $ctrl.cadencesTableOptions = getCadencesTableConfig();
          }

          /**
           * @function 
           * @name copySSOLink
           * @param {String} text 
           * @param {Object} target 
           * @param {*} $event 
           * @description Copy success handler
           * @returns {Void} 
           */
          function copySSOLink(text, target, $event) {
            RegularUserTableService.copySuccess(text, target, $event);
          }

          function editMaxLimit(id) {
            $ctrl.tableOptions.templateScope.variables.userGroups[id].maxLimit = $ctrl.tableOptions.templateScope.variables.userGroups[id].groups.length;
          }

          function convertMemberToGuest(uid) {
            blockUI.start();
            var dataSource = $ctrl.tableOptions.gridConfig.dataSource,
              user = _.find(dataSource.data(), { uid: uid }) || {},
              index = _.findIndex(dataSource.data(), { uid: uid });
            AccountService.convertMemberToGuest(user.id).then(function () {
              var tableData = $ctrl.tableOptions.gridConfig.dataSource.data();
              $ctrl.tableOptions.gridConfig.dataSource.data(
                _.filter(tableData, function (data) {
                  return data.uid !== uid;
                })
              );
              store.removeUser(user);
              $ctrl.users.splice(index, 1);
              $ctrl.tableOptions.gridConfig.dataSource.data($ctrl.users);
              $rootScope.$emit('USER:DELETED', { member: user });
              showGrowlMessage($filter('translate')('userProfile.messages.convertMemberToGuestSuccessfully'));
              getUsers();
              store.clearGuestsStore();
              store.getLightweightGuests().then(function (res) {
                $rootScope.$emit('COUNT_UPDATE', { type: 'guests', count: res.length });
              });
              blockUI.stop();
              $state.go('settings.org.orgGuestUsers');
            }, function () { blockUI.stop(); });
          }
        }
    });
})();
